import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import CostValueCo from '../../components/CostValueCo';

const CoModel = () => {

    const location = useLocation();
    const [model, setModel] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [modelData, setModelData] = useState({ model_name: "", values: [] })
    const [error, setError] = useState(null)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addValue, setAddValue] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_utilities_list", "create_utilities", "update_utilities"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/roles')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/model/create/co') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setModel(queryParams.get('model'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (getPutPost === 'get' && model !== null) {
            setLoading(true)
            const auth = context.getUser.token;
            API.getModelCo(cancelToken, auth, model).then(res => {
                setModelData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && 'error' in err.response.data) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, model, forceUpdate])

    const updateModel = (e) => {
        const { name, value } = e.target;
        setModelData(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    const postModel = async (e) => {
        if (modelData.model_name === "") {
            setError({ model_name: "Missing Value" })
        } else {
            setLoading(true)
            const cancelToken = API.cancelToken();
            const auth = context.getUser.token;
            let request = null
            try {
                if (getPutPost === 'post') {
                    request = await API.postModelCo(cancelToken, auth, modelData)
                    navigate(`/model/co?model=${request.data}`)
                } else {
                    request = await API.putModelCo(cancelToken, auth, modelData)
                    setGetPutPost('get')
                    setError(null)
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    if ('general' in err.response.data || 'message' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.general,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    } else {
                        setError(err.response.data)
                    }
                }
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/costsfactors/co">
                    CO2 Cost Models
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? modelData.model_name : "Add Cost Model"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='input-border pb-8 items-center mb-6'>
                    <input
                        placeholder='Model Name'
                        className='text-3xl p-2 outline-none hide-input-background'
                        type="text"
                        name='model_name'
                        disabled={getPutPost === 'get'}
                        defaultValue={modelData.model_name ? modelData.model_name : ""}
                        onChange={updateModel} />
                    {
                        error && error.model_name &&
                        <p className='text-red text-xs p-2'>{error.model_name}</p>

                    }
                </div>
                {
                    loading &&
                    <Loader />
                }
                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Model
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && modelData.model_name !== "" && !loading && <button className='submit-button' onClick={postModel}>
                            Save
                        </button>
                    }
                </div>
                {
                    ['get', 'put'].includes(getPutPost) &&
                    <div className='mt-4'>
                        <p className='text-2xl'>Carbon Intensity CO2</p>
                        {
                            modelData.values.map((e, index) => (
                                <CostValueCo key={index + "costevalue"} setUpdate={setForceUpdate} update={forceUpdate} value={e} />
                            ))
                        }
                        {
                            addValue &&
                            <CostValueCo edit={true} setUpdate={setForceUpdate} open={setAddValue} update={forceUpdate} value={null} modelId={model} />
                        }

                        {addValue ?
                            <div className='w-full border-light-green block mt-8 text-sm text-green4 font-medium cursor-pointer' onClick={() => setAddValue(false)}>
                                Cancel
                            </div>
                            :
                            <div className='w-full border-light-green block mt-8 text-sm text-green4 font-medium cursor-pointer' onClick={() => setAddValue(true)}>
                                <span className='text-lg mr-2'>+</span>
                                Add new value
                            </div>}
                    </div>
                }
            </div>
        </div>
    );
};

export default CoModel;