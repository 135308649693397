import { useContext, useEffect, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import { useLocation } from 'react-router-dom';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import MaintenanceGroups from '../../components/MaintenanceGroups';
import Loader from "../../components/Loader";
import GlobalFuncs from '../../utils/GlobalFuncs';
import moment from 'moment';
import MaintenanceDetailsModal from "../../components/MaintenanceDetailsModal";
import MaintenancePieChart from "../../components/MaintenancePieChart";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './style.css'
import MaintenanceBarChart from "../../components/MaintenanceBarChart";

const Maintenance = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [showDetails, setShowDetails] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [groups, setGroups] = useState([])
    const [equipmentRuntime, setEquipmentRuntime] = useState([])
    const [rawGroups, setRawGroups] = useState(null)
    const [filteredGroups, setFilteredGroups] = useState([])
    const [filters, setFilters] = useState({})
    const [sorting, setSorting] = useState({ percent: false })
    const [requestedGroup, setRequestedGroup] = useState(false)
    const [loading, setLoading] = useState(true)
    const context = useContext(AppContext);
    const [freeRange, setFreeRange] = useState({ start: '2022-01-01', end: moment().format('YYYY-MM-DD') })
    const [groupSummary, setGroupSummary] = useState({
        'Overdue': 0,
        'Due': 0,
        'Upcoming': 0,
        'Set': 0,
        'Total': 0,
        categories: {},
        completed: {},
        onTime: {},
    })
    // const [showBuildings, setShowBuildings] = useState(false)
    const minDate = new Date('2022-01-01');
    const maxDate = new Date();

    useEffect(() => {
        if (queryParams.get('building') === 'all') return
        context.setBuilding(queryParams.get('building'))
        let modalId = queryParams.get('group')
        setRequestedGroup(modalId)
        // setShowBuildings(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    const getEquipmentRuntime = async (cancelToken, equipmentList, tempEquipmentRuntime) => {
        return new Promise(async (resolve, reject) => {
            var promises = []
            equipmentList.forEach((circuit) => {
                if (!circuit[0] || !circuit[1]) return
                const auth = context.getUser.token;
                promises.push(API.getCircuitRuntime(cancelToken, auth, "2022-01-01", moment().format('YYYY-MM-DD'), circuit[0], circuit[1]).then(res => {
                    tempEquipmentRuntime[circuit[1]] = res.data.series
                }).catch(err => {
                    console.log(err)
                    if (err.message === 'cancelling') return
                    if (err.response && err.response.data) {
                        try {
                            if ('error' in err.response.data) {
                                Swal.fire({
                                    title: 'Error.',
                                    text: err.response.data.error,
                                    icon: 'warning',
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: '#46775A',
                                });
                            }
                        } catch (e) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } else {
                        Swal.fire({
                            title: 'Error.',
                            text: 'An error occurred, please try again later.',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }

                }))
            })
            let finalData = await Promise.all(promises)
            resolve(finalData)
        })
    }


    useEffect(() => {
        if (!context.getBuildingInfo || !context.getUser) return;
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        let tempEquipmentRuntime = {}
        API.getMaintenanceGroups(cancelToken, auth, context.getBuildingInfo.building_id).then(async res => {
            let equipmentList = []
            const seen = {};

            for (let group of res.data) {
                group.activities.forEach(item => {
                    const key = `${item.device_id}${item.channel_name}`;
                    if (!seen[key] && item.timebase === 'runtime') {
                        seen[key] = true;
                        equipmentList.push([item.device_id, item.channel_name]);
                    }
                });
            }
            getEquipmentRuntime(cancelToken, equipmentList, tempEquipmentRuntime).then(async data => {
                setEquipmentRuntime(tempEquipmentRuntime)
                setRawGroups(res.data)
                setLoading(false)
            })
        }).catch(e => {
            console.log(e)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        })
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuildingInfo, context.getUser])

    useEffect(() => {
        if (equipmentRuntime.length === 0 || !rawGroups) return
        updateNumbers(rawGroups, equipmentRuntime)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipmentRuntime, rawGroups])

    const updateNumbers = async (tempGroups) => {
        if (equipmentRuntime.length === 0) return
        let final = GlobalFuncs.getCalculatedMaintenanceValues(tempGroups, equipmentRuntime)
        setGroups(final)
    }

    useEffect(() => {
        let tempGroups = JSON.parse(JSON.stringify(groups))
        for (let field in filters) {
            if (filters[field] === null) continue
            if (field === 'lastActionDate') {
                tempGroups = tempGroups.filter(group => moment(group.lastActionDate) >= moment(filters[field]))
            } else if (field === 'equipment') {
                tempGroups = tempGroups.filter(group => group.activities.map(activity => activity.channel_name).includes(filters[field]))
            } else if (['threshold', 'tolerance', 'dueIn', 'percent', 'currentStatus'].includes(field)) {
                let digits = ['threshold', 'tolerance', 'dueIn', 'percent']
                tempGroups = tempGroups.filter(group => digits.includes(field) ? group.activities.map(activity => parseFloat(activity[field])).includes(parseFloat(filters[field])) : group.activities.map(activity => activity[field]).includes(filters[field]))
            } else {
                tempGroups = tempGroups.filter(group => group[field].trim().toLowerCase() === filters[field].trim().toLowerCase())
            }
        }
        for (let field in sorting) {
            tempGroups.sort((a, b) => {
                if (a[field] === null && b[field] === null) return 0;
                if (a[field] === null) return sorting[field] ? 1 : -1;
                if (b[field] === null) return sorting[field] ? -1 : 1;

                switch (field) {
                    case 'lastActionDate':
                    case 'updated_date':
                        return sorting[field]
                            ? moment(a[field]).diff(moment(b[field]))
                            : moment(b[field]).diff(moment(a[field]));
                    case 'type':
                    case 'name':
                    case 'performedBy':
                        return sorting[field]
                            ? a[field].localeCompare(b[field])
                            : b[field].localeCompare(a[field]);
                    default:
                        return sorting[field]
                            ? a[field] - b[field]
                            : b[field] - a[field];
                }
            });
        }
        setFilteredGroups(tempGroups)
    }, [groups, filters, sorting])

    useEffect(() => {
        let tempGroupSummary = {
            'Overdue': 0,
            'Due': 0,
            'Upcoming': 0,
            'Set': 0,
            'Total': 0,
            categories: {},
            completed: {},
            onTime: {},
        }
        for (let group of filteredGroups) {
            group.logs.forEach(log => {
                if (log.completed_date === null) return
                tempGroupSummary.completed[log.completed_date] = tempGroupSummary.completed[log.completed_date] || 0
                tempGroupSummary.completed[log.completed_date] += 1
                tempGroupSummary.onTime[log.completed_date] = tempGroupSummary.onTime[log.completed_date] || 0
                tempGroupSummary.onTime[log.completed_date] += (log.onTime ? 1 : 0)
            })
            group.activities.forEach(activity => {
                tempGroupSummary[activity.currentStatus]++
                tempGroupSummary['Total']++
            })
        }

        setGroupSummary(tempGroupSummary)
    }, [filteredGroups])

    useEffect(() => {
        if (!filteredGroups.length || !requestedGroup) {
            setShowDetails(null)
            return
        }
        setShowDetails(parseInt(requestedGroup))
    }, [filteredGroups, requestedGroup])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilters && !event.target.closest('.multi-select')) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showFilters]);

    const monthComplete = (startDate, endDate, dict) => {
        let tempCount = 0
        Object.keys(dict).forEach((key) => {
            tempCount += (moment(key).isBetween(startDate, endDate, undefined, '[]') ? dict[key] : 0)
        })
        return tempCount
    }

    const handleDateChange = (data) => {
        if (!data.value) return
        let startDate = data.value[0]
        let endDate = data.value[1]
        setFreeRange({ start: GlobalFuncs.formatDate(startDate, 'YYYY-MM-DD'), end: GlobalFuncs.formatDate(endDate, 'YYYY-MM-DD') })
    }

    const getColor = (amount) => {
        if (!amount || amount < 0.33) return "#F75D5F"
        if (amount < 0.67) return "#F9941E"
        return "#107048"
    }


    return (
        <div className=''>
            {/* <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-between items-center rounded-md bg-[#FFF] shadow-md" >
                <div className='flex flex-nowrap gap-6 text-base font-bold'>
                    <Link to={`/conservation?building=all`} className='active-reports-tab'>Building View</Link>
                    <Link to={`/conservation/portfolio`}>Portfolio View</Link>
                </div>
                <div className="relative flex flex-nowrap justify-center items-center w-3/12">
                    <div onClick={() => setShowBuildings(!showBuildings)} className='p-1 border border-2 rounded-[8px] transparent  border-[#E5E7EB] h-[43px] cursor-pointer flex flex-nowrap justify-between items-center w-10/12'>
                        <p className='font-sans font-normal text-sans text-[#C1C1C1]'>{context.getBuilding ? context.getBuilding : "Select building"}</p>
                        {showBuildings && GlobalSVG.sortArrowDesc('#C1C1C1')}
                        {!showBuildings && GlobalSVG.sortArrowAsc('#C1C1C1')}
                    </div>
                    <div className={`${showBuildings ? 'block' : 'hidden'} z-10 h-[300px] absolute laptop:top-[110%] left-0 tablet:top-[100%] mobile:top-[100%] w-full flex items-start justify-center `}>
                        <div className='card buildings-card w-full laptop:h-full tablet:h-full mobile:h-full p-2 right-0'>
                            <Accordion onSelect={() => null} compact={true} linkTo='conservation' />
                        </div>
                    </div>
                </div>
            </div> */}
            {context.getBuilding ?
                <div className='tablet:pl-12 tablet:pr-6'>
                    <div className='flex tablet:flex-nowrap mobile:flex-wrap justify-between items-center my-[40px]'>
                        <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Maintenance Summary</h2>
                    </div>
                    <div className='w-full'>
                        {
                            loading ?
                                <div className='w-full h-[300px] flex flex-nowrap justify-center items-center'>
                                    <Loader />
                                </div> :
                                <div className='overflow-x-auto h-[305px]'>
                                    <div className="flex flex-nowrap justify-start items-center overflow-x-auto ">
                                        <div className='mr-[15px] min-w-[190px] w-[190px] h-[304px] bg-[white] rounded-md'>
                                            <p className='font-semibold mb-[10px] px-[30px] pt-[30px]'>Activity Breakdown</p>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-2">
                                                <p className='text-[16px] text-[#F75D5F] mr-2'>{groupSummary['Overdue']}</p>
                                                <p className='text-[12px] text-[#F75D5F]'>Overdue</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-2">
                                                <p className='text-[16px] text-[#F9941E] mr-2'>{groupSummary['Due']}</p>
                                                <p className='text-[12px] text-[#F9941E]'>Due</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-2">
                                                <p className='text-[16px] text-[#107048] mr-2'>{groupSummary['Upcoming']}</p>
                                                <p className='text-[12px] text-[#107048]'>Upcoming</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-2">
                                                <p className='text-[16px] text-[#B8BBB9] mr-2'>{groupSummary['Set']}</p>
                                                <p className='text-[12px] text-[#B8BBB9]'>Set</p>
                                            </div>
                                            <div className="flex flex-nowrap justify-start items-center px-[30px] py-4 bg-[#F2F8F5]">
                                                <p className='text-[16px] text-[#000000] mr-2'>{groupSummary['Total']}</p>
                                                <p className='text-[12px] text-[#000000]'>Total</p>
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[350px] w-[350px] h-[305px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.maintenanceCategories()
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold pr-[30px] pt-[30px]'>Category Breakdown</p>
                                            </div>
                                            <div className=''>
                                                <MaintenancePieChart groups={filteredGroups} />
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[320px] w-[320px] h-[305px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.tools("#107048")
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold pr-[30px] pt-[30px]'>Activity Completion</p>
                                            </div>
                                            <div className='px-[30px]'>
                                                <div className="flex flex-nowrap justify-between items-between border-b border-1 border-[#B8BBB9] py-[10px]">
                                                    <div className="text-[#107048] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">1 month</div>
                                                    <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-[50px]">
                                                        {groupSummary.completed && monthComplete(moment().subtract(30, 'days'), moment(), groupSummary.completed)}
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">Completed</p>
                                                    </div>
                                                    <div className="h-[20px] mt-[10px] border-r border-1 border-[#B8BBB9]"></div>
                                                    <div style={{ color: getColor(monthComplete(moment().subtract(30, 'days'), moment(), groupSummary.onTime) / monthComplete(moment().subtract(30, 'days'), moment(), groupSummary.completed)) }}
                                                        className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-content">
                                                        {groupSummary.onTime &&
                                                            monthComplete(moment().subtract(30, 'days'), moment(), groupSummary.completed) ?
                                                            parseInt((monthComplete(moment().subtract(30, 'days'), moment(), groupSummary.onTime) / monthComplete(moment().subtract(30, 'days'), moment(), groupSummary.completed)) * 100).toLocaleString() :
                                                            0}%
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">On Time</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-nowrap justify-between items-between border-b border-1 border-[#B8BBB9] py-[10px]">
                                                    <div className="text-[#107048] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">3 month</div>
                                                    <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-[50px]">
                                                        {groupSummary.completed && monthComplete(moment().subtract(90, 'days'), moment(), groupSummary.completed)}
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">Completed</p>
                                                    </div>
                                                    <div className="h-[20px] mt-[10px] border-r border-1 border-[#B8BBB9]"></div>
                                                    <div style={{ color: getColor(monthComplete(moment().subtract(90, 'days'), moment(), groupSummary.onTime) / monthComplete(moment().subtract(90, 'days'), moment(), groupSummary.completed)) }}
                                                        className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-content">
                                                        {groupSummary.onTime &&
                                                            monthComplete(moment().subtract(90, 'days'), moment(), groupSummary.completed) ?
                                                            parseInt((monthComplete(moment().subtract(90, 'days'), moment(), groupSummary.onTime) / monthComplete(moment().subtract(90, 'days'), moment(), groupSummary.completed)) * 100).toLocaleString() :
                                                            0}%
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">On Time</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-nowrap justify-between items-between border-b border-1 border-[#B8BBB9] py-[10px]">
                                                    <div className="text-[#107048] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">12 month</div>
                                                    <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-[50px]">
                                                        {groupSummary.completed && monthComplete(moment().subtract(365, 'days'), moment(), groupSummary.completed)}
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">Completed</p>
                                                    </div>
                                                    <div className="h-[20px] mt-[10px] border-r border-1 border-[#B8BBB9]"></div>
                                                    <div style={{ color: getColor(monthComplete(moment().subtract(365, 'days'), moment(), groupSummary.onTime) / monthComplete(moment().subtract(365, 'days'), moment(), groupSummary.completed)) }}
                                                        className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-content">
                                                        {groupSummary.onTime &&
                                                            monthComplete(moment().subtract(365, 'days'), moment(), groupSummary.completed) ?
                                                            parseInt((monthComplete(moment().subtract(365, 'days'), moment(), groupSummary.onTime) / monthComplete(moment().subtract(365, 'days'), moment(), groupSummary.completed)) * 100).toLocaleString() :
                                                            0}%
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">On Time</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-nowrap justify-between items-between py-[10px]">
                                                    <div className="text-[#107048] text-[12px] font-semibold date-range-picker-container w-[92px]">
                                                        <DateRangePickerComponent
                                                            cssClass="hide-calendar-icon"
                                                            startDate={freeRange && moment(freeRange.start).toDate()}
                                                            endDate={freeRange && moment(freeRange.end).toDate()}
                                                            min={minDate}
                                                            max={maxDate}
                                                            onChange={handleDateChange} />
                                                    </div>
                                                    <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-[55px]">
                                                        {groupSummary.completed && monthComplete(moment(freeRange.start), moment(freeRange.end), groupSummary.completed)}
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">Completed</p>
                                                    </div>
                                                    <div className="h-[20px] mt-[10px] border-r border-1 border-[#B8BBB9] ml-[2px]"></div>
                                                    <div style={{ color: getColor(monthComplete(moment(freeRange.start), moment(freeRange.end), groupSummary.onTime) / monthComplete(moment(freeRange.start), moment(freeRange.end), groupSummary.completed)) }}
                                                        className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-content">
                                                        {groupSummary.onTime &&
                                                            monthComplete(moment(freeRange.start), moment(freeRange.end), groupSummary.completed) ?
                                                            parseInt((monthComplete(moment(freeRange.start), moment(freeRange.end), groupSummary.onTime) / monthComplete(moment(freeRange.start), moment(freeRange.end), groupSummary.completed)) * 100).toLocaleString() :
                                                            0}%
                                                        <p className="text-[8px] leading-[8px] text-[#B8BBB9] font-bold">On Time</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mr-[15px] min-w-[600px] w-[600px] h-[300px] bg-[white] rounded-md'>
                                            <div className="flex flex-nowrap justify-start items-center">
                                                <div className="mb-[10px] pl-[30px] pt-[30px] mr-4">
                                                    {
                                                        GlobalSVG.onTimeMaintenance()
                                                    }
                                                </div>
                                                <p className='w-[150px] font-semibold mb-[10px] pr-[30px] pt-[30px]'>On-time Completion</p>
                                            </div>
                                            <div className='px-[30px]'>
                                                <MaintenanceBarChart groups={groupSummary} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='flex tablet:flex-nowrap mobile:flex-wrap justify-between items-center my-[30px]'>
                        <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Maintenance Groups</h2>
                        <div className="flex flex-nowrap justify-end items-center">
                            <div className={`flex flex-nowrap justify-end items-center tablet:items-end gap-2.5 rounded-md`}>
                                <div className={`relative flex laptop:justify-end laptop:items-start tablet:items-end flex-col multi-select`}>
                                    <div className="relative flex items-center justify-start text-sm text-normal text-[grey] cursor-pointer">
                                        <div onClick={() => setShowFilters(!showFilters)} className="w-full mx-3 flex flex-nowrap items-center justify-center">
                                            {GlobalSVG.filter()}
                                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2 mr-6'>Filter</p>
                                        </div>
                                        {
                                            showFilters &&
                                            <div className={`absolute top-[120%] right-[30px] w-[130px] h-[180px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, lastActionDate: null }))} >
                                                    Last Action Date
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, type: null }))} >
                                                    Activity
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, currentStatus: null }))} >
                                                    Status
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, equipment: null }))} >
                                                    Equipment
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, name: null }))} >
                                                    Name
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, performedBy: null }))} >
                                                    Performed By
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, threshold: null }))} >
                                                    Threshold
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, tolerance: null }))} >
                                                    Tolerance
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, dueIn: null }))} >
                                                    Due In
                                                </div>
                                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, percent: null }))} >
                                                    Threshold Used
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {GlobalSVG.plus2()}
                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => setShowCreate(true)}>Add Group</p>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='w-full h-[90px] flex flex-nowrap justify-center items-center'>
                                <Loader />
                            </div> :
                            <MaintenanceGroups
                                sorting={sorting}
                                setSorting={setSorting}
                                setGroups={setGroups}
                                filteredGroups={filteredGroups}
                                groups={groups}
                                filters={filters}
                                setFilters={setFilters} />
                    }
                    {
                        (showDetails || showCreate) &&
                        <MaintenanceDetailsModal
                            setGroups={setGroups}
                            setShowCreate={setShowCreate}
                            updateNumbers={updateNumbers}
                            groups={groups}
                            group={showDetails}
                            create={showCreate ? true : false} />
                    }
                </div>
                :
                <div className='h-[70vh]'>
                    <p className='p-8 text-center'>Please select a building to begin.</p>
                </div>
            }
        </div>
    )

};

export default Maintenance;