import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import PublicArcGauge from '../../components/PublicArcGauge';
import GlobalFuncs from "../../utils/GlobalFuncs";
import ThermoGraph from '../../components/ThermoGraph';
import GlobalSVG from '../../utils/GlobalSVG';

const PublicElectricHistory = () => {


    const location = useLocation();
    const [current, setCurrent] = useState(null)
    const [catConsumption, setCatConsumption] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const [flicker, setFlicker] = useState(false)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);


    const style = {
        backgroundImage: `url(${require('../../assets/images/live-building-usage.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    };

    const getData = async (cancelToken) => {
        let id = queryParams.get('id')
        try {
            const today = new Date();
            const lastMonth = new Date();
            let consumptionDict = {
                hvac: 0,
                lighting: 0,
                plugs: 0,
                refrigeration: 0,
                total: 0,
                other: 0
            }
            today.setDate(today.getDate());
            lastMonth.setDate(lastMonth.getDate() - 30);
            let start = GlobalFuncs.formatDate(lastMonth, 'YYYY-MM-DD')
            let end = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
            let tempCatConsumption = await API.getCatConsumption(cancelToken, start, end, id)
            let tempMeta = await API.getPublicBuilding(cancelToken, id)
            let tempCurrent = await API.getPublicCurrentReading(cancelToken, id)
            setMetadata(tempMeta.data)
            setCurrent(tempCurrent.data)

            for (let key in tempCatConsumption.data) {
                switch (key) {
                    case "HVAC": case "Heating": case "Cooling": case "Ventilation":
                        consumptionDict.hvac += tempCatConsumption.data[key]
                        break
                    case "Lighting":
                        consumptionDict.lighting += tempCatConsumption.data[key]
                        break
                    case "Plug Loads":
                        consumptionDict.plugs += tempCatConsumption.data[key]
                        break
                    case "Refrigeration":
                        consumptionDict.refrigeration += tempCatConsumption.data[key]
                        break
                    case "total":
                        consumptionDict.total += tempCatConsumption.data.total
                        break
                    default:
                        consumptionDict.other += tempCatConsumption.data[key]
                }
            }
            let finalCatDict = {}

            for (let key in consumptionDict) {
                finalCatDict[key] = parseInt((consumptionDict[key] / consumptionDict.total) * 100)
            }

            setCatConsumption(finalCatDict)

        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('error' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }

    useEffect(() => {
        const cancelToken = API.cancelToken();
        const fetchData = () => {
            getData(cancelToken);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => {
            API.cancel(cancelToken);
            clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location.pathname])


    useEffect(() => {
        if (current === null) return
        const updateCounter = () => {
            let factor = flicker ? 0.05 : -0.05
            let num = factor * current
            setCurrent(prevCounter => prevCounter + num);
            setFlicker(!flicker)
        };
        const intervalId = setInterval(updateCounter, 10000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current])

    return (
        <div className='flex flex-col flex-nowrap item-center jusitfy-center h-screen relative'>
            <div className='flex flex-nowrap justify-between items-center'>
                <div className='w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[40px]'>
                    {
                        metadata &&
                        <div className='flex flex-nowrap justify-start items-center h-[143px]'>
                            <div className="relative w-full h-full max-w-[143px] max-h-[143px] mr-4">
                                <div className="absolute inset-0 rounded-full overflow-hidden">
                                    <img className="w-full h-full object-cover object-center" src={metadata.image} alt="" />
                                </div>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center h-[143px] border-y border-[#979797] '>
                                <div className='flex flex-col flex-nowrap justify-center item-center h-full'>
                                    <div className="flex flex-nowrap justify-center item-center desktop:text-[32px] laptop:text-[20px] text-[#383838] font-semibold pr-[36px]">
                                        {metadata.building}
                                    </div>
                                </div>
                                <div className='border-[#979797] border h-[60%]'></div>
                                <div className="flex flex-nowrap justify-start item-center h-full">
                                    <img className='h-full' src={metadata.logo} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='flex flex-nowrap justify-between items-center w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[30px]'>
                    <img className='w-[353px] ' src={require('../../assets/images/greenwave-black.png')} alt="" />
                    <div>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            Making our world more responsible...
                        </p>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            one building at a time!
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-nowrap justify-center items-center flex-grow' style={style}>
                <div className='flex flex-nowrap items-center justify-center h-full w-full'>
                    {current !== null &&
                        <div className='h-full w-[30%] flex flex-nowrap items-center justify-end'>
                            <PublicArcGauge type="e" unit="kW" amount={parseInt(current)} max={parseInt(current) * 1.86} generation={false} />
                        </div>}
                    <div className='w-[65%] text-center'>
                        <h1 className='text-[#353535] text-[64px] font-bold'>Electrical Usage</h1>
                        <h2 className='text-[#353535] text-[34px]'>Previous 30 Days</h2>
                        <div className='flex flex-nowrap justify-center items-center'>
                            <div className='w-[80%] flex flex-col items-center justify-center margin-auto'>
                                {catConsumption && catConsumption.lighting ? <ThermoGraph name="Lighting" icon={GlobalSVG.bulb()} color="#75A42C" percent={catConsumption.lighting} /> : ""}
                                {catConsumption && catConsumption.plugs ? <ThermoGraph name="Plugs" icon={GlobalSVG.wallplug()} color="#2F7DBC" percent={catConsumption.plugs} /> : ""}
                                {catConsumption && catConsumption.refrigeration ? <ThermoGraph name="Refrigeration" icon={GlobalSVG.snowflake()} color="#FFD44D" percent={catConsumption.refrigeration} /> : ""}
                                {catConsumption && catConsumption.hvac ? <ThermoGraph name="HVAC" icon={GlobalSVG.flow()} color="#87B19F" percent={catConsumption.hvac} /> : ""}
                                {catConsumption && catConsumption.other ? <ThermoGraph name="Other" icon={GlobalSVG.ellipses()} color="#FD7C7C" percent={catConsumption.other} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PublicElectricHistory;