import { useCallback, useContext, useEffect, useState } from "react";
import GlobalSVG from "../../utils/GlobalSVG";
import "./style.css";
import { AppContext } from "../../utils/ContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import MainGraph from "../../components/MainGraph";
import DiagramFuncs from "../../utils/DiagramFuncs";
import MultipleSelect from "../../components/MultipleSelect";
import API from "../../utils/API";
import GraphFuncs from "../../utils/GraphFuncs";

import {
  HierarchicalTree,
  DataBinding,
  DiagramComponent,
  NodeConstraints,
  SnapConstraints,
  Inject,
  PrintAndExport,
  DiagramTools,
} from "@syncfusion/ej2-react-diagrams";
import { DataManager } from "@syncfusion/ej2-data";
import {
  ItemDirective,
  ItemsDirective,
  ToolbarComponent,
} from "@syncfusion/ej2-react-navigations";
import html2canvas from "html2canvas";
import ContextMenu from "../../components/ContextMenu";

const Distribuition = () => {
  const [distHierarchy, setDistHierarchy] = useState(null);
  const [egHierarchy, setEgHierarchy] = useState(null);
  const [catHierarchy, setCatHierarchy] = useState(null);
  const [wHierarchy, setWHierarchy] = useState(null);
  const [gHierarchy, setGHierarchy] = useState(null);
  const [collapseAll, setCollapseAll] = useState(false)
  const location = useLocation();
  const context = useContext(AppContext);
  const queryParams = new URLSearchParams(location.search);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [circuits, setCircuits] = useState(null);

  const [buildingOptions, setBuildingOptions] = useState([]);
  const [circuitOptions, setCircuitOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [eGroupOptions, setEGroupOptions] = useState([]);

  const [selectedBuildings, setSelectedBuildings] = useState(null);

  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [nodePan, setNodePan] = useState(null);
  const [maptype, setMapType] = useState("1")

  const typeDict = { '1': 'circuit', '2': 'circuit', '3': 'circuit', '4': 'gas', '5': 'water' }
  const mapTypeDict = { '1': 'e_channels', '2': 'e_channels', '3': 'e_channels', '4': 'g_channels', '5': 'w_channels' }

  const [openFilters, setOpenFilters] = useState(false)

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [clickedNode, setClickedNode] = useState({});
  const [exporting, setExporting] = useState(false);
  const [positionContextMenu, setPositionContextMenu] = useState({
    x: 0,
    y: 0,
  });

  const [distHierarchy2, setDistHierarchy2] = useState(null);
  const [catHierarchy2, setCatHierarchy2] = useState(null);
  const [egHierarchy2, setEgHierarchy2] = useState(null);
  const [wHierarchy2, setWHierarchy2] = useState(null);
  const [gHierarchy2, setGHierarchy2] = useState(null);
  const hierarchyDict = { distHierarchy: distHierarchy2, catHierarchy: catHierarchy2, egHierarchy: egHierarchy2, wHierarchy: wHierarchy2, gHierarchy: gHierarchy2 }
  const hierarchyDictO = { distHierarchy: distHierarchy, catHierarchy: catHierarchy, egHierarchy: egHierarchy, wHierarchy: wHierarchy, gHierarchy: gHierarchy }

  const [loaded, setLoaded] = useState(false)
  const [currentHierarchy, setCurrentHierarchy] = useState(null)
  let [diagramInstance, setDiagramInstance] = useState(null)

  useEffect(() => {
    if (queryParams.size === 0 || queryParams.get('building') === "null") {
      navigate('/map')
    }
    if (queryParams.get('refresh')) {
      navigate(`/distribution?building=${queryParams.get('building')}`)
      window.location.reload(true);
    }
    if (context.getBuilding) return
    context.setBuilding(queryParams.get('building'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (!context.getBuildingInfo || selectedBuildings === null) return;
    var arrChanel = [];
    var arrEGroups = [];
    var arrCats = [];

    // Set circuit options
    if (selectedBuildings.length !== 0) {
      for (let c of context.getBuildingInfo[mapTypeDict[maptype]]) {
        if (selectedBuildings.includes(c.building_distribution)) {
          arrChanel.push(c.channel_name.trim());
          arrEGroups.push(c.equipment_group && c.equipment_group.trim());
          arrCats.push(c.category && c.category.trim());
        }
      }
      arrChanel = Array.from(new Set(arrChanel));
      arrEGroups = Array.from(new Set(arrEGroups));
      arrEGroups = arrEGroups.filter(e => e !== null)
      arrEGroups = arrEGroups.map(e => ({ eg_id: "Group: " + e, eg: e }))
      arrCats = new Set(arrCats)
      arrCats.delete("Source")
      arrCats.delete("Building Distribution")
      arrCats = Array.from(arrCats);
      arrCats = arrCats.filter(e => e !== null)
      arrChanel.sort(function (a, b) {
        var titleA = a.toUpperCase();
        var titleB = b.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setCircuitOptions(arrChanel);
      arrEGroups.sort(function (a, b) {
        var titleA = a.eg.toUpperCase();
        var titleB = b.eg.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setEGroupOptions(arrEGroups);
      arrCats.sort(function (a, b) {
        var titleA = a.toUpperCase();
        var titleB = b.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setCategoryOptions(arrCats);
    }

    const cancelToken = API.cancelToken();
    const auth = context.getUser.token;
    var currentDate = new Date();
    var hour = currentDate.toLocaleTimeString('en-US', { hour12: false }).split(":")[0]
    var currentDateFormatted = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

    API.getHierarchyConsumption(cancelToken, auth, currentDateFormatted, hour, context.getBuildingInfo.building_id).then(async res => {
      let main = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)
      let dist = DiagramFuncs.getTree(context.getBuildingInfo, "1", selectedBuildings);
      const sum = res.data[main.circuit_name]
      for (let node of dist) {
        if (node.name.includes('Cap Bank')) {
          node.consumption = 'N/A'
          node.percentage = 'N/A'
          continue
        }
        node.consumption = node.name in res.data ? res.data[node.name].toFixed(2) + " kWh" : "0 kWh"
        node.percentage = node.name in res.data ? ((res.data[node.name] / sum) * 100).toFixed(2) : "0"
      }
      sortHierarchy(dist)
      setDistHierarchy(dist);
      setDistHierarchy2(dist);
      let eg = DiagramFuncs.getTree(context.getBuildingInfo, "2", selectedBuildings);
      let egDict = {}
      let egArr = []
      for (let node of eg) {
        if (node.dataType === 'equipment_group') {
          egArr.push(node)
          continue
        }
        egDict[node.parent] = egDict[node.parent] || 0
        egDict[node.parent] += res.data[node.name]
        node.consumption = node.name in res.data ? res.data[node.name].toFixed(2) + " kWh" : "0 kWh"
        node.percentage = node.name in res.data ? ((res.data[node.name] / sum) * 100).toFixed(2) : "0"
      }
      for (let node of egArr) {
        node.consumption = node.id in egDict ? egDict[node.id].toFixed(3) + " kWh" : "0 kWh"
        node.percentage = node.id in egDict ? ((egDict[node.id] / sum) * 100).toFixed(2) : "0"
      }
      sortHierarchy(eg)
      setEgHierarchy(eg);
      setEgHierarchy2(eg);

      let cat = DiagramFuncs.getTree(context.getBuildingInfo, "3", selectedBuildings);
      let catDict = {}
      let subcatDict = {}
      let catArr = []
      let subcatArr = []
      for (let node of cat) {
        if (node.dataType === 'category') {
          catArr.push(node)
          continue
        }
        if (node.dataType === 'sub_category') {
          subcatArr.push(node)
          continue
        }
        subcatDict[node.parent] = subcatDict[node.parent] || 0
        subcatDict[node.parent] += res.data[node.name]
        node.consumption = node.name in res.data ? res.data[node.name].toFixed(2) + " kWh" : "0 kWh"
        node.percentage = node.name in res.data ? ((res.data[node.name] / sum) * 100).toFixed(2) : "0"
      }
      for (let node of subcatArr) {
        catDict[node.parent] = catDict[node.parent] || 0
        catDict[node.parent] += subcatDict[node.id]
        node.consumption = node.id in subcatDict ? subcatDict[node.id].toFixed(3) + " kWh" : "0 kWh"
        node.percentage = node.id in subcatDict ? ((subcatDict[node.id] / sum) * 100).toFixed(2) : "0"
      }
      for (let node of catArr) {
        node.consumption = node.id in catDict ? catDict[node.id].toFixed(3) + " kWh" : "0 kWh"
        node.percentage = node.id in catDict ? ((catDict[node.id] / sum) * 100).toFixed(2) : "0"
      }
      sortHierarchy(cat)
      setCatHierarchy(cat);
      setCatHierarchy2(cat);

      const dUnits = { 'l.': "L", "ft^3": "ft³" }

      if (context.getBuildingInfo.g_channels.length !== 0) {

        API.getHierarchyConsumptionD(cancelToken, auth, context.getBuildingInfo.building_id, currentDateFormatted, hour, 'Natural Gas').then(async gRes => {
          let main = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', context.getBuildingInfo.g_hierarchy)
          const sum = gRes.data[main.channel_name]
          let g = DiagramFuncs.getTree(context.getBuildingInfo, "4", selectedBuildings);
          for (let node of g) {
            node.consumption = node.name in gRes.data ? gRes.data[node.name].toFixed(2) + ` ${dUnits[main.channel.input_unit]}` : `0 ${dUnits[main.channel.input_unit]}`
            node.percentage = node.name in gRes.data ? ((gRes.data[node.name] / sum) * 100).toFixed(2) : "0"
          }
          sortHierarchy(g)
          setGHierarchy(g);
          setGHierarchy2(g);
        }).catch(e => {
          if (e.message === 'cancelling') return
          console.log(e)
        })
      } else {
        setGHierarchy([])
      }

      if (context.getBuildingInfo.w_channels.length !== 0) {
        API.getHierarchyConsumptionD(cancelToken, auth, context.getBuildingInfo.building_id, currentDateFormatted, hour, 'Water').then(async wRes => {
          let main = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', context.getBuildingInfo.w_hierarchy)
          const sum = wRes.data[main.channel_name]
          let w = DiagramFuncs.getTree(context.getBuildingInfo, "5", selectedBuildings);
          for (let node of w) {
            node.consumption = node.name in wRes.data ? wRes.data[node.name].toFixed(2) + ` ${dUnits[main.channel.input_unit]}` : `0 ${dUnits[main.channel.input_unit]}`
            node.percentage = node.name in wRes.data ? ((wRes.data[node.name] / sum) * 100).toFixed(2) : "0"
          }
          sortHierarchy(w)
          setWHierarchy(w);
          setWHierarchy2(w);
        }).catch(e => {
          if (e.message === 'cancelling') return
          console.log(e)
        })
      } else {
        setWHierarchy([])
      }
    }).catch(e => {
      if (e.message === 'cancelling') return
      console.log(e)
    })

    return () => {
      API.cancel(cancelToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.getBuildingInfo, maptype, selectedBuildings])

  useEffect(() => {
    if (!context.getBuildingInfo || !context.getUserInfo) return;

    // Get building options
    let tempBuildingOptions = context.getBuildingInfo.e_channels.map(e => e.building_distribution)
    tempBuildingOptions = tempBuildingOptions.concat(context.getBuildingInfo.w_channels.map(e => e.building_distribution))
    tempBuildingOptions = tempBuildingOptions.concat(context.getBuildingInfo.g_channels.map(e => e.building_distribution))
    tempBuildingOptions = Array.from(new Set(tempBuildingOptions))
    // tempBuildingOptions = tempBuildingOptions.map(e => ({ building: context.getUserInfo.buildings.find(b => b.building_id === e).building, building_id: e }))
    // tempBuildingOptions = tempBuildingOptions.filter(e => e !== null)
    setBuildingOptions(tempBuildingOptions)
    if (selectedBuildings === null) setSelectedBuildings(tempBuildingOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.getBuildingInfo, context.getUserInfo]);

  useEffect(() => {
    let tempCircuits = [];
    for (let item in context.nodeSelected) {
      if (context.nodeSelected[item].dataType === 'circuit') {
        let circuit = GraphFuncs.findCirctuitInHierarchy(
          item,
          context.getBuildingInfo.hierarchy
        )
        if (!circuit) circuit = context.getBuildingInfo.e_sources.filter(e => e.circuit_name.trim() === item)[0]
        if (!circuit) continue
        circuit.type = 'electricity'
        tempCircuits.push(
          circuit
        );
      } else if (context.nodeSelected[item].dataType === 'gas') {
        let circuit = GraphFuncs.findCirctuitInHierarchyD(
          item,
          context.getBuildingInfo.g_hierarchy
        )
        if (!circuit) circuit = context.getBuildingInfo.g_sources.filter(e => e.channel_name.trim() === item)[0]
        if (!circuit) continue
        circuit.type = 'gas'
        tempCircuits.push(
          circuit
        );
      } else if (context.nodeSelected[item].dataType === 'water') {
        let circuit = GraphFuncs.findCirctuitInHierarchyD(
          item,
          context.getBuildingInfo.w_hierarchy
        )
        if (!circuit) circuit = context.getBuildingInfo.w_sources.filter(e => e.channel_name.trim() === item)[0]
        if (!circuit) continue
        circuit.type = 'water'
        tempCircuits.push(
          circuit
        );
      }
    }
    setCircuits(tempCircuits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.nodeSelected]);


  // Hierarchical tree generator to the selected building
  const selectBuilding = (e) => {
    if (!e.target) return
    let selection = e.target.value
    if (selectedBuildings.includes(selection)) {
      setSelectedBuildings(prev => prev.filter(e => e !== selection))
    } else {
      setSelectedBuildings(prev => [...prev, selection])
    }
  };


  // set sugestions in onChange
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    const filteredSuggestions = circuitOptions.filter(
      (suggestion) =>
        value && suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  // sugestion clicked
  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setNodePan(suggestion);
    setSuggestions([]);
    DiagramFuncs.selectCircuit({ target: { value: suggestion, name: 'circuit' } }, context.nodeSelected, context.setNodeSelected, context.getBuildingInfo)
  };


  const sortHierarchy = (tempClients) => {
    tempClients.sort(function (a, b) {
      var titleA = parseFloat(a.consumption);
      var titleB = parseFloat(b.consumption);
      if (titleA > titleB) {
        return -1;
      }
      if (titleA < titleB) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    let tempNodeSelected = Object.keys(context.nodeSelected)
    if (diagramInstance && diagramInstance.nodes && !clickedNode) {
      if (tempNodeSelected.length > 0) {
        let nodes = diagramInstance.nodes;
        for (let node of nodes) {
          let id = node.internalId.replaceAll("alsdjfklajsdfkjaherth", "").replaceAll("alsdjfklajsdfkjah", " ")
          if (tempNodeSelected[tempNodeSelected.length - 1].includes(id)) {
            setNodePan(node);
          }
        }
      }
    }
    let correctHierarchy = getCorrectHierarchy().hierarchy
    if (correctHierarchy === null) return
    for (let node of correctHierarchy) {
      if (tempNodeSelected.includes(node.id.replaceAll("alsdjfklajsdfkjaherth", ""))) {
        node.selected = true;
      } else {
        node.selected = false;
      }
    }
    setClickedNode(null);
    setCurrentHierarchy(correctHierarchy);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.nodeSelected, maptype, distHierarchy2, egHierarchy2, catHierarchy2, gHierarchy2, wHierarchy2]);

  useEffect(() => {
    if (exporting && diagramInstance) {
      const diagramFullSize = document.getElementById(
        "diagram_diagramLayer_svg"
      );

      const diagram = document.getElementById("diagramcontent");
      const inicialHeight = diagram.clientHeight
      const inicialWidth = diagram.clientWidth
      diagram.style.height = diagramFullSize.clientHeight + "px";
      diagram.style.width = diagramFullSize.clientWidth + "px";
      diagram.style.background = "transparent";
      diagramInstance.reset();
      html2canvas(diagram).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "diagram.png";
        link.click();
        diagram.style.height = inicialHeight + "px";
        diagram.style.width = inicialWidth + "px";
        setExporting(false)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exporting]);

  useEffect(() => {
    if (diagramInstance && diagramInstance.nodes) {
      if (nodePan) {
        let nodes = diagramInstance.nodes;
        for (let node of nodes) {
          let id = node.internalId.replaceAll("alsdjfklajsdfkjaherth", '').replaceAll("alsdjfklajsdfkjah", " ");
          if (nodePan === id) {
            setNodePan(node)
            return;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodePan]);

  useEffect(() => {
    if (context.nodeSelected.length > 0 || nodePan) return
    if (!diagramInstance || diagramInstance.nodes.length === 0) setLoaded(!loaded)
    else {
      let bounds = diagramInstance.nodes[0].wrapper.bounds;
      diagramInstance.bringToCenter(bounds);
      diagramInstance.clearSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramInstance])

  useEffect(() => {
    if (!nodePan || !nodePan.wrapper) return
    let bounds = nodePan.wrapper.bounds;
    diagramInstance.bringToCenter(bounds);
    diagramInstance.clearSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodePan])

  function nodeDefaults(obj, diagram) {
    obj.width = 220;
    obj.height = 85;
    obj.bold = true;
    obj.verticalAlignment = "Center";
    obj.internalId = obj.data.id.replaceAll(" ", "alsdjfklajsdfkjah");
    obj.name = obj.data.name;
    obj.dataType = obj.data.dataType;
    obj.consumption = obj.data.consumption;
    obj.device = obj.data.device;
    obj.percentage = obj.data.percentage;
    obj.style = {
      fill: obj.data.selected ? "#5F816D" : "#FFF",
      strokeColor: "none",
      color: "#1D192B",
      strokeWidth: 2,
      textAlign: "Center",
    };
    obj.shape = {
      type: "HTML",
      content: `<div class="cursor-pointer relative" data-internal=${obj.data.id.replaceAll(
        " ",
        "alsdjfklajsdfkjah"
      )} style="background:${obj.data.selected ? "#5F816D" : "#FFF"}; 
      padding: 8px 2px 8px 10px; flex-direction: column;justify-content: center; align-items: flex-start; gap:6px; border-radius: 4px; border-right: 8px ${obj.data.collapsed ? "solid" : "hidden"
        } #3A5345; border-left: 8px solid #3A5345; height:100%; width:100%; box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);">
          <div class="absolute top-[-8px] left-[-10px] w-[105%] h-[110%]">
            <div class="top-[0] right-[40px] text-right flex flex-nowrap justify-end">
              <div data-context="context" class="w-[15px] p-3 m-3">
              <svg data-context="context" id="fi_2311524" enable-background="new 0 0 32 32" height="15" viewBox="0 0 32 32" width="15" xmlns="http://www.w3.org/2000/svg"><path id="XMLID_294_" d="m13 16c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z"></path><path id="XMLID_295_" d="m13 26c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z"></path><path id="XMLID_297_" d="m13 6c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z"></path></svg>
              </div>
            </div>
          </div>
        <p style="color: ${obj.data.selected ? "#FFF" : "#000"
        };text-align:left;  font-size:14px; font-style:normal; font-weight:600; line-height:32px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">${obj.data.name
        }</p>
        <p style="color: ${obj.data.selected ? "#FFF" : "#416A53"
        }; text-align: left;font-size: 11px;font-style: normal;font-weight: 400;line-height: 20px; /* 181.818% */">previous 24 hours</p>
        <div class="flex flex-nowrap justify-between items-center">
        <p style="color: ${obj.data.selected ? "#FFF" : "#416A53"}; text-align: left;font-size: 10px;font-style: normal;font-weight: 600;line-height: 20px; /* 181.818% */">${obj.consumption}</p>
        <p style="color: ${obj.data.selected ? "#FFF" : "#416A53"}; text-align: left;font-size: 10px;font-style: normal;font-weight: 600;line-height: 20px; /* 181.818% */">${isNaN(obj.percentage) || !isFinite(obj.percentage) ? "..." : obj.percentage}% of building</p>
        </div>
      </div>`,
    };
    obj.backgroundColor = "#FFF";
    obj.borderWidth = 1;
    obj.borderColor = "#FFF";
    obj.constraints =
      NodeConstraints.Default &
      ~NodeConstraints.Drag &
      ~NodeConstraints.Delete &
      ~NodeConstraints.Rotate &
      ~NodeConstraints.AspectRatio &
      ~NodeConstraints.Expandable &
      ~NodeConstraints.Tooltip &
      ~NodeConstraints.Resize;
    return obj;
  }


  //sets connector default value
  function connectorDefaults(connector, diagram) {
    connector.style = {
      strokeColor: "#B8B8B8",
      fill: "#B8B8B8",
      strokeWidth: 2,
    };
    connector.targetDecorator = {
      style: {
        fill: "#B8B8B8",
        strokeColor: "#B8B8B8",
      },
    };
    connector.type = "Orthogonal";
    connector.constraints = 1;
    connector.cornerRadius = 5;
    return connector;
  }

  function tooledit(args) {
    switch (args.item.tooltipText) {
      case "Zoom In":
        let zoomin = { type: "ZoomIn", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomin);
        break;
      case "Zoom Out":
        let zoomout = { type: "ZoomOut", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomout);
        break;
      case "Select":
        diagramInstance.clearSelection();
        diagramInstance.drawingObject = {};
        diagramInstance.tool = DiagramTools.SingleSelect;
        break;
      case "Pan Tool":
        diagramInstance.clearSelection();
        diagramInstance.drawingObject = {};
        diagramInstance.tool = DiagramTools.ZoomPan;
        break;
      case "Maximize":
        setExpanded(!expanded);
        break;
      case "Minimize":
        setExpanded(!expanded);
        break;
      default:
        break;
      case "Reset":
        diagramInstance.reset();
        break;
      case "Download":
        setExporting(true);
        diagramInstance.reset();
        break;
      case "Print":
        diagramInstance.print({ mode: "SVG" });
        break;
      case "Expand All":
        expandNode('all')
        setCollapseAll(!collapseAll)
        break;
      case "Collapse All":
        collapseNode('all')
        setCollapseAll(!collapseAll)
        break;
    }
  }

  function updateCircuits(tree) {
    if (tree.length > 0) {
      var auxOptionsCircuit = [];
      for (let circ of tree) {
        auxOptionsCircuit.push(circ.name);
      }
      setCircuitOptions(auxOptionsCircuit);
    }
  }

  const getCorrectHierarchy = () => {
    switch (maptype) {
      case "1": default:
        return { name: "distHierarchy", hierarchy: JSON.parse(JSON.stringify(distHierarchy2)), setter: setDistHierarchy2, oHierarchy: JSON.parse(JSON.stringify(distHierarchy)) }
      case "2":
        return { name: "egHierarchy", hierarchy: JSON.parse(JSON.stringify(egHierarchy2)), setter: setEgHierarchy2, oHierarchy: JSON.parse(JSON.stringify(egHierarchy)) }
      case "3":
        return { name: "catHierarchy", hierarchy: JSON.parse(JSON.stringify(catHierarchy2)), setter: setCatHierarchy2, oHierarchy: JSON.parse(JSON.stringify(catHierarchy)) }
      case "4":
        return { name: "gHierarchy", hierarchy: JSON.parse(JSON.stringify(gHierarchy2)), setter: setGHierarchy2, oHierarchy: JSON.parse(JSON.stringify(gHierarchy)) }
      case "5":
        return { name: "wHierarchy", hierarchy: JSON.parse(JSON.stringify(wHierarchy2)), setter: setWHierarchy2, oHierarchy: JSON.parse(JSON.stringify(wHierarchy)) }
    }
  }

  const handleContextMenuSelect = (e) => {
    setPositionContextMenu({
      x: e.clientX + "px",
      y: e.clientY + "px",
    });
    setShowContextMenu(true);
  };

  const handleClick = useCallback(() => {
    if (!diagramInstance || showContextMenu) return;

    const selectedItems = diagramInstance.selectedItems.nodes;
    if (selectedItems.length > 0) {
      const node = selectedItems[0];
      if (!node) return;
      const id = node.internalId.replaceAll("alsdjfklajsdfkjaherth", "").replaceAll("alsdjfklajsdfkjah", " ");
      DiagramFuncs.selectCircuit(
        { target: { name: node.dataType, value: id } },
        context.nodeSelected,
        context.setNodeSelected,
        context.getBuildingInfo
      );
    }
  }, [diagramInstance, context.nodeSelected, context.setNodeSelected, context.getBuildingInfo, showContextMenu]);

  const handleContext = useCallback((event) => {
    if (!diagramInstance) return;
    const selectedItems = diagramInstance.selectedItems.nodes;
    if (
      diagramInstance &&
      diagramInstance.selectedItems
    ) {
      if (selectedItems[0]) {
        handleContextMenuSelect(event);
        setClickedNode(selectedItems[0])
      }
    }
  }, [diagramInstance]);


  useEffect(() => {
    if (!diagramInstance) return
    const handleMouseUp = (event) => {
      event.preventDefault();
      if (event.target.dataset.context) {
        handleContext(event);
      } else {
        handleClick();
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramInstance, handleClick])


  useEffect(() => {
    if (!getCorrectHierarchy().hierarchy) return
    if (collapseAll) {
      collapseNode('all')
    } else {
      expandNode('all')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseAll])

  const collapseNode = (node) => {
    var correctHierarchy = getCorrectHierarchy()
    var tempHierarchy = correctHierarchy.hierarchy;

    var nodesToDelete = [];
    if (node !== 'all') {
      DiagramFuncs.getChildren(tempHierarchy, node.data.id, nodesToDelete);
      tempHierarchy = tempHierarchy.filter(
        (e) => !nodesToDelete.includes(e.id)
      );
      for (let e of tempHierarchy) {
        if (e.id === node.data.id) e.collapsed = true
      }
    } else {
      tempHierarchy = tempHierarchy.filter(
        (e) => e.parent === null
      );
      for (let e of tempHierarchy) {
        e.collapsed = true
        node = JSON.parse(JSON.stringify(hierarchyDict[correctHierarchy.name]))
      }
    }
    correctHierarchy.setter(tempHierarchy);
    updateCircuits(tempHierarchy);
    setNodePan(node)
    return;
  };

  const expandNode = (node) => {
    var correctHierarchy = getCorrectHierarchy()
    var tempHierarchy = correctHierarchy.hierarchy;

    var nodesToPush = [];
    if (node !== 'all') {
      DiagramFuncs.getImmediateChildren(hierarchyDictO[correctHierarchy.name], node.data.id, nodesToPush);
      for (let n of hierarchyDictO[correctHierarchy.name]) {
        if (nodesToPush.includes(n.id)) {
          n.collapsed = true
          tempHierarchy.push(n);
        }
      }
      for (let e of tempHierarchy) {
        if (e.id === node.data.id) e.collapsed = false
      }
    } else {
      tempHierarchy = []
      for (let n of hierarchyDictO[correctHierarchy.name]) {
        node = JSON.parse(JSON.stringify(hierarchyDictO[correctHierarchy.name]))
        n.collapsed = false
        tempHierarchy.push(n);
      }
    }
    correctHierarchy.setter(tempHierarchy);
    updateCircuits(tempHierarchy);
    setNodePan(node)
    return;
  };


  const hanldeMultipleSelect = (elem) => {
    DiagramFuncs.selectCircuit(elem, context.nodeSelected, context.setNodeSelected, context.getBuildingInfo)
  }

  return (
    <>
      {/* Search Bar */}
      <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-end items-center rounded-md bg-[transparent] shadow-md">
        <div className="flex flex-nowrap justify-end items-center w-11/12 gap-3">
          <p className="text-[black] w-[70px]">View By: </p>
          {!context.getLaptop && <div className="w-[200px] flex items-center h-[36px] mt-[2px] justify-center border border-1 rounded border-[#C1C1C1] self-stretch">
            <div className="flex itens-center pl-2 justify-center self-center">
              {GlobalSVG.advancedFilter()}
            </div>
            <select
              id="distributions"
              className="w-[200px] h-[36px] bg-[transparent] text-normal text-[grey] leading-4"
              defaultValue={maptype}
              onChange={(e) => setMapType(e.currentTarget.value)}
            >
              <option className="text-[#000000]" value="1">Distribution</option>
              <option className="text-[#000000]" value="3">Categories</option>
              <option className="text-[#000000]" value="2">Equipment Groups</option>
              {context.getBuildingInfo && context.getBuildingInfo.g_channels.length > 0 && <option className="text-[#000000]" value="4">Gas</option>}
              {context.getBuildingInfo && context.getBuildingInfo.w_channels.length > 0 && <option className="text-[#000000]" value="5">Water</option>}
            </select>
          </div>}
          {
            !context.getLaptop && buildingOptions.length > 0 && <MultipleSelect data={buildingOptions} dataType='building' selectItem={selectBuilding} selectedList={selectedBuildings} placeholder="Filter Buildings" idAttr="building" valAttr="building" />
          }
          {
            !context.getLaptop && maptype === "2" && circuitOptions.length > 0 && <MultipleSelect data={eGroupOptions} dataType='equipment_group' selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Equipment Groups" idAttr="eg_id" valAttr="eg" />
          }
          {
            !context.getLaptop && maptype === "3" && circuitOptions.length > 0 && <MultipleSelect data={categoryOptions} dataType='category' selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Categories" />
          }
          {
            !context.getLaptop && circuitOptions.length > 0 && <MultipleSelect data={circuitOptions} dataType={typeDict[maptype]} selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Circuits" />
          }
          <div className="relative h-10 flex justify-center items-center gap-3 w-[250px]">
            <div className="relative flex flex-nowrap h-9 p-2.5 gap-2.5 items-center rounded-md border-[1px] border-[#C1C1C1]">
              <input
                type="search"
                className="text-[#AFAAB1] text-base text-normal leading-4 h-9 border-0 bg-[transparent] px-2 placeholder-[grey]"
                placeholder="Search"
                aria-label="Search"
                value={inputValue}
                onChange={handleInputChange}
              />
              <span className="input-group-text flex items-center rounded py-1.5">
                {GlobalSVG.search()}
              </span>
            </div>
            {suggestions.length > 0 && (
              <div
                className={`absolute top-[94%] w-full h-[250px] overflow-auto flex flex-col px-1 py-4 whitespace-nowrap border border-[#C1C1C1] z-10 rounded bg-[#FFF]`}
              >
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      className="cursor-pointer p-1 mx-1"
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {context.getLaptop &&
          <div className="input-group-text flex items-center whitespace-nowrap cursor-pointer" onClick={() => setOpenFilters(!openFilters)}>
            {GlobalSVG.menuExport()}
            <p className="text-sm text-normal leading-4">Filter</p>
          </div>}
      </div>


      {context.getLaptop && <div className={`${openFilters ? 'tablet:w-[300px] mobile:w-full p-3' : "w-0"} card alert-sidebar transition-width duration-300 ease-in-out h-full fixed right-0 tablet:top-[10] mobile:top-[10] z-40 background-white`}>
        {context.getLaptop && openFilters &&
          <div className="flex flex-nowrap justify-center items-center my-4">
            <div className="flex w-[200px] items-center p-1 my-1 justify-center border border-1 rounded bg-[#619E7B] border-[#C1C1C1] self-stretch">
              <div className="flex items-center pl-2 justify-center self-center">
                {GlobalSVG.advancedFilter()}
              </div>
              <select
                id="distributions"
                className="w-61 bg-[transparent] text-normal text-[#FFF] leading-4"
                defaultValue={maptype}
                onChange={(e) => setMapType(e.currentTarget.value)}
              >
                <option className="text-[#000000]" value="1">Distribution</option>
                <option className="text-[#000000]" value="3">Categories</option>
                <option className="text-[#000000]" value="2">Equipment Groups</option>
                <option className="text-[#000000]" value="4">Gas</option>
                <option className="text-[#000000]" value="5">Water</option>
              </select>
            </div>
          </div>
        }
        {
          context.getLaptop && openFilters && buildingOptions.length > 0 && <MultipleSelect data={buildingOptions} dataType='building' selectItem={selectBuilding} selectedList={selectedBuildings} placeholder="Filter Buildings" idAttr="building" valAttr="building" />
        }
        {
          context.getLaptop && openFilters && maptype === "2" && circuitOptions.length > 0 && <MultipleSelect data={eGroupOptions} dataType='equipment_group' selectItem={hanldeMultipleSelect} selectedList={context.nodeSelected} placeholder="Select Equipment Groups" idAttr="eg_id" valAttr="eg" />
        }
        {
          context.getLaptop && openFilters && maptype === "3" && circuitOptions.length > 0 && <MultipleSelect data={categoryOptions} dataType='category' selectItem={hanldeMultipleSelect} selectedList={context.nodeSelected} placeholder="Select Categories" />
        }
        {
          context.getLaptop && openFilters && circuitOptions.length > 0 && <MultipleSelect data={circuitOptions} dataType={typeDict[maptype]} selectItem={hanldeMultipleSelect} selectedList={context.nodeSelected} placeholder="Select Circuits" />
        }
      </div>}

      <div className={`flex flex-1 laptop:p-8 laptop:pb-3 w-full ${expanded || !context.nodeSelected || Object.keys(context.nodeSelected).length === 0 ? "h-[726px]" : "h-[564px]"} justify-center items-center self-stretch bg-[transparent]`} >
        {distHierarchy && egHierarchy && catHierarchy && wHierarchy && gHierarchy ? (
          <div className="relative flex flex-1 w-full h-full px-8 pt-6 pb-2.5 justify-center items-center gap-16 self-stretch rounded-lg bg-[transparent] shadow-md">
            <div className="w-full bg-[transparent]">
              <div className="col-lg-12 control-section bg-[transparent]">
                <div className="content-wrapper bg-[transparent]" style={{ width: "100%" }} >
                  <div id={"container-diagram"} className="bg-[transparent]">
                    <DiagramComponent
                      id="diagram"
                      ref={(diagram) => (setDiagramInstance(diagram))}
                      snapSettings={{ constraints: SnapConstraints.SnapToObject }}
                      height={
                        expanded || !context.nodeSelected || Object.keys(context.nodeSelected).length === 0 || exporting
                          ? "650px"
                          : "500px"
                      }
                      created={() => {

                      }}
                      layout={{
                        type: "HierarchicalTree",
                        margin: {
                          top: 10,
                          bottom: 10,
                        },
                        orientation: "LeftToRight",
                        verticalSpacing: 100,
                        horizontalSpacing: 10,
                        verticalAlignment: "Center",
                      }}
                      tool={DiagramTools.SingleSelect}
                      dataSourceSettings={{
                        id: "id",
                        parentId: "parent",
                        dataSource: new DataManager(currentHierarchy),
                      }}
                      getNodeDefaults={(node, diagram) => {
                        return nodeDefaults(node, diagram);
                      }}
                      getConnectorDefaults={(connector, diagram) => {
                        return connectorDefaults(connector, diagram);
                      }}
                      mode="SVG"
                    >
                      <Inject
                        services={[DataBinding, HierarchicalTree, PrintAndExport]}
                      />
                    </DiagramComponent>
                  </div>
                </div>
              </div>

              <div className="w-full col-lg-4 property-section">
                <div className="property-panel-header"></div>

                <div
                  className="row w-full property-panel-content"
                  style={{ paddingTop: "10px" }}
                ></div>
              </div>
            </div>
            {showContextMenu && (
              <ContextMenu
                setShow={setShowContextMenu}
                node={clickedNode}
                setClickedNode={setClickedNode}
                position={positionContextMenu}
                collapseNode={collapseNode}
                expandNode={expandNode}
              ></ContextMenu>
            )}
            <div className="absolute bottom-[30px] right-[0px] inline-flex w-8 flex-col items-center p-2 gap-1 shrink rounded bg-[transparent] shadow">
              <ToolbarComponent id="toolbar_diagram" clicked={tooledit}>
                <ItemsDirective>
                  <ItemDirective
                    prefixIcon="e-icons e-download"
                    tooltipText="Download"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-zoom-in"
                    tooltipText="Zoom In"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-zoom-out"
                    tooltipText="Zoom Out"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-mouse-pointer"
                    tooltipText="Select"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-pan"
                    tooltipText="Pan Tool"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-reset"
                    tooltipText="Reset"
                  />
                  <ItemDirective
                    prefixIcon={`e-icons ${expanded ? "e-align-top" : "e-align-bottom"}`}
                    tooltipText={expanded ? "Minimize" : "Maximize"}
                  />
                  <ItemDirective
                    prefixIcon={`e-icons ${collapseAll ? "e-full-screen" : "e-exit-full-screen"}`}
                    tooltipText={collapseAll ? 'Expand All' : 'Collapse All'}
                  />
                </ItemsDirective>
              </ToolbarComponent>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <p className="laptop:pl-8 text-sm">(*) Virtual Circuit</p>
      {/* Distributions Analisys */}
      {circuits && circuits.length > 0 && (
        <div className='laptop:mx-8 laptop:mb-8 tablet:mb-4 mobile:my-4'>
          <MainGraph circuits={circuits ? circuits : []} dist={true} />
        </div>
      )}
    </>
  );
};

export default Distribuition;
