import city from '../../assets/lottie/city.json'
import road from '../../assets/lottie/road.json'
import Lottie from 'lottie-react'
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';

const PublicEmissions = () => {

    const location = useLocation();
    const [reduction, setReduction] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const [level, setLevel] = useState(null)
    const [animation, setAnimation] = useState(null)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const Integer = ({ n, from }) => {
        const { number } = useSpring({
            from: { number: from },
            number: n,
            delay: 3000,
            config: { mass: 1, tension: 20, friction: 10 },
            format: (value) => value.toLocaleString()
        })
        return <animated.div>{number.to((n) => Math.ceil(n).toLocaleString())}</animated.div>
    }

    useEffect(() => {
        const levelDict = { 'building': 0, 'portfolio': 1, 'greenwave': 2 }
        let id = queryParams.get('id')
        let arr = location.pathname.split("/")
        setLevel(levelDict[arr[arr.length - 2]])
        setAnimation(arr[arr.length - 1])

        const cancelToken = API.cancelToken();
        const getData = async () => {
            try {
                let reduction = await API.getPublicImpact(cancelToken, 'co', levelDict[arr[arr.length - 2]], id)
                let tempMeta = {}
                if (arr[arr.length - 2] === 'building') {
                    tempMeta = await API.getPublicBuilding(cancelToken, id)
                } else if (arr[arr.length - 2] === 'portfolio') {
                    tempMeta = await API.getPublicClient(cancelToken, id)
                } else {
                    tempMeta.data = 'greenwave'
                }
                setMetadata(tempMeta.data)
                setReduction(reduction.data)

            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if ('error' in err.response.data) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }
        }

        getData()

        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location.pathname])


    return (
        <div className='flex flex-nowrap justify-between items-center h-full relative'>
            <div className="w-1/2 desktop:py-[106px] desktop:px-[96px] laptop:py-[50px] laptop:px-[50px] h-screen flex flex-col justify-between items-stretch">
                <div className=''>
                    {
                        metadata && level === 0 &&
                        <div className='flex flex-nowrap justify-start items-center h-[143px]'>
                            <div class="relative w-full h-full max-w-[143px] max-h-[143px] mr-4">
                                <div class="absolute inset-0 rounded-full overflow-hidden">
                                    <img class="w-full h-full object-cover object-center" src={metadata.image} alt="" />
                                </div>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center h-[143px] border-y border-[#979797] '>
                                <div className='flex flex-col flex-nowrap justify-center item-center h-full'>
                                    <div className="flex flex-nowrap justify-center item-center desktop:text-[32px] laptop:text-[20px] text-[#383838] font-semibold pr-[36px]">
                                        {metadata.building}
                                    </div>
                                </div>
                                <div className='border-[#979797] border h-[60%]'></div>
                                <div className="flex flex-nowrap justify-start item-center h-full">
                                    <img className='h-full' src={metadata.logo} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        metadata && level === 1 &&
                        <div className='border-y border-[#979797] w-fit h-[143px] '>
                            <div className='border-y border-[#979797] flex flex-nowrap justify-start items-center w-auto h-[143px] '>
                                <div className="flex flex-nowrap justify-start item-center w-[140px] h-[140px] pr-4">
                                    <img className='' src={metadata.logo} alt="" />
                                </div>
                                <div className='border-[#979797] border h-[60%] mr-4'></div>
                                <div className='flex flex-col flex-nowrap justify-center item-center h-full'>
                                    <div className="flex flex-nowrap justify-center item-center desktop:text-[32px] laptop:text-[20px] text-[#383838] font-semibold pr-[36px] ">
                                        {metadata.client_name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        level === 2 &&
                        <div className='flex flex-nowrap justify-start items-center h-[143px] w-full border-y border-[#979797] '>
                            <div className="flex flex-nowrap justify-start item-center w-[60%] mr-5">
                                <img className='w-full' src={require('../../assets/images/greenwave-coloured.png')} alt="" />
                            </div>
                        </div>
                    }
                </div>
                {reduction &&
                    <div className='mt-[-100px]'>
                        {metadata === 'greenwave' && <p className='text-[40px] text-[#383838]'>The Greenwave Network Has Reduced</p>}
                        {metadata !== 'greenwave' && <p className='text-[40px] text-[#383838]'>{`${level === 0 ? "This Building" : "Our Portfolio"} Has Reduced`}</p>}
                        <div className='text-[164px] text-[#2F7DBC] font-extrabold'>
                            <Integer n={reduction.reduction_to_date} from={reduction.reduction_to_date - 0.05 < 0 ? 0 : reduction.reduction_to_date - 0.05} />
                        </div>
                        <p className='text-[40px] text-[#383838]'>tonnes of CO₂e</p>
                    </div>}
                <div className='flex flex-nowrap justify-between items-center w-full'>
                    <img className='w-[353px] ' src={require('../../assets/images/greenwave-black.png')} alt="" />
                    <div>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            Making our world more responsible...
                        </p>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            one building at a time!
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-1/2 relative h-screen">
                <div className='w-full h-full overflow-hidden'>
                    {reduction && animation === 'house' && <Lottie animationData={city} style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }} />}
                    {reduction && animation === 'car' && <Lottie animationData={road} style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }} />}
                </div>
                {reduction && animation === 'house' &&
                    <div className='absolute top-0 right-0 flex flex-nowrap justify-center items-center w-full h-screen mt-[50px]'>
                        <div className='text-[white]'>
                            <div className='text-[164px] font-bold text-center'>
                                <Integer n={reduction.reduction_to_date / 1.4956} from={(reduction.reduction_to_date - 0.05) / 1.4956 < 0 ? 0 : (reduction.reduction_to_date - 0.05) / 1.4956} />
                            </div>
                            <p className='text-[40px] font-semibold text-center'>household’s annual</p>
                            <p className='text-[40px] font-semibold text-center'>electricity use</p>
                        </div>
                    </div>}
                {reduction && animation === 'car' &&
                    <div className='absolute top-0 right-0 flex flex-nowrap justify-center items-center w-full h-screen mt-[50px]'>
                        <div className='text-[white]'>
                            <div className='text-[164px] font-bold text-center'>
                                <Integer n={(reduction.reduction_to_date / 3.26)} from={(reduction.reduction_to_date - 0.05) / 3.26 < 0 ? 0 : (reduction.reduction_to_date - 0.05) / 3.26} />
                            </div>
                            <p className='text-[40px] font-semibold text-center'>passenger vehicles taken</p>
                            <p className='text-[40px] font-semibold text-center'>off the road for 1 year</p>
                        </div>
                    </div>}
            </div>
            <div className={`absolute w-full flex flex-nowrap items-center justify-center`}>
                <div className={`flex flex-nowrap items-center justify-center rounded-full w-[105px] h-[105px] bg-[white] drop-shadow-md`}>
                    {GlobalSVG.equal()}
                </div>
            </div>
        </div>
    )
};

export default PublicEmissions;