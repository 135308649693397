import LoaderWheel from "../LoaderWheel";
import { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/ContextProvider';
import API from "../../utils/API";
import Swal from 'sweetalert2';
import GlobalSVG from "../../utils/GlobalSVG";
import moment from 'moment';

const MaintenanceLogLine = (props) => {
    const context = useContext(AppContext);
    const [deleting, setDeleting] = useState(false)
    const [saving, setSaving] = useState(false)
    const [log, setLog] = useState()
    const [edit, setEdit] = useState(false)
    const statusColor = { 'Upcoming': '#107048', 'Due': '#F9941E', 'Set': '#B8BBB9', 'Overdue': '#F75D5F' }
    const [showNote, setShowNote] = useState(false)


    useEffect(() => {
        if (!props.log) return
        setLog(props.log)
        if (props.adding) setEdit(true)
    }, [props])

    const handleSave = () => {
        const auth = context.getUser.token;
        setSaving(true)
        let groupId
        if (log.channel_name || log.type) {
            log.activity_id = props.equipmentList ? props.equipmentList[log.channel_name].id : props.groupsList[log.type].id
            log.threshold = props.equipmentList ? props.equipmentList[log.channel_name].threshold : props.groupsList[log.type].threshold
            log.tolerance = props.equipmentList ? props.equipmentList[log.channel_name].tolerance : props.groupsList[log.type].tolerance
            log.timebase = props.equipmentList ? props.equipmentList[log.channel_name].timebase : props.groupsList[log.type].timebase
            groupId = props.equipmentList ? props.group.id : props.groupsList[log.type].group_id
        } else {
            let text = 'Please select an activity then try saving again!'
            if (!log.channel_name) text = 'Please select an equipment then try saving again!'
            Swal.fire({
                title: 'Oops!',
                text: text,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
            setSaving(false)
            return
        }
        if (log.completed_date) log.completed_date = moment(log.completed_date).format("YYYY-MM-DD")
        if (props.adding) {
            API.createMaintenanceLog(auth, log).then(async res => {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === groupId) {
                        let logs = [...e.logs] || []
                        logs.push(res.data)
                        return { ...e, logs: JSON.parse(JSON.stringify(logs)) };
                    }
                    return e;
                });
                props.setGroups(updatedGroups);
                props.updateNumbers(updatedGroups)
                setEdit(false)
                props.setAdding(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        } else {
            let group_id = log.group_id
            let forbidden = ["id", "updated_by", "created_at", "updated_at", "activity_id", "runtime", "daysOverdue", "onTime", "channel_name", "percent", "group_id", "type"]
            let payload = JSON.parse(JSON.stringify(log))
            for (let key in payload) {
                if (forbidden.includes(key)) delete payload[key]
            }
            API.updateMaintenanceLog(auth, payload, log.id).then(async res => {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === group_id) {
                        let logs = e.logs.filter(e => e.id !== log.id)
                        logs.push(log)
                        return { ...e, logs: JSON.parse(JSON.stringify(logs)) };
                    }
                    else return e
                });
                props.setGroups(updatedGroups);
                props.updateNumbers(updatedGroups)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        }
    }

    const updateLog = (event) => {
        setLog(e => ({ ...e, [event.target.name]: event.target.value }))
    }

    const handleDelete = (id) => {
        const auth = context.getUser.token;
        setDeleting(true)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateMaintenanceLog(auth, { deleted: true }, id).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === log.group_id) {
                            let logs = e.logs.filter(e => e.id !== log.id)
                            return { ...e, logs: JSON.parse(JSON.stringify(logs)) };
                        }
                        else return e
                    });
                    props.setGroups(updatedGroups);
                    props.updateNumbers(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(false)
            }
        });
    }

    return (
        <div>
            {
                !edit && showNote &&
                <div className="absolute top-[25vh] left-[25vw] w-[50vw] h-[50vh] z1000 overflow-auto bg-[#f9f9f9] p-[15px] rounded card">
                    <div className="w-full flex flex-nowrap justify-end cursor-pointer">
                        <div onClick={() => setShowNote(false)}>
                            {GlobalSVG.close('black')}
                        </div>
                    </div>
                    <div className="text-[#b9b9b9] overflow-auto">
                        {log.notes ? log.notes : "No notes."}
                    </div>
                </div>
            }
            {log &&
                <div style={{ color: statusColor[log.status] }} className='flex flex-nowrap justify-between items-center py-4 my-2 w-full border-b border-1 border-[#B8BBB9] relative'>
                    <input
                        type="date"
                        name="completed_date"
                        disabled={!edit}
                        className={`w-[82px] text-[12px] ${!edit ? "bg-[transparent]" : "bg-[#FAFAFA]"}`}
                        defaultValue={log.completed_date ? log.completed_date.split('T')[0] : ""}
                        onChange={updateLog} />
                    {props.equipmentList && <select
                        onChange={updateLog}
                        className={`w-[100px] text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[#FAFAFA]"}`}
                        disabled={!edit}
                        name="channel_name"
                        value={log.channel_name && log.channel_name}>
                        <option value=""> - </option>
                        {
                            Object.keys(props.equipmentList).map((e, id) => (
                                <option key={id + e} value={e}>{e}</option>
                            ))
                        }
                    </select>}
                    {props.groupsList && <select
                        onChange={updateLog}
                        className={`w-[100px] text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[#FAFAFA]"}`}
                        disabled={!edit}
                        name="type"
                        value={log.type && log.type}>
                        <option value=""> - </option>
                        {
                            Object.keys(props.groupsList).map((e, id) => (
                                <option key={id + e} value={e}>{e}</option>
                            ))
                        }
                    </select>}
                    <div className="text-[12px] w-[70px] outline-none">{log.threshold ? log.threshold : " - "}{log.timebase === 'runtime' ? "h" : " days"}</div>
                    <div className="text-[12px] w-[70px] outline-none">{log.tolerance ? log.tolerance : " - "}{log.timebase === 'runtime' ? "h" : " days"}</div>
                    <div className="text-[12px] w-[70px] outline-none">{log.runtime ? parseInt(log.runtime).toLocaleString() : " - "}{log.timebase === 'runtime' ? "h" : " days"}</div>
                    <div className={`w-[70px] text-[12px] ${log.daysOverdue || log.daysOverdue === 0 ? "" : "pl-[25px]"}`}>{log.daysOverdue || log.daysOverdue === 0 ? parseInt(log.daysOverdue).toLocaleString() + (log.timebase === 'runtime' ? "h" : " days") : " - "}</div>
                    <div className={`w-[110px] text-[12px] ${log.percent || log.percent === 0 ? "" : "pl-[25px]"}`}>{log.percent || log.percent === 0 ? parseInt(log.percent * 100).toLocaleString() + "%" : " - "}</div>
                    <input
                        type="text"
                        name="performed_by"
                        disabled={!edit}
                        className={`w-[95px] text-[12px] ${!edit ? "bg-[transparent]" : "bg-[#FAFAFA]"}`}
                        defaultValue={log.performed_by ? log.performed_by : ""}
                        onChange={updateLog} />
                    <div className={`w-1/12 text-[12px] ${log.updated_at ? "" : "pl-[25px]"}`}>{log.updated_at ? moment(log.updated_at).format('YYYY-MM-DD') : " - "}</div>
                    <div className={`w-1/12 text-[12px] ${log.updated_by ? "" : "pl-[25px]"}`}>{log.updated_by ? log.updated_by : " - "}</div>
                    <div className="">
                        <div className="flex flex-nowrap justify-end items-center">
                            {edit &&
                                (saving ?
                                    <LoaderWheel /> :
                                    <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[21px] mr-[4px]' >
                                    <div onClick={() => setShowNote(true)}>{GlobalSVG.info('black')}</div>
                                </div>
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                                    {context.getUserInfo && context.getUserInfo.update_buildings_and_operators && GlobalSVG.edit('black')}
                                </div>
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]'>
                                    {context.getUserInfo && context.getUserInfo.update_buildings_and_operators &&
                                        (deleting ?
                                            <LoaderWheel /> :
                                            <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete(log.id)}>{GlobalSVG.delete()}</div>)
                                    }
                                </div>
                            }
                        </div>

                    </div>

                </div>}
            {
                edit &&
                <textarea
                    data-gramm="false"
                    data-gramm_editor="false"
                    data-enable-grammarly="false"
                    type="text"
                    name="notes"
                    disabled={!edit}
                    placeholder="Notes"
                    className="text-sm text-[#332D41] mb-[10px] w-full outline-none hide-input-background p-[10px]"
                    defaultValue={log.notes}
                    onChange={updateLog}></textarea>
            }
        </div>
    )
}
export default MaintenanceLogLine