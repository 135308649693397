import GraphFuncs from "./GraphFuncs";

const { isEmpty } = require("./utils");

const DiagramFuncs = {
  getCircuitsByEg: (eg, channels) => {
    let circuits = new Set()
    let group = eg.replace("Group: ", "")
    for (let channel of channels) {
      if (channel.equipment_group === group) {
        circuits.add(channel.channel_name)
      }
    }
    return Array.from(circuits)
  },

  // Select option of circuits
  selectCircuit: (elem, nodeSelected, setNodeSelected, buildingInfo) => {
    let tempNodeSelected = JSON.parse(JSON.stringify(nodeSelected))
    if (elem.target.value && elem.target.value !== "all") {
      if (!(elem.target.value in tempNodeSelected)) {
        tempNodeSelected[elem.target.value] = { name: elem.target.value, dataType: elem.target.name }
        if (elem.target.name === 'equipment_group') {
          let egCircuits = DiagramFuncs.getCircuitsByEg(elem.target.value, buildingInfo.e_channels)
          for (let c of egCircuits) {
            tempNodeSelected[c] = { name: c, dataType: 'circuit' }
          }
        }
        if (elem.target.name === 'category') {
          let egCircuits = DiagramFuncs.getCircuitsByCategory(elem.target.value, buildingInfo.e_channels)
          for (let c of egCircuits) {
            tempNodeSelected[c] = { name: c, dataType: 'circuit' }
          }
          let subcats = DiagramFuncs.getSubcatByCategory(elem.target.value, buildingInfo.e_channels)
          for (let s of subcats) {
            tempNodeSelected[(elem.target.value + s)] = { name: s, dataType: 'sub_category' }
          }
        }
        if (elem.target.name === 'sub_category') {
          let egCircuits = DiagramFuncs.getCircuitsBySubcategory(elem.target.value, buildingInfo.e_channels)
          for (let c of egCircuits) {
            tempNodeSelected[c] = { name: c, dataType: 'circuit' }
          }
        }
      } else {
        delete tempNodeSelected[elem.target.value]
        if (elem.target.name === 'circuit') {
          // Get cat
          let cat = DiagramFuncs.getCatByCirctuit(elem.target.value, buildingInfo.e_channels)
          // Delet cat selection
          delete tempNodeSelected[cat]
          // Get subcats
          let subcats = DiagramFuncs.getSubcatByCategory(cat, buildingInfo.e_channels)
          // Delete subcat selections
          for (let s of subcats) {
            delete tempNodeSelected[(cat + s)]
          }
          // Get EG
          let eg = DiagramFuncs.getEgByCirctuit(elem.target.value, buildingInfo.e_channels)
          // Delete EG selection
          delete tempNodeSelected[eg]
        }
        if (elem.target.name === 'equipment_group') {
          let egCircuits = DiagramFuncs.getCircuitsByEg(elem.target.value, buildingInfo.e_channels)
          for (let c of egCircuits) {
            delete tempNodeSelected[c]
          }
        }
        else if (elem.target.name === 'category') {
          let egCircuits = DiagramFuncs.getCircuitsByCategory(elem.target.value, buildingInfo.e_channels)
          for (let c of egCircuits) {
            delete tempNodeSelected[c]

          }
          let subcats = DiagramFuncs.getSubcatByCategory(elem.target.value, buildingInfo.e_channels)
          for (let s of subcats) {
            delete tempNodeSelected[(elem.target.value + s)]
          }
        }
        else if (elem.target.name === 'sub_category') {
          let egCircuits = DiagramFuncs.getCircuitsBySubcategory(elem.target.value, buildingInfo.e_channels)
          for (let c of egCircuits) {
            delete tempNodeSelected[c]
          }
        }
      }
      setNodeSelected(tempNodeSelected);
    } else if (elem.target.value === "all") {
      setNodeSelected({});
    }
  },

  getCircuitsByCategory: (cat, channels) => {
    let circuits = new Set()
    for (let channel of channels) {
      if (channel.category === cat) {
        circuits.add(channel.channel_name)
      }
    }
    return Array.from(circuits)
  },

  getSubcatByCategory: (cat, channels) => {
    let subcats = new Set()
    for (let channel of channels) {
      if (channel.category === cat) {
        let subcat = channel.sub_category
        if (subcat === null) subcat = channel.category
        subcats.add(subcat)
      }
    }
    return Array.from(subcats)
  },

  getCircuitsBySubcategory: (subcat, channels) => {
    let circuits = new Set()
    for (let channel of channels) {
      let tempsubcat = channel.sub_category
      if (tempsubcat === null) tempsubcat = channel.category
      if ((channel.category + tempsubcat) === subcat) {
        circuits.add(channel.channel_name)
      }
    }
    return Array.from(circuits)
  },

  getCatByCirctuit: (circuit, channels) => {
    for (let channel of channels) {
      if (channel.channel_name === circuit) {
        return channel.category
      }
    }
  },

  getEgByCirctuit: (circuit, channels) => {
    for (let channel of channels) {
      if (channel.channel_name === circuit) {
        return "Group: " + channel.equipment_group
      }
    }
  },

  getChildren: (tempHierarchy, parentName, nodes) => {
    for (let n of tempHierarchy) {
      if (n.parent === parentName) {
        nodes.push(n.id);
        DiagramFuncs.getChildren(tempHierarchy, n.id, nodes);
      }
    }
  },

  getImmediateChildren: (tempHierarchy, parentName, nodes) => {
    for (let n of tempHierarchy) {
      if (n.parent === parentName) {
        nodes.push(n.id);
      }
    }
  },

  getTree: (buildingInfo, maptype, selectedBuildings) => {
    let auxHierarchy = []
    switch (maptype) {
      case "1": default:
        DiagramFuncs.treeGenerator(auxHierarchy, buildingInfo.hierarchy, null, selectedBuildings)
        DiagramFuncs.addSources(auxHierarchy, buildingInfo.e_sources, buildingInfo.hierarchy)
        break

      case "2":
        DiagramFuncs.egTreeGenerator(auxHierarchy, buildingInfo.e_channels, selectedBuildings)
        break

      case "3":
        DiagramFuncs.catTreeGenerator(auxHierarchy, buildingInfo.e_channels, selectedBuildings)
        break

      case "4":
        DiagramFuncs.dTreeGenerator(auxHierarchy, buildingInfo.g_hierarchy, null, selectedBuildings, 'gas')
        break

      case "5":
        DiagramFuncs.dTreeGenerator(auxHierarchy, buildingInfo.w_hierarchy, null, selectedBuildings, 'water')
        break
    }

    return auxHierarchy
  },

  // Recursive hierarchy generator
  treeGenerator: (auxHierarchy, hierarchy, parent, selectedBuildings) => {
    for (let key in hierarchy) {
      if (selectedBuildings.length > 0 && !selectedBuildings.includes(hierarchy[key].channels[0].building_distribution)) continue
      let node = {
        id: hierarchy[key].circuit_name.trim(),
        name: hierarchy[key].circuit_name.trim(),
        device: hierarchy[key].channels[0].device_id.trim(),
        parent: parent === null ? parent : parent.trim(),
        selected: false,
        collapsed: false,
        dataType: 'circuit',
        shape: {
          type: "HTML",
          content: hierarchy[key].circuit_name.trim(),
        },
      };
      auxHierarchy.push(node);
      if (!isEmpty(hierarchy[key].children)) {
        DiagramFuncs.treeGenerator(auxHierarchy, hierarchy[key].children, key, selectedBuildings);
      }
    }
  },

  egTreeGenerator: (auxHierarchy, channels, selectedBuildings) => {
    let addedCircuits = []
    let equipmentGroups = []
    for (let channel of channels) {
      if (selectedBuildings.length > 0 && !selectedBuildings.includes(channel.building_distribution)) continue
      if (channel.equipment_group !== null && !equipmentGroups.includes(channel.equipment_group)) {
        let node = {
          id: "Group: " + channel.equipment_group.trim(),
          name: "Group: " + channel.equipment_group.trim(),
          parent: null,
          selected: false,
          collapsed: false,
          dataType: 'equipment_group',
          shape: {
            type: "HTML",
            content: "Group: " + channel.equipment_group.trim(),
          },
        };
        auxHierarchy.push(node);
        equipmentGroups.push(channel.equipment_group.trim(),)
      }
      if (channel.equipment_group !== null && !addedCircuits.includes(channel.channel_name.trim())) {
        let node = {
          id: channel.channel_name.trim(),
          name: channel.channel_name.trim(),
          parent: "Group: " + channel.equipment_group.trim(),
          selected: false,
          collapsed: false,
          device: channel.device_id.trim(),
          dataType: 'circuit',
          shape: {
            type: "HTML",
            content: channel.channel_name.trim(),
          },
        };
        auxHierarchy.push(node);
        addedCircuits.push(channel.channel_name.trim())
      }
    }
  },

  catTreeGenerator: (auxHierarchy, channels, selectedBuildings) => {
    channels = GraphFuncs.getNonSubmonitored(channels)
    let addedCircuits = []
    let categories = {}
    for (let channel of channels) {
      if (selectedBuildings.length > 0 && !selectedBuildings.includes(channel.channels[0].building_distribution)) continue
      if (channel.channels[0].category !== null && !Object.keys(categories).includes(channel.channels[0].category.trim())) {
        let node = {
          id: channel.channels[0].category.trim(),
          name: channel.channels[0].category.trim(),
          parent: null,
          selected: false,
          collapsed: false,
          dataType: 'category',
          shape: {
            type: "HTML",
            content: channel.channels[0].category.trim(),
          },
        };
        auxHierarchy.push(node);
        categories[channel.channels[0].category.trim()] = []
      }
      let subcat = channel.channels[0].category !== null && (channel.channels[0].sub_category === null ? channel.channels[0].category.trim() : channel.channels[0].sub_category.trim())
      if (channel.channels[0].category !== null && !categories[channel.channels[0].category.trim()].includes(subcat)) {
        let node = {
          id: channel.channels[0].category.trim() + subcat,
          name: subcat,
          parent: channel.channels[0].category.trim(),
          selected: false,
          collapsed: false,
          dataType: 'sub_category',
        }
      auxHierarchy.push(node);
      categories[channel.channels[0].category.trim()].push(subcat)
    }
    if (channel.channels[0].category !== null && !addedCircuits.includes(channel.channels[0].category.trim())) {
      let node = {
        id: channel.circuit_name.trim() + "alsdjfklajsdfkjaherth",
        name: channel.circuit_name.trim(),
        parent: channel.channels[0].category.trim() + subcat,
        selected: false,
        collapsed: false,
        device: channel.channels[0].device_id.trim(),
        dataType: 'circuit',
        shape: {
          type: "HTML",
          content: channel.circuit_name.trim(),
        },
      };
      auxHierarchy.push(node);
      addedCircuits.push(channel.circuit_name.trim())
    }
  }
},

  dTreeGenerator: (auxHierarchy, hierarchy, parent, selectedBuildings, type) => {
    for(let key in hierarchy) {
      if (selectedBuildings.length > 0 && !selectedBuildings.includes(hierarchy[key].channel.building_distribution.trim())) continue
let node = {
  id: hierarchy[key].channel_name.trim(),
  name: hierarchy[key].channel_name.trim(),
  parent: parent === null ? parent : parent.trim(),
  selected: false,
  device: hierarchy[key].channel.device_id.trim(),
  collapsed: false,
  dataType: type,
  shape: {
    type: "HTML",
    content: hierarchy[key].channel_name.trim(),
  },
};
auxHierarchy.push(node);
if (!isEmpty(hierarchy[key].children)) {
  DiagramFuncs.dTreeGenerator(auxHierarchy, hierarchy[key].children, key, selectedBuildings, type);
}
    }
  },


addSources: (auxHierarchy, e_sources, hierarchy) => {
  let main = auxHierarchy.find(jsonObj => jsonObj.parent === null);
  let mainParents = []
  let index = 0
  for (let [ix, source] of e_sources.entries()) {
    if (source.channels && source.channels.length > 0 && source.channels[0].sub_category && source.channels[0].sub_category.trim().toLowerCase() === 'utility') index = ix
    mainParents.push(source.circuit_name)
    let node = {
      id: source.circuit_name,
      name: source.circuit_name,
      parent: null,
      selected: false,
      dataType: "circuit",
      device: source.device_id,
      collapsed: false,
      shape: {
        type: "HTML",
        content: source.circuit_name,
      },
    };
    auxHierarchy.push(node);
  }
  main.parent = e_sources.length > 0 ? e_sources[index].circuit_name : null
}
}
export default DiagramFuncs