import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highcharts";
import { useRef, useEffect, useState } from 'react';
import Loader from "../Loader";
import moment from 'moment';

export default function MaintenanceBarChart(props) {

    const [currentData, setCurrentData] = useState(null)
    const [options, setOptions] = useState(null)
    const chartRef = useRef(null);

    useEffect(() => {
        if (!props.groups) return
        try {
            const currentYear = moment().year();
            const lastYear = moment().subtract(1, 'years').year();
            const yearBeforeLast = moment().subtract(2, 'years').year();

            let data = {
                [currentYear]: {
                    0: { onTime: 0, completed: 0 },
                    1: { onTime: 0, completed: 0 },
                    2: { onTime: 0, completed: 0 },
                    3: { onTime: 0, completed: 0 },
                    4: { onTime: 0, completed: 0 },
                    5: { onTime: 0, completed: 0 },
                    6: { onTime: 0, completed: 0 },
                    7: { onTime: 0, completed: 0 },
                    8: { onTime: 0, completed: 0 },
                    9: { onTime: 0, completed: 0 },
                    10: { onTime: 0, completed: 0 },
                    11: { onTime: 0, completed: 0 }
                },
                [lastYear]: {
                    0: { onTime: 0, completed: 0 },
                    1: { onTime: 0, completed: 0 },
                    2: { onTime: 0, completed: 0 },
                    3: { onTime: 0, completed: 0 },
                    4: { onTime: 0, completed: 0 },
                    5: { onTime: 0, completed: 0 },
                    6: { onTime: 0, completed: 0 },
                    7: { onTime: 0, completed: 0 },
                    8: { onTime: 0, completed: 0 },
                    9: { onTime: 0, completed: 0 },
                    10: { onTime: 0, completed: 0 },
                    11: { onTime: 0, completed: 0 }
                },
                [yearBeforeLast]: {
                    0: { onTime: 0, completed: 0 },
                    1: { onTime: 0, completed: 0 },
                    2: { onTime: 0, completed: 0 },
                    3: { onTime: 0, completed: 0 },
                    4: { onTime: 0, completed: 0 },
                    5: { onTime: 0, completed: 0 },
                    6: { onTime: 0, completed: 0 },
                    7: { onTime: 0, completed: 0 },
                    8: { onTime: 0, completed: 0 },
                    9: { onTime: 0, completed: 0 },
                    10: { onTime: 0, completed: 0 },
                    11: { onTime: 0, completed: 0 }
                }
            }

            for (let timestamp in props.groups.onTime) {
                data[moment(timestamp).year()][moment(timestamp).month()].onTime += props.groups.onTime[timestamp]
            }

            for (let timestamp in props.groups.completed) {
                data[moment(timestamp).year()][moment(timestamp).month()].completed += props.groups.completed[timestamp]
            }

            let finalThisYear = []
            let finalLastYear = []
            let finalYearBeforeLast = []
            let monthDict = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            for (let month in monthDict) {
                finalThisYear.push({
                    name: monthDict[month],
                    y: parseFloat(((data[currentYear][month].onTime / data[currentYear][month].completed) * 100).toFixed(2)),
                    label: `<b>${currentYear}: ${labelMaker(data[currentYear][month].onTime, data[currentYear][month].completed)}</b>
                            <br/><b>${lastYear}: ${labelMaker(data[lastYear][month].onTime, data[lastYear][month].completed)}</b>
                            <br/><b>${yearBeforeLast}: ${labelMaker(data[yearBeforeLast][month].onTime, data[yearBeforeLast][month].completed)}</b>`
                })
                finalLastYear.push({
                    name: monthDict[month],
                    y: parseFloat(((data[lastYear][month].onTime / data[lastYear][month].completed) * 100).toFixed(2)),
                    label: `<b>${currentYear}: ${labelMaker(data[currentYear][month].onTime, data[currentYear][month].completed)}</b>
                            <br/><b>${lastYear}: ${labelMaker(data[lastYear][month].onTime, data[lastYear][month].completed)}</b>
                            <br/><b>${yearBeforeLast}: ${labelMaker(data[yearBeforeLast][month].onTime, data[yearBeforeLast][month].completed)}</b>`
                })
                finalYearBeforeLast.push({
                    name: monthDict[month],
                    y: parseFloat(((data[yearBeforeLast][month].onTime / data[yearBeforeLast][month].completed) * 100).toFixed(2)),
                    label: `<b>${currentYear}: ${labelMaker(data[currentYear][month].onTime, data[currentYear][month].completed)}</b>
                            <br/><b>${lastYear}: ${labelMaker(data[lastYear][month].onTime, data[lastYear][month].completed)}</b>
                            <br/><b>${yearBeforeLast}: ${labelMaker(data[yearBeforeLast][month].onTime, data[yearBeforeLast][month].completed)}</b>`
                })
            }

            setCurrentData([finalThisYear, finalLastYear, finalYearBeforeLast])
        } catch (e) {
            console.log(e)
            if (e.message === 'cancelling') return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const labelMaker = (onTime, completed) => {
        if (!completed) return " - "
        return `${parseFloat(((onTime / completed) * 100).toFixed(1)).toLocaleString()}%, ${onTime} of ${completed} `
    }


    useEffect(() => {
        setOptions({
            chart: {
                type: 'column',
                height: 200
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: null,
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                crosshair: true,
                accessibility: {
                    description: 'Month'
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: '',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -10
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            tooltip: {
                formatter: function () {
                    return this.point.label
                },
            },

            series: [
                {
                    name: moment().subtract(2, 'years').year(),
                    data: currentData && currentData[2],
                    color: "#FFD233"
                },
                {
                    name: moment().subtract(1, 'years').year(),
                    data: currentData && currentData[1],
                    color: "#17AEEE",
                },
                {
                    name: moment().year(),
                    data: currentData && currentData[0],
                    color: "#DD6B20",
                }
            ],
            exporting: { enabled: false }
        })
    }, [currentData])

    return (
        <div className=''>
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className="">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}