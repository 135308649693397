import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';

const Operator = () => {

    const location = useLocation();
    const [operator, setOperator] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [operatorData, setOperatorData] = useState({
        operator_id: "",
        op_name: "",
        op_phone: "",
        op_email: "",
        op_image: "",
    })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_buildings_and_operators_list", "create_buildings_and_operators", "update_buildings_and_operators"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/devices')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/operator/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setOperator(queryParams.get('operator'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (getPutPost === 'get' && operator !== null) {
            setLoading(true)
            const auth = context.getUser.token;
            API.getOperator(cancelToken, auth, operator).then(res => {
                setOperatorData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && 'error' in err.response.data) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, operator])

    const updateOperator = (e) => {
        const { name, value } = e.target;
        if (name === 'op_image') {
            const selectedImage = e.target.files[0];
            if (selectedImage) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    setOperatorData(prevValue => {
                        return {
                            ...prevValue,
                            [name]: base64ImageData
                        }
                    })
                };

                reader.readAsDataURL(selectedImage);
            }
        } else {
            setOperatorData(prevValue => {
                return {
                    ...prevValue,
                    [name]: value.trim()
                }
            })
        }
    }

    const postOperator = async (e) => {
        if (operatorData.op_name === "") {
            setError({ op_name: "Missing Value" })
        } else {
            let payload = JSON.parse(JSON.stringify(operatorData))
            delete payload['created_at'];
            delete payload['updated_at'];
            delete payload['operator_id'];
            if (payload['op_image'] && payload['op_image'].includes('https')) {
                delete payload['op_image'];
            }
            for (let key in payload) {
                if (payload[key] === null || String(payload[key]).trim() === "") {
                    delete payload[key];
                }
            }
            setLoading(true)
            const cancelToken = API.cancelToken();
            const auth = context.getUser.token;
            try {
                if (getPutPost === 'post') {
                    let res = await API.postOperator(cancelToken, auth, payload)
                    navigate(`/operator?operator=${res.data.operator_id}`)
                } else {
                    await API.putOperator(cancelToken, auth, operator, payload)
                    setGetPutPost('get')
                    setError(null)
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if ('error' in err.response.data) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                }
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/operators">
                    Operators
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? operatorData.op_name : "Add Operator"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='flex flex-wrap'>
                    <div className='input-border pb-8 flex flex-wrap w-full justify-between items-center w-full items-center pt-3 pb-3 mb-6'>
                        <div className='items-center mb-6 laptop:w-1/2 tablet:w-full mobile:w-full'>
                            <input
                                placeholder='Operator Name'
                                className='text-3xl p-2 outline-none hide-input-background w-full'
                                type="text"
                                name='op_name'
                                disabled={getPutPost !== 'post'}
                                defaultValue={operatorData.op_name ? operatorData.op_name : ""}
                                onChange={updateOperator} />
                            {
                                error && error.op_name &&
                                <p className='text-red text-xs p-2'>{error.op_name}</p>

                            }
                        </div>
                    </div>
                    {
                        loading &&
                        <Loader />
                    }
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="logo">Image</label>
                            {
                                getPutPost === 'get' &&
                                <img className='w-20' src={operatorData.op_image ? operatorData.op_image : require('../../assets/images/placeholder-image.png')} alt="" />
                            }
                            {getPutPost !== 'get' &&
                                <input
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="file"
                                    name="op_image"
                                    onChange={updateOperator} />
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="op_phone">Phone</label>
                            <input
                                placeholder='Phone'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="op_phone" defaultValue={operatorData.op_phone ? operatorData.op_phone : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateOperator} />
                            {
                                error && error.op_phone &&
                                <p className='text-red text-xs'>{error.op_phone}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="op_email">E-mail</label>
                            <input
                                placeholder='E-mail'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                step="0.1"
                                name="op_email" defaultValue={operatorData.op_email ? operatorData.op_email : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateOperator} />
                            {
                                error && error.op_email &&
                                <p className='text-red text-xs'>{error.op_email}</p>

                            }
                        </div>
                    </div>
                </div>
                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Operator
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && operatorData.op_name !== "" && !loading && <button className='submit-button' onClick={postOperator}>
                            Save
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Operator;