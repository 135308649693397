
import API from '../../utils/API';
import GraphFuncs from '../../utils/GraphFuncs';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import moment from 'moment';

export default function DistribuitionTable(props) {

    const [commonCircuits, setCommonCircuits] = useState({})
    const [kwCircuits, setKwCircuits] = useState(null)
    const [kvaCircuits, setKvaCircuits] = useState([])
    const [kvarCircuits, setKvarCircuits] = useState([])
    const [vllCircuits, setVllCircuits] = useState([])
    const [vlnCircuits, setVlnCircuits] = useState([])
    const [aCircuits, setACircuits] = useState([])
    const [pfCircuits, setPfCircuits] = useState([])
    const [circuitsCompare, setCircuitsCompare] = useState({})
    const [hCircuits, setHCircuits] = useState([])
    const [wCircuits, setWCircuits] = useState([])
    const [gCircuits, setGCircuits] = useState([])
    const [coCircuits, setCoCircuits] = useState([])
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext);

    const getDetail = (diff) => {
        let detail = 1
        if (diff > 1 && diff < 31) detail = 15
        else if (diff > 30) detail = 60
        return detail
    }

    const unitDict = {
        thdv: "THD (V)", othdv: "OTHD (V)", ethdv: "ETHD (V)", thffv: "THFF (V)",
        cfv: "CF (V)", unblv: "Unbl (V)", unbli: "Unbl (I)", thdva: "THD (V)",
        kfi: "KF (I)", ethdi: "ETHD (I)", othdi: "OTHD (I)", thdi: "THD (I)",
        kw: getDetail(GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])) < 60 ? "kW" : 'kWh',
        kva: getDetail(GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])) < 60 ? "kVA" : 'kVAh',
        kvar: getDetail(GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])) < 60 ? "kVAr" : 'kVArh',
        a: getDetail(GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])) < 60 ? "A" : 'Ah',
        vll: "V", vln: "V", co: "T",
        "ft³": "ft³", L: "L", pf: "PF"
    }

    const getLineInfo = async (cancelToken, circuit) => {
        try {
            let tempC = {}
            if (!circuit.series) return null
            let series = Object.values(circuit.series)
            tempC.name = 'channel_name' in circuit ? circuit.channel_name : circuit.circuit_name
            let temptotal = series.reduce((sum, value) => sum + value, 0)
            const auth = context.getUser.token;
            tempC.runtime = circuit.runtime
            tempC.total = '-'
            if (['kw', 'kva', 'kvar', 'a'].includes(circuit.unit)) {
                let sum = await API.getSum(cancelToken, auth, circuit.startDate, circuit.endDate, circuit.device_id, circuit.unit.toLowerCase(), circuit.circuit_name)
                tempC.total = parseFloat(sum.data.toFixed(2)).toLocaleString() + ` ${unitDict[circuit.unit]}`
            }
            if (['L', 'ft³', 'co'].includes(circuit.unit)) {
                tempC.total = series.reduce((sum, value) => sum + value, 0).toFixed(2) + ` ${unitDict[circuit.unit]}`
            }
            let price = 0
            if (circuit.type === 'water') {
                price = await API.getPriceW(cancelToken, auth, circuit.startDate, circuit.endDate, circuit.channel.channel_name, circuit.device_id)
                tempC.price = parseFloat(price.data.total.toFixed(2)).toLocaleString()
            } else if (circuit.type === 'gas') {
                price = await API.getPriceG(cancelToken, auth, circuit.startDate, circuit.endDate, circuit.channel.channel_name, circuit.device_id)
                tempC.price = parseFloat(price.data.total.toFixed(2)).toLocaleString()
            }
            tempC.high = series.length > 0 ? parseFloat(Math.max(...series).toFixed(2)).toLocaleString() + ` ${unitDict[circuit.unit]}` : 0 + ` ${unitDict[circuit.unit]}`
            tempC.unit = unitDict[circuit.unit]
            tempC.low = series.length > 0 ? parseFloat(Math.min(...series).toFixed(2)).toLocaleString() + ` ${unitDict[circuit.unit]}` : 0 + ` ${unitDict[circuit.unit]}`
            tempC.average = series.length > 0 ? parseFloat((temptotal / series.length).toFixed(2)).toLocaleString() + ` ${unitDict[circuit.unit]}` : 0 + `${unitDict[circuit.unit]}`
            return tempC
        } catch (e) {
            if (e.message === 'cancelling') return
            console.log(e)
        }
    }

    const getCommonValues = async (cancelToken, circuit) => {
        try {
            const auth = context.getUser.token;
            let price = 0
            price = await API.getPriceE(cancelToken, auth, circuit.startDate, circuit.endDate, circuit.circuit_name, circuit.device_id)
            price = price.data.total
            circuit.price = price.toFixed(2)
            circuit.runtime = "N/A"
            if (!circuit.channels[0].virtual) {
                let running = await API.getCircuitRuntime(cancelToken, auth, circuit.startDate, circuit.endDate, circuit.device_id, circuit.circuit_name)
                let active = Object.values(running.data.series).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                circuit.runtime = active.toFixed(2) + " h"
            }

            let peak = await API.getElectricity(cancelToken, auth, circuit.startDate, circuit.endDate, circuit.device_id, 15, 'a', circuit.circuit_name)
            circuit.breaker = '-'
            if (circuit.channels[0].breaker_amps) {
                circuit.breaker = (((Math.max(...Object.values(peak.data.series)).toFixed(2) / 3) / circuit.channels[0].breaker_amps) * 100).toFixed(2) + "%"
            }
            setCommonCircuits(prev => ({ ...prev, [circuit.circuit_name]: circuit }))
        } catch (e) {
            console.log(e)
            if (e.message === 'cancelling') return
        }
    }

    useEffect(() => {
        const cancelToken = API.cancelToken();
        let tempkvaCircuits = []
        let tempkvarCircuits = []
        let tmpkwCircuits = []
        let tempvllCircuits = []
        let tempvlnCircuits = []
        let tempaCircuits = []
        let temppfCircuits = []
        let temphCircuits = []
        let tempwCircuits = []
        let tempgCircuits = []
        let tempcoCircuits = []
        let tempCircuitCompare = {}
        setLoading(true)
        const getLines = async () => {
            for (let circuitKey in props.circuits) {
                let circuit = props.circuits[circuitKey]
                if (!circuit || circuit.channel_name.includes('Cap Bank')) continue
                if (!circuit.compare) {
                    if (['electricity', 'harmonics'].includes(circuit.type)) {
                        if ((!(circuit.circuit_name in commonCircuits) ||
                            circuit.startDate !== commonCircuits[circuit.circuit_name].startDate ||
                            circuit.endDate !== commonCircuits[circuit.circuit_name].endDate)) {
                            getCommonValues(cancelToken, circuit)
                        }
                        if (circuit.unit === 'kw') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tmpkwCircuits.push(tempC)
                        } else if (circuit.unit === 'kva') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempkvaCircuits.push(tempC)
                        } else if (circuit.unit === 'kvar') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempkvarCircuits.push(tempC)
                        } else if (circuit.unit === 'a') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempaCircuits.push(tempC)
                        } else if (circuit.unit === 'vll') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempvllCircuits.push(tempC)
                        } else if (circuit.unit === 'vln') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempvlnCircuits.push(tempC)
                        } else if (circuit.unit === 'pf') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            temppfCircuits.push(tempC)
                        } else if (circuit.type === 'harmonics') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            if (!tempC) continue
                            temphCircuits.push(tempC)
                        } else if (circuit.unit === 'co') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempcoCircuits.push(tempC)
                        }
                    } else {
                        if (circuit.type === 'gas') {
                            if (circuit.unit === 'co') {
                                let tempC = await getLineInfo(cancelToken, circuit)
                                tempcoCircuits.push(tempC)
                            } else {
                                let tempC = await getLineInfo(cancelToken, circuit)
                                tempgCircuits.push(tempC)
                            }
                        } else if (circuit.type === 'water') {
                            let tempC = await getLineInfo(cancelToken, circuit)
                            tempwCircuits.push(tempC)
                        }
                    }
                } else {
                    if (circuit.unit === 'kw') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        tempCircuitCompare[circuit.circuit_name] = tempCircuitCompare[circuit.circuit_name] || {}
                        tempCircuitCompare[circuit.circuit_name].kw = tempC
                    } else if (circuit.unit === 'kva') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        tempCircuitCompare[circuit.circuit_name] = tempCircuitCompare[circuit.circuit_name] || {}
                        tempCircuitCompare[circuit.circuit_name].kva = tempC
                    } else if (circuit.unit === 'kvar') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        tempCircuitCompare[circuit.circuit_name] = tempCircuitCompare[circuit.circuit_name] || {}
                        tempCircuitCompare[circuit.circuit_name].kvar = tempC
                    } else if (circuit.unit === 'a') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        tempCircuitCompare[circuit.circuit_name] = tempCircuitCompare[circuit.circuit_name] || {}
                        tempCircuitCompare[circuit.circuit_name].a = tempC
                    } else if (circuit.unit === 'co') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        let name = 'channel_name' in circuit ? circuit.channel_name : circuit.circuit_name
                        tempCircuitCompare[name] = tempCircuitCompare[circuit.circuit_name] || {}
                        tempCircuitCompare[name].co = tempC
                    } else if (circuit.type === 'gas') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        tempCircuitCompare[circuit.channel_name] = tempCircuitCompare[circuit.channel_name] || {}
                        tempCircuitCompare[circuit.channel_name].gas = tempC
                    } else if (circuit.type === 'water') {
                        let tempC = await getLineInfo(cancelToken, circuit)
                        tempCircuitCompare[circuit.channel_name] = tempCircuitCompare[circuit.channel_name] || {}
                        tempCircuitCompare[circuit.channel_name].water = tempC
                    }
                }
            }
            setKwCircuits(tmpkwCircuits)
            setKvaCircuits(tempkvaCircuits)
            setKvarCircuits(tempkvarCircuits)
            setACircuits(tempaCircuits)
            setVllCircuits(tempvllCircuits)
            setVlnCircuits(tempvlnCircuits)
            setPfCircuits(temppfCircuits)
            setHCircuits(temphCircuits)
            setGCircuits(tempgCircuits)
            setWCircuits(tempwCircuits)
            setCoCircuits(tempcoCircuits)
            setCircuitsCompare(tempCircuitCompare)
            setLoading(false)
        }
        getLines()
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.circuits])


    const getDigitalSum = (circuits, type, measure) => {
        let total = 0
        for (let circuit of circuits) {
            if (!circuit) continue
            if (type === 'gas') {
                if (circuit.unit === 'ft³') {
                    total += (parseFloat(circuit[measure].replace(',', '')) / 35.3147)
                } else if (circuit.unit === 'L') {
                    total += (parseFloat(circuit[measure].replace(',', '')) / 1000)
                }
            } else if (type === 'water') {
                if (circuit.unit === 'ft³') {
                    total += (parseFloat(circuit[measure].replace(',', '')) * 28.3168)
                } else if (circuit.unit === 'L') {
                    total += (parseFloat(circuit[measure].replace(',', '')))
                }
            }
        }
        return parseFloat(total.toFixed(2)).toLocaleString()
    }

    const getSumCompareDigital = (circuits, type) => {
        let total = 0
        for (let circuit of circuits) {
            if (!circuit || !circuitsCompare || !circuitsCompare[circuit.name] || !circuitsCompare[circuit.name][type]) continue
            if (type === 'gas') {
                if (circuit.unit === 'ft³') {
                    total += (parseFloat(circuitsCompare[circuit.name][type].total.replace(',', '')) / 35.3147)
                } else if (circuit.unit === 'L') {
                    total += (parseFloat(circuitsCompare[circuit.name][type].total.replace(',', '')) / 1000)
                }
            } else if (type === 'water') {
                if (circuit.unit === 'ft³') {
                    total += (parseFloat(circuitsCompare[circuit.name][type].total.replace(',', '')) * 28.3168)
                } else if (circuit.unit === 'L') {
                    total += (parseFloat(circuitsCompare[circuit.name][type].total.replace(',', '')))
                }
            }
        }
        return parseFloat(total.toFixed(2)).toLocaleString()
    }

    const getCompareDiffDigital = (circuits, type) => {
        let sumCompare = getSumCompareDigital(circuits, type)
        let total = getDigitalSum(circuits, type, 'total')

        let reduced = 0
        if (total !== 0) {
            reduced = 100 * (sumCompare - total) / total
        }
        try {
            let returnVal = parseFloat(reduced.replace(',', '')).toFixed(2)
            return parseFloat(returnVal).toLocaleString()
        } catch (e) {
            console.log('Filed to get compare diff')
            console.log(e)
            console.log(reduced)
            return 0
        }
    }

    const getReduced = (circuits, attr) => {
        let tempCircuit = JSON.parse(JSON.stringify(circuits))
        let reduced = 0
        if (tempCircuit.length > 0) {
            tempCircuit.forEach(e =>
                reduced += (e ? parseFloat(e[attr].replaceAll(',', '')) : 0), 0)
        }
        try {
            let returnVal = parseFloat(reduced).toFixed(2)
            return parseFloat(returnVal).toLocaleString()
        } catch (e) {
            console.log('Failed to get total')
            console.log(e)
            console.log(reduced)
            return 0
        }
    }

    const getSumCompare = (circuits, attr) => {
        let tempCircuit = JSON.parse(JSON.stringify(circuits))
        let reduced = 0
        if (tempCircuit.length > 0) {
            tempCircuit.forEach(e => {
                reduced += (circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name][attr] ? parseFloat(circuitsCompare[e.name][attr].total.replace(',', '')) : 0)
            })
        }
        try {
            let returnVal = parseFloat(reduced).toFixed(2)
            return parseFloat(returnVal).toLocaleString()
        } catch (e) {
            console.log('Failed to get compare sum')
            console.log(e)
            console.log(reduced)
            return 0
        }

    }

    const getCompareDiff = (circuits, attr) => {
        let sumCompare = getSumCompare(circuits, attr)
        let total = getReduced(circuits, 'total')

        let reduced = 0
        if (total !== 0) {
            reduced = 100 * (parseFloat(total.replace(",", '')) - parseFloat(sumCompare.replace(",", ''))) / parseFloat(sumCompare.replace(",", ''))
        }
        try {
            let returnVal = parseFloat(reduced).toFixed(2)
            return parseFloat(returnVal).toLocaleString()
        } catch (e) {
            console.log('Failed to get compare diff')
            console.log(e)
            console.log(reduced)
            return 0
        }
    }

    const getTotalAverage = (circuits, attr) => {
        let tempCircuit = JSON.parse(JSON.stringify(circuits))
        let reduced = 0

        let average = getReduced(tempCircuit, 'average')
        if (tempCircuit.length > 0 && average > 0) {
            tempCircuit.forEach(e => {
                reduced += (e ? (parseFloat(e.average.replace(',', '')) * parseFloat(e.average.replace(',', '')) / parseFloat(average.replace(',', ''))) : 0)
            })
        }
        try {
            let returnVal = parseFloat(reduced).toFixed(2)
            return parseFloat(returnVal).toLocaleString()
        } catch (e) {
            console.log('Failed to get total average')
            console.log(e)
            console.log(reduced)
            return 0
        }
    }

    const getCommon = (circuits, attr) => {
        let tempCircuit = JSON.parse(JSON.stringify(circuits))
        let reduced = 0
        if (tempCircuit.length > 0 && commonCircuits) {
            tempCircuit.forEach(e => {
                reduced += (e && e.name in commonCircuits && !isNaN(parseFloat(commonCircuits[e.name][attr].replace(',', ''))) ? parseFloat(commonCircuits[e.name][attr].replace(',', '')) : 0)
            })

        }
        try {
            let returnVal = parseFloat(reduced).toFixed(2)
            return parseFloat(returnVal).toLocaleString()
        } catch (e) {
            console.log('Failed to get common')
            console.log(e)
            console.log(reduced)
            return 0
        }
    }

    const sortOptions = (a, b) => {
        var titleA = parseInt(a.total)
        var titleB = parseInt(b.total)
        if (titleA < titleB) {
            return 1;
        }
        if (titleA > titleB) {
            return -1;
        }
        return 0;
    }

    return (
        <div className={`card tablet:p-8 mobile:p-2 mt-8 rounded-lg relative w-full`}>
            <div className='flex flex-nowrap justify-between items-center'>
                <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41] mb-2">Historical Usage</h2>
                {loading && <Loader />}
            </div>
            <p className='mb-4 font-light text-sm'>Date Range: <span className='font-normal'>{moment(props.startEnd[0]).format("MMM DD, YYYY")} - {moment(props.startEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])} days)</span></p>
            {props.compare && props.compare.length === 2 && <p className='mb-4 font-light text-sm'>Comparative Range: <span className='font-normal'>{moment(props.compare[0]).format("MMM DD, YYYY")} - {moment(props.compare[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.compare[0], props.compare[1])} days)</span></p>}
            <div className='w-full overflow-x-auto'>
                <div className='flex flex-nowrap w-content justify-center items-center rounded bg-[#619E7B] text-white p-4 text-sm min-w-[700px]'>
                    <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                        <p className='w-1/4 text-center'>Circuits</p>
                        {!props.compare && <p className='w-1/4 text-center'>Total</p>}
                        {props.compare && <p className='w-1/4 text-center'>Selected Dates</p>}
                        {props.compare && <p className='w-1/4 text-center'>Comparative Dates</p>}
                        {props.compare && <p className='w-1/4 text-center'>Difference</p>}
                        {!props.compare && <p className='w-1/4 text-center'>Highest</p>}
                        {!props.compare && <p className='w-1/4 text-center'>Lowest</p>}
                    </div>
                    {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                        <p className='w-1/4 text-center'>Average</p>
                        <p className='w-1/4 text-center'>Breaker Capacity</p>
                        <p className='w-1/4 text-center'>Run time Hour</p>
                        <p className='w-1/4 text-center'>Cost</p>
                    </div>}
                </div>
                {
                    kwCircuits && kwCircuits.length > 0 && kwCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].kw &&
                                    <p className='w-1/4 text-center'>{circuitsCompare[e.name].kw.total}</p>}
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].kw &&
                                    <p className='w-1/4 text-center'>{(100 * (parseFloat(e.total.replace(",", "")) - parseFloat(circuitsCompare[e.name].kw.total.replace(",", ""))) / parseFloat(circuitsCompare[e.name].kw.total.replace(",", ""))).toFixed(2)}%</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    kwCircuits && kwCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kwCircuits, 'total')} kWh</p>
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getSumCompare(kwCircuits, 'kw')} kWh</p>}
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getCompareDiff(kwCircuits, 'kw')} %</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kwCircuits, 'high')} {unitDict.kw}</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kwCircuits, 'low')} {unitDict.kw}</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{getTotalAverage(kwCircuits, 'kw')} {unitDict.kw}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getCommon(kwCircuits, 'runtime')} h</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${getCommon(kwCircuits, 'price')}</p>
                        </div>}
                    </div>
                }
                {
                    kvaCircuits && kvaCircuits.length > 0 && kvaCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].kva &&
                                    <p className='w-1/4 text-center'>{circuitsCompare[e.name].kva.total}</p>}
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].kva &&
                                    <p className='w-1/4 text-center'>{(100 * (parseFloat(e.total.replace(",", "")) - parseFloat(circuitsCompare[e.name].kva.total.replace(",", ""))) / parseFloat(circuitsCompare[e.name].kva.total.replace(",", ""))).toFixed(2)}%</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    kvaCircuits && kvaCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kvaCircuits, 'total')} kVAh</p>
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getSumCompare(kvaCircuits, 'kva')} kVAh</p>}
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getCompareDiff(kvaCircuits, 'kva')} %</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kvaCircuits, 'high')} {unitDict.kva}</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kvaCircuits, 'low')} {unitDict.kva}</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{getTotalAverage(kvaCircuits, 'kva')} {unitDict.kva}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getCommon(kvaCircuits, 'runtime')} h</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${getCommon(kvaCircuits, 'price')}</p>
                        </div>}
                    </div>
                }
                {
                    kvarCircuits && kvarCircuits.length > 0 && kvarCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].kvar &&
                                    <p className='w-1/4 text-center'>{circuitsCompare[e.name].kvar.total}</p>}
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].kvar &&
                                    <p className='w-1/4 text-center'>{(100 * (parseFloat(e.total.replace(",", "")) - parseFloat(circuitsCompare[e.name].kvar.total.replace(",", ""))) / parseFloat(circuitsCompare[e.name].kvar.total.replace(",", ""))).toFixed(2)}%</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    kvarCircuits && kvarCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kvarCircuits, 'total')} kVArh</p>
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getSumCompare(kvarCircuits, 'kvar')} kVArh</p>}
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getCompareDiff(kvarCircuits, 'kvar')} %</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kvarCircuits, 'high')} {unitDict.kvar}</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(kvarCircuits, 'low')} {unitDict.kvar}</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{getTotalAverage(kvarCircuits, 'kvar')} {unitDict.kvar}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getCommon(kvarCircuits, 'runtime')} h</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${getCommon(kvarCircuits, 'price')}</p>
                        </div>}
                    </div>
                }
                {
                    aCircuits && aCircuits.length > 0 && aCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].a &&
                                    <p className='w-1/4 text-center'>{circuitsCompare[e.name].a.total}</p>}
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].a &&
                                    <p className='w-1/4 text-center'>{(100 * (parseFloat(e.total.replace(",", "")) - parseFloat(circuitsCompare[e.name].a.total.replace(",", ""))) / parseFloat(circuitsCompare[e.name].a.total.replace(",", ""))).toFixed(2)}%</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    aCircuits && aCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getReduced(aCircuits, 'total')} Ah</p>
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getSumCompare(aCircuits, 'a')} Ah</p>}
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getCompareDiff(aCircuits, 'a')} %</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(aCircuits, 'high')} {unitDict.a}</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(aCircuits, 'low')} {unitDict.a}</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{getTotalAverage(aCircuits, 'a')} {unitDict.a}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getCommon(aCircuits, 'runtime')} h</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${getCommon(aCircuits, 'price')}</p>
                        </div>}
                    </div>
                }
                {
                    !props.compare && vllCircuits && vllCircuits.length > 0 && vllCircuits.map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>
                            </div>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>
                        </div>
                    ))
                }
                {
                    !props.compare && vllCircuits && vllCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                        </div>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{vllCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")) * (parseFloat(value.average.replace(",", "")) / vllCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")), 0)), 0).toFixed(2) + ' V'}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{commonCircuits && vllCircuits.reduce((sum, value) => sum + (value.name in commonCircuits ? parseFloat(commonCircuits[value.name].runtime.replace(",", "")) : 0), 0).toFixed(2) + ' h'}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${commonCircuits && vllCircuits.reduce((sum, value) => sum + (value.name in commonCircuits ? parseFloat(commonCircuits[value.name].price.replace(",", "")) : 0), 0).toFixed(2)}</p>
                        </div>
                    </div>
                }
                {
                    !props.compare && vlnCircuits && vlnCircuits.length > 0 && vlnCircuits.map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>
                            </div>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>
                        </div>
                    ))
                }
                {
                    !props.compare && vlnCircuits && vlnCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                        </div>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{vlnCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")) * (parseFloat(value.average.replace(",", "")) / vlnCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")), 0)), 0).toFixed(2) + ' V'}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{commonCircuits && vlnCircuits.reduce((sum, value) => sum + (value.name in commonCircuits ? parseFloat(commonCircuits[value.name].runtime.replace(",", "")) : 0), 0).toFixed(2) + ' h'}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${commonCircuits && vlnCircuits.reduce((sum, value) => sum + (value.name in commonCircuits ? parseFloat(commonCircuits[value.name].price.replace(",", "")) : 0), 0).toFixed(2)}</p>
                        </div>
                    </div>
                }
                {
                    !props.compare && pfCircuits && pfCircuits.length > 0 && pfCircuits.map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>
                            </div>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>
                        </div>
                    ))
                }
                {
                    !props.compare && pfCircuits && pfCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                        </div>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{pfCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")) * (parseFloat(value.average.replace(",", "")) / pfCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")), 0)), 0).toFixed(2) + ''}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{commonCircuits && pfCircuits.reduce((sum, value) => sum + (value.name in commonCircuits ? parseFloat(commonCircuits[value.name].runtime.replace(",", "")) : 0), 0).toFixed(2) + ' h'}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${commonCircuits && pfCircuits.reduce((sum, value) => sum + (value.name in commonCircuits ? parseFloat(commonCircuits[value.name].price.replace(",", "")) : 0), 0).toFixed(2)}</p>
                        </div>
                    </div>
                }
                {
                    !props.compare && hCircuits && hCircuits.length > 0 && hCircuits.map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.high} {e && e.unit ? unitDict[e.unit] : ""}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.low} {e && e.unit ? unitDict[e.unit] : ""}</p>
                            </div>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average} {e && e.unit ? unitDict[e.unit] : ""}</p>
                                <p className={`w-1/4 text-center ${e && e.name in commonCircuits && parseInt(commonCircuits[e.name].breaker.split(".")[0]) > 80 ? 'text-[red]' : 'text-[#332D41]'}`}>{e && e.name in commonCircuits && commonCircuits[e.name].breaker}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.name in commonCircuits && commonCircuits[e.name].runtime}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.name in commonCircuits && parseFloat(commonCircuits[e.name].price).toLocaleString()}</p>
                            </div>
                        </div>
                    ))
                }
                {
                    !props.compare && hCircuits && hCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                        </div>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{commonCircuits && hCircuits.reduce((sum, value) => sum + (value && value.name in commonCircuits ? parseFloat(commonCircuits[value.name].runtime.replace(",", "")) : 0), 0).toFixed(2) + ' h'}</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${commonCircuits && hCircuits.reduce((sum, value) => sum + (value && value.name in commonCircuits ? parseFloat(commonCircuits[value.name].price.replace(",", "")) : 0), 0).toFixed(2)}</p>
                        </div>
                    </div>
                }
                {
                    gCircuits && gCircuits.length > 0 && gCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].gas &&
                                    <p className='w-1/4 text-center'>{circuitsCompare[e.name].gas.total}</p>}
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].gas &&
                                    <p className='w-1/4 text-center'>{(100 * (parseFloat(e.total.replace(",", "")) - parseFloat(circuitsCompare[e.name].gas.total.replace(",", ""))) / parseFloat(circuitsCompare[e.name].gas.total.replace(",", ""))).toFixed(2)}%</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.price}</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    gCircuits && gCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total (Natural Gas)</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(gCircuits, 'gas', 'total')} m³</p>
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getSumCompareDigital(gCircuits, 'gas')} m³</p>}
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getCompareDiffDigital(gCircuits, 'gas')} %</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(gCircuits, 'gas', 'high')} m³</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(gCircuits, 'gas', 'low')} m³</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(gCircuits, 'gas', 'average')} m³</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${gCircuits.reduce((sum, value) => sum + parseFloat(value.price.replace(",", "")), 0).toFixed(2)}</p>
                        </div>}
                    </div>
                }
                {
                    wCircuits && wCircuits.length > 0 && wCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].water &&
                                    <p className='w-1/4 text-center'>{circuitsCompare[e.name].water.total}</p>}
                                {props.compare && circuitsCompare && circuitsCompare[e.name] && circuitsCompare[e.name].water &&
                                    <p className='w-1/4 text-center'>{(100 * (parseFloat(e.total.replace(",", "")) - parseFloat(circuitsCompare[e.name].water.total.replace(",", ""))) / parseFloat(circuitsCompare[e.name].water.total.replace(",", ""))).toFixed(2)}%</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>${e && e.price}</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    wCircuits && wCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total (Water)</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(wCircuits, 'water', 'total')} L</p>
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getSumCompareDigital(wCircuits, 'water')} L</p>}
                            {props.compare && circuitsCompare &&
                                <p className='w-1/4 text-center'>{getCompareDiffDigital(wCircuits, 'water')} %</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(wCircuits, 'water', 'high')} L</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(wCircuits, 'water', 'low')} L</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{getDigitalSum(wCircuits, 'water', 'average')} L</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>${wCircuits.reduce((sum, value) => sum + parseFloat(value.price.replace(",", "")), 0).toFixed(2)}</p>
                        </div>}
                    </div>
                }
                {
                    coCircuits && coCircuits.length > 0 && coCircuits.sort((a, b) => sortOptions(a, b)).map((e, idx) => (
                        <div key={idx + "tableitems"} className='flex flex-nowrap w-content justify-center items-center p-4 text-sm min-w-[700px]'>
                            <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center font-medium text-[#385B46]'>{e && e.name}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.total}</p>
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.high}</p>}
                                {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{e && e.low}</p>}
                            </div>
                            {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                                <p className='w-1/4 text-center text-[#332D41]'>{e && e.average}</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                                <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            </div>}
                        </div>
                    ))
                }
                {
                    coCircuits && coCircuits.length > 0 &&
                    <div className='flex flex-nowrap w-content justify-center items-center p-4 bg-[#EDEDED] rounded text-sm min-w-[700px]'>
                        <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center font-medium text-[#385B46]'>Total (CO₂)</p>
                            <p className='w-1/4 text-center text-[#332D41]'>{getReduced(coCircuits, 'total')} T</p>
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(coCircuits, 'high')} T</p>}
                            {!props.compare && <p className='w-1/4 text-center text-[#332D41]'>{getReduced(coCircuits, 'low')} T</p>}
                        </div>
                        {!props.compare && <div className={`flex flex-nowrap w-content justify-between items-center ${props.compare ? 'w-full' : 'w-1/2'} font-medium`}>
                            <p className='w-1/4 text-center text-[#332D41]'>{coCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")) * (parseFloat(value.average.replace(",", "")) / coCircuits.reduce((sum, value) => sum + parseFloat(value.average.replace(",", "")), 0)), 0).toFixed(2)} T</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                            <p className='w-1/4 text-center text-[#332D41]'>-</p>
                        </div>}
                    </div>
                }
            </div>
        </div>
    )
}