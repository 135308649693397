import API from './API';
import moment from 'moment';

const GraphFuncs = {

    isEmpty: (obj) => {
        if (obj == null) return true;
        return Object.keys(obj).length === 0;
    },

    formatSeriesByTimestamp: (data, channel, vpf = false) => {
        var serieArray = {}
        if (!GraphFuncs.isEmpty(data)) {
            for (let obj of data) {
                if (!GraphFuncs.isEmpty(obj)) {
                    if (vpf !== false) {
                        let sumA = 0
                        let sumPf = 0
                        for (let channel of vpf) {
                            sumA += parseFloat(obj[`channel_${channel.channel}_a`])
                        }
                        for (let channel of vpf) {
                            let tempSum = sumA ? (parseFloat(obj[`channel_${channel.channel}_a`]) * parseFloat(obj[`channel_${channel.channel}_pf`])) / sumA : 1
                            sumPf += tempSum
                        }
                        serieArray[obj["timestamp"]] = { amp: sumA, pf: sumPf }
                    } else {
                        serieArray[obj["timestamp"]] = parseFloat(obj[channel])
                    }
                }
            }
        }
        return serieArray
    },
    formatSeriesByTimestampD: (data) => {
        var serieArray = {}
        if (!GraphFuncs.isEmpty(data)) {
            for (let obj of data) {
                if (!GraphFuncs.isEmpty(obj)) {
                    serieArray[obj["timestamp"]] = parseFloat(obj.value)
                }
            }
        }
        return serieArray
    },
    getDifferenceInDays: (date1, date2) => {
        if (!date1 || !date2) return 0
        return moment(date2).diff(moment(date1), 'days') + 1
    },
    getDifferenceInMonths: (date1, date2) => {
        if (!date1 || !date2) return 0
        const start = moment(date1);
        const lastDay = moment(date2);

        let numMonths = (lastDay.year() - start.year()) * 12 + (lastDay.month() - start.month());

        if (lastDay.month() > start.month()) {
            numMonths += 1;
        }        

        return numMonths
    },
    getMeasuredFromVirtual: (circuitName, hierarchy) => {
        let endChannels = []
        let circuit = GraphFuncs.findCirctuitInHierarchy(circuitName, hierarchy)

        if (Object.keys(circuit.children).length !== 0) {
            endChannels = GraphFuncs.getAllMeasuredChildren(circuit)
        } else {
            let parent = GraphFuncs.findCirctuitInHierarchy(circuit.channels[0].channel_subpanel, hierarchy)
            parent.parent = true;
            endChannels.push(parent)
            let siblings = GraphFuncs.getAllMeasuredChildren(parent, hierarchy)
            endChannels = endChannels.concat(siblings)
        }
        return endChannels
    },
    getMeasuredFromVirtualD: (circuitName, hierarchy) => {
        let endChannels = []
        let circuit = GraphFuncs.findCirctuitInHierarchyD(circuitName, hierarchy)
        if (Object.keys(circuit.children).length !== 0) {
            endChannels = GraphFuncs.getAllMeasuredChildrenD(circuit)
        } else {
            let parent = GraphFuncs.findCirctuitInHierarchyD(circuit.channel.channel_parent, hierarchy)
            parent.parent = true;
            endChannels.push(parent)
            let siblings = GraphFuncs.getAllMeasuredChildrenD(parent)
            endChannels = endChannels.concat(siblings)
        }
        return endChannels
    },
    findCirctuitInHierarchy: (circuit, hierarchy) => {
        for (const key in hierarchy) {
            if (key === circuit || (circuit === 'Main Distribution' && hierarchy[key].channels[0].category === "Building Distribution")) {
                return JSON.parse(JSON.stringify(hierarchy[key]));
            } else if (Object.keys(hierarchy[key].children).length !== 0) {
                // If the value is another object (nested JSON), recursively call the function
                const nestedValue = GraphFuncs.findCirctuitInHierarchy(circuit, hierarchy[key].children);
                if (nestedValue !== undefined) {
                    return nestedValue; // If the key is found in the nested JSON, return its value
                }
            }
        }
    },
    findCirctuitInHierarchyD: (circuit, hierarchy) => {
        for (const key in hierarchy) {
            if (key.trim() === circuit.trim() || (circuit === 'Main Distribution' && hierarchy[key].channel.category === "Building Level")) {
                return JSON.parse(JSON.stringify(hierarchy[key]));
            } else if (Object.keys(hierarchy[key].children).length !== 0) {
                // If the value is another object (nested JSON), recursively call the function
                const nestedValue = GraphFuncs.findCirctuitInHierarchyD(circuit, hierarchy[key].children);
                if (nestedValue !== undefined) {
                    return nestedValue; // If the key is found in the nested JSON, return its value
                }
            }
        }
    },
    getAllMeasuredChildren: (circuit) => {
        let channelsList = []
        for (const key in circuit.children) {
            if (!circuit.children[key].channels[0].virtual) channelsList.push(JSON.parse(JSON.stringify(circuit.children[key])));
            else channelsList.push(...GraphFuncs.getAllMeasuredChildren(circuit.children[key]))
        }
        return channelsList
    },
    getAllMeasuredChildrenD: (circuit) => {
        let channelsList = []
        for (const key in circuit.children) {
            if (!circuit.children[key].channel.virtual) channelsList.push(JSON.parse(JSON.stringify(circuit.children[key])));
            else channelsList.push(...GraphFuncs.getAllMeasuredChildrenD(circuit.children[key]))
        }
        return channelsList
    },
    getNonSubmonitored: (channels) => {
        let circuits = {}
        for (let channel of channels) {
            if (!channel.sub_monitored || channel.sub_monitored.toLowerCase() !== "no") continue
            if (channel.channel_name in circuits) {
                circuits[channel.channel_name].channels.push({ ...channel })
            } else {
                circuits[channel.channel_name] = {
                    circuit_name: channel.channel_name,
                    channels: [JSON.parse(JSON.stringify(channel))],
                    device_id: channel.device_id,
                    virtual: channel.virtual,
                }
            }
        }
        return Object.values(circuits)
    },
    getNonSubmonitoredD: (channels) => {
        let parents = channels.map(e => e.channel_parent)
        let circuits = []
        for (let channel of channels) {
            if (parents.includes(channel.channel_name)) continue
            circuits.push({
                channel_name: channel.channel_name,
                channel: JSON.parse(JSON.stringify(channel)),
                device_id: channel.device_id,
                virtual: channel.virtual,
            })
        }
        return circuits
    },
    getPeakKva: async (cancelToken, auth, circuit, start, end) => {
        circuit = JSON.parse(JSON.stringify(circuit));
        let res = await API.getElectricity(cancelToken, auth, start, end, circuit.device_id, 15, 'kva', circuit.circuit_name)
        let maxKey = null;
        let maxValue = 0;
        let data = res.data.series
        for (const key in data) {
            if (data[key] > maxValue) {
                maxKey = key;
                maxValue = data[key];
            }
        }
        return { [maxKey]: maxValue }
    },
    getPeakKvaBusinessHours: async (cancelToken, auth, circuit, start, end, businessHours) => {
        let businessHoursNoMinutes = businessHours.map(e => e.split(":")[0])
        circuit = JSON.parse(JSON.stringify(circuit));
        let res = await API.getElectricity(cancelToken, auth, start, end, circuit.device_id, 15, 'kva', circuit.circuit_name)
        let maxOnKey = null;
        let maxOnValue = 0;
        let maxOffKey = null;
        let maxOffValue = 0;
        let data = res.data.series

        for (const key in data) {
            if (businessHoursNoMinutes.includes(key.split(":")[0])) {
                if (data[key] > maxOnValue) {
                    maxOnKey = key;
                    maxOnValue = data[key];
                }
            } else {
                if (data[key] > maxOffValue) {
                    maxOffKey = key;
                    maxOffValue = data[key];
                }
            }
        }
        return {
            on: { [maxOnKey]: maxOnValue },
            off: { [maxOffKey]: maxOffValue }
        }
    }
}

export default GraphFuncs;



