import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import PublicArcGauge from '../../components/PublicArcGauge';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import moment from 'moment-timezone';
import './style.css'

const PublicGenerationHistory = () => {

    require('highcharts/modules/exporting')(Highcharts);
    highchartsExporting(Highcharts);
    highchartsExportData(Highcharts);

    const location = useLocation();
    const [amount, setAmount] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const [flicker, setFlicker] = useState(false)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [options, setOptions] = useState({
        chart: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            type: "line",
            zoomType: null,
            panning: false,
            panKey: null,
            height: 600
        },
        credits: {
            enabled: false,
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                    ]
                },
            },
            sourceWidth: 1500,
            sourceHeight: 600,
            scale: 1,
            enabled: false,
        },
        navigation: {
            buttonOptions: {
                align: 'right',
                verticalAlign: 'bottom',
                y: 0
            }
        },
        navigator: {
            enabled: false
        },
        tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderWidth: 1,
            borderColor: '#ccc',
            formatter: function (data) {
                let tooltipContent = '<div style="border-bottom: 1px solid #ccc;margin-bottom: 5px; padding-bottom: 5px;font-size:12px">Time Stamp: <b>' + Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) + '</b></div>';

                // Loop through each series in the hovered category
                this.points.forEach(function (point) {
                    let tempName = point.series.name.split(" in ")[0]
                    let tempUnit = point.series.name.split(" in ")[1] || ""
                    tooltipContent += '<div style="color:' + point.color + ';font-size: 12px; padding-bottom: 5px">' + tempName + ': <b>' + point.y.toLocaleString() + " " + tempUnit + '</b></div>';
                });

                return tooltipContent;
            },
            positioner: function (boxWidth, boxHeight, point) {
                return { x: 50, y: 0 }; // Adjust the coordinates as needed
            },

        },
        legend: {
            enabled: false,
            align: 'left',
            layout: 'horizontal',
            verticalAlign: 'bottom',
            itemMarginBottom: 0,
            itemMarginTop: 0,
        },
        rangeSelector: {
            enabled: false
        },
        backgroundColor: "transparent",
        series: [],
        plotOptions: {
            series: {
                stacking: "overlap",
                dataGrouping: {
                    enabled: false
                },
                lineWidth: 1.5,
                color: "#cccccc",
            }
        },
        accessibility: {
            enabled: false
        },
        yAxis: [{
            lineColor: "#FFD44D",
            gridLineWidth: 0,
            lineWidth: 0.1,
            tickWidth: 0.1,
            opposite: false,
            visible: true,
            unit: 'kWh',
            min: 0,
            max: 100,
            type: "linear",
            title: {
                align: 'high',
                offset: 0,
                rotation: 0,
                y: 10,
                text: "kWh",
                style: {
                    color: "#000000"
                }
            },
            labels: {
                align: "left",
                style: {
                    color: "#000000"
                }
            }
        }],
        xAxis: [{
            type: 'datetime',
            tickInterval: 12 * 3600 * 1000,
            minRange: 60 * 1000,
            tickPixelInterval: 100,
            ordinal: false,
            crosshair: true,
            visible: true,
            min: null,
            max: null,
            lineColor: '#000000',
            tickColor: '#000000',
            labels: {
                style: {
                    color: '#000000'
                }
            },
            internalType: "main",
            dateTimeLabelFormats: {
                second: '%d %b %Y <br/> %H:%M:%S %P',
            },
            zoomEnabled: false
        }],
    })

    const style = {
        backgroundImage: `url(${require('../../assets/images/sloar-background.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    };

    const getData = async (id, cancelToken) => {
        try {
            let reduction = await API.getPublicGenerationRecent(cancelToken, id)
            let tempMeta = {}
            tempMeta = await API.getPublicBuilding(cancelToken, id)
            setMetadata(tempMeta.data)
            let tempArr = Object.values(reduction.data.series)
            setAmount(tempArr[tempArr.length - 1])
            tempArr[tempArr.length - 1] = {
                y: tempArr[tempArr.length - 1],
                marker: {
                    enabled: true,
                    fillColor: '#000000',
                    radius: 5
                },
                dataLabels: {
                    enabled: true,
                    format: tempArr[tempArr.length - 1].toFixed(1).toLocaleString() + " kW",
                    align: 'left',
                    style: {
                        fontSize: '24px',
                        color: '#000000'
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    padding: 10,
                    verticalAlign: 'top',
                    alight: 'center',
                    x: -40,
                    y: 20
                }
            }
            for (let i = 0; i < 35; i++) {
                tempArr.push(null)
            }
            if (Object.keys(reduction.data.series).length === 0) return
            let tempOptions = JSON.parse(JSON.stringify(options))
            let startDate = moment.tz(Object.keys(reduction.data.series)[0].replaceAll(" ", "T"), "Etc/GMT")
            startDate = startDate.valueOf();
            let tempESeries = {
                data: tempArr,
                visible: true,
                pointStart: startDate,
                pointInterval: 60 * 60 * 1000,
                color: '#FFD44D',
                fillColor: '#FFD44D',
                lineWidth: 4,
                name: `Renewable Generation`,
                type: 'area',
                labels: {
                    style: {
                        color: '#000000'
                    }
                },
                yAxis: 0,
                xAxis: 0,
                internalId: 'e',
                toggle_checked: true,
                zIndex: 0
            }
            tempOptions.series = tempESeries
            setOptions(tempOptions)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('error' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }

    useEffect(() => {
        let id = queryParams.get('id')

        const cancelToken = API.cancelToken();
        const fetchData = () => {
            getData(id, cancelToken);
        };

        fetchData();

        const intervalId = setInterval(fetchData, 600000);

        return () => {
            API.cancel(cancelToken);
            clearInterval(intervalId);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location.pathname])


    useEffect(() => {
        if (!options || amount === null) return
        const updateCounter = () => {
            let factor = flicker ? 0.05 : -0.05
            let num = factor * amount
            setAmount(prevCounter => prevCounter + num);
            setFlicker(!flicker)
            let tempOptions = JSON.parse(JSON.stringify(options))
            tempOptions.series.data[tempOptions.series.data.length - 36] = {
                y: amount + num,
                marker: {
                    enabled: true,
                    fillColor: '#000000',
                    radius: 5
                },
                dataLabels: {
                    enabled: true,
                    format: (amount + num).toFixed(1).toLocaleString() + " kW",
                    align: 'left',
                    style: {
                        fontSize: '24px',
                        color: '#000000'
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    padding: 10,
                    verticalAlign: 'top',
                    alight: 'center',
                    x: -40,
                    y: 20
                }
            }
            setOptions(tempOptions)
        };
        const intervalId = setInterval(updateCounter, 10000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, amount])

    return (
        <div className='generation-history flex flex-col flex-nowrap item-center jusitfy-center h-screen'>
            <div className='flex flex-nowrap justify-between items-center'>
                <div className='w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[40px]'>
                    {
                        metadata &&
                        <div className='flex flex-nowrap justify-start items-center h-[143px]'>
                            <div className="relative w-full h-full max-w-[143px] max-h-[143px] mr-4">
                                <div className="absolute inset-0 rounded-full overflow-hidden">
                                    <img className="w-full h-full object-cover object-center" src={metadata.image} alt="" />
                                </div>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center h-[143px] border-y border-[#979797] '>
                                <div className='flex flex-col flex-nowrap justify-center item-center h-full'>
                                    <div className="flex flex-nowrap justify-center item-center desktop:text-[32px] laptop:text-[20px] text-[#383838] font-semibold pr-[36px]">
                                        {metadata.building}
                                    </div>
                                </div>
                                <div className='border-[#979797] border h-[60%]'></div>
                                <div className="flex flex-nowrap justify-start item-center h-full">
                                    <img className='h-full' src={metadata.logo} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='flex flex-nowrap justify-between items-center w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[30px]'>
                    <img className='w-[353px]' src={require('../../assets/images/greenwave-black.png')} alt="" />
                    <div>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            Making our world more responsible...
                        </p>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            one building at a time!
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-nowrap justify-between items-center flex-grow' style={style}>
                <div className="w-1/2 flex flex-col justify-between items-stretch h-full">
                    <div className='flex flex-nowrap items-center justify-center h-full'>
                        {amount !== null &&
                            <div className='w-full flex flex-nowrap items-center justify-center mt-[-10%]'>
                                <PublicArcGauge type="e" unit="kW" amount={amount} max={100} generation={true} />
                            </div>}
                    </div>
                </div>
                <div className="w-1/2 relative h-full">
                    <div className='w-full h-full px-[150px] py-[60px]'>
                        <p className='text-[36px] text-[white] mb-10'>Renewable Generation - Previous 3 days</p>
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={"stockChart"}
                            options={options}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PublicGenerationHistory;