import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import PublicArcGauge from '../../components/PublicArcGauge';

const PublicUtilitiesUsage = () => {


    const location = useLocation();
    const [currentE, setCurrentE] = useState(null)
    const [currentW, setCurrentW] = useState(null)
    const [currentG, setCurrentG] = useState(null)
    const [count, setCount] = useState(null)
    const [flicker, setFlicker] = useState(false)
    const [metadata, setMetadata] = useState(null)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const style1 = {
        backgroundImage: `url(${require('../../assets/images/city-yellow.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat'
    }
    const style2 = {
        backgroundImage: `url(${require('../../assets/images/city-orange.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat'
    }
    const style3 = {
        backgroundImage: `url(${require('../../assets/images/city-blue.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat'
    }

    const getData = async (cancelToken) => {
        try {
            let id = queryParams.get('id')
            let tempMeta = await API.getPublicBuilding(cancelToken, id)
            let tempCurrentE = await API.getPublicCurrentReading(cancelToken, id)
            let tempCurrentW = await API.getPublicCurrentReadingD(cancelToken, id, 'Water')
            let tempCurrentG = await API.getPublicCurrentReadingD(cancelToken, id, 'Natural Gas')
            setCount([tempCurrentE.data, tempCurrentW.data, tempCurrentG.data].filter(item => item !== null).length)
            setMetadata(tempMeta.data)
            setCurrentE(tempCurrentE.data)
            setCurrentW(tempCurrentW.data)
            setCurrentG(tempCurrentG.data)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('error' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }

    useEffect(() => {
        const cancelToken = API.cancelToken();
        const fetchData = () => {
            getData(cancelToken);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => {
            API.cancel(cancelToken);
            clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location.pathname])


    useEffect(() => {
        const updateCounter = () => {
            let factor = flicker ? 0.05 : -0.05
            if (currentE !== null) {
                let num = factor * currentE
                setCurrentE(prevCounter => prevCounter + num);
            }
            if (currentW !== null) {
                let num = factor * currentW
                setCurrentW(prevCounter => prevCounter + num);
            }
            if (currentG !== null) {
                let num = factor * currentG
                setCurrentG(prevCounter => prevCounter + num);
            }
            setFlicker(!flicker)
        };
        const intervalId = setInterval(updateCounter, 10000);
        return () => clearInterval(intervalId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentE, currentW, currentG])

    return (
        <div className='flex flex-col flex-nowrap item-center jusitfy-center h-screen'>
            <div className='flex flex-nowrap justify-between items-center'>
                <div className='w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[40px]'>
                    {
                        metadata &&
                        <div className='flex flex-nowrap justify-start items-center h-[143px]'>
                            <div className="relative w-full h-full max-w-[143px] max-h-[143px] mr-4">
                                <div className="absolute inset-0 rounded-full overflow-hidden">
                                    <img className="w-full h-full object-cover object-center" src={metadata.image} alt="" />
                                </div>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center h-[143px] border-y border-[#979797] '>
                                <div className='flex flex-col flex-nowrap justify-center item-center h-full'>
                                    <div className="flex flex-nowrap justify-center item-center desktop:text-[32px] laptop:text-[20px] text-[#383838] font-semibold pr-[36px]">
                                        {metadata.building}
                                    </div>
                                </div>
                                <div className='border-[#979797] border h-[60%]'></div>
                                <div className="flex flex-nowrap justify-start item-center h-full">
                                    <img className='h-full' src={metadata.logo} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='flex flex-nowrap justify-between items-center w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[30px]'>
                    <img className='w-[353px] ' src={require('../../assets/images/greenwave-black.png')} alt="" />
                    <div>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            Making our world more responsible...
                        </p>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            one building at a time!
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center flex-grow relative w-full h-full'>
                <div className='flex flex-nowrap items-center justify-center w-full absolute top-[10%] left-0'>
                    <h1 className='desktop:text-[60px] laptop:text-[30px] text-[#FFFFFF] font-bold text-center w-full'>Live Building Usage</h1>
                </div>
                <div className='flex flex-nowrap items-center justify-center h-full w-full'>
                    {
                        currentE !== null && <div className={`w-${count === 1 ? 'full' : '1/' + count} h-full flex flex-nowrap items-center justify-center`} style={style1}>
                            <PublicArcGauge type="e" unit="kW" amount={parseInt(currentE)} max={parseInt(currentE) * 1.86} generation={false} />
                        </div>
                    }
                    {
                        currentG !== null && <div className={`w-${count === 1 ? 'full' : '1/' + count} h-full flex flex-nowrap items-center justify-center`} style={style2}>
                            <PublicArcGauge type="g" unit="ft³ / min" amount={parseInt(currentG)} max={parseInt(currentG) * 1.76} generation={false} />
                        </div>
                    }
                    {
                        currentW !== null && <div className={`w-${count === 1 ? 'full' : '1/' + count} h-full flex flex-nowrap items-center justify-center`} style={style3}>
                            <PublicArcGauge type="w" unit="litres / min" amount={parseInt(currentW)} max={parseInt(currentW) * 1.56} generation={false} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default PublicUtilitiesUsage;