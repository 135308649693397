import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';

const Building = () => {

    const location = useLocation();
    const [building, setBuilding] = useState(null)
    const [clients, setClients] = useState(null)
    const [operators, setOperators] = useState(null)
    const [manager, setManager] = useState(false)
    const [getPutPost, setGetPutPost] = useState()
    const [buildingData, setBuildingData] = useState({
        building_id: "",
        property_id: "",
        client_id: "",
        building: "",
        address: "",
        city: "",
        province: "",
        postalcode: "",
        country: "",
        timezone: "",
        latitude: "",
        longitude: "",
        sq_footage: "",
        building_type: "",
        year_built: "",
        image: "",
        rep_name: "",
        rep_phone: "",
        rep_email: "",
        rep_image: ""
    })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_buildings_and_operators_list", "create_buildings_and_operators", "update_buildings_and_operators"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/map')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/building/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setBuilding(queryParams.get('building'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (getPutPost === 'get' && building !== null) {
            setLoading(true)
            const auth = context.getUser.token;
            API.getBuilding(cancelToken, auth, building).then(res => {
                setLoading(false)
                setBuildingData(res.data)
                return
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if ('error' in err.response.data) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, building])

    useEffect(() => {
        if (!context.getUser) return
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        API.getClients(cancelToken, auth).then(res => {
            setClients(res.data)
            return
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })
        API.getOperators(cancelToken, auth).then(res => {
            setOperators(res.data)
            return
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUser])

    const updateBuilding = (e) => {
        const { name, value } = e.target;
        if (name === 'image' || name === 'rep_image') {
            const selectedImage = e.target.files[0];
            if (selectedImage) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    setBuildingData(prevValue => {
                        return {
                            ...prevValue,
                            [name]: base64ImageData
                        }
                    })
                };

                reader.readAsDataURL(selectedImage);
            }
        } else {
            setBuildingData(prevValue => {
                return {
                    ...prevValue,
                    [name]: value.trim()
                }
            })
        }
    }

    const postBuilding = async (e) => {
        if (buildingData.building === "") {
            setError({ building: "Missing Value" })
        } else {
            let payload = JSON.parse(JSON.stringify(buildingData))
            const toDel = ['client_name', 'devices', 'e_channels', 'hierarchy', 'w_channels', 'e_sources', 'operating_hours', 'w_hierarchy', 'g_channels', 'g_hierarchy', 'created_at', 'updated_at', 'w_sources', 'alerts', 'g_sources']
            for (let item of toDel) {
                delete payload[item];
            }
            if (getPutPost === 'put') {
                delete payload['building_id'];
            }
            for (let key in payload) {
                if (payload[key] === null || String(payload[key]).trim() === "") {
                    delete payload[key];
                }
            }
            if (payload['image'] && payload['image'].includes('https')) {
                delete payload['image'];
            }
            if (payload['rep_image'] && payload['rep_image'].includes('https')) {
                delete payload['rep_image'];
            }
            setLoading(true)
            const cancelToken = API.cancelToken();
            const auth = context.getUser.token;
            payload.operators = buildingData.operators ? buildingData.operators.map(e => e.operator_id) : []
            try {
                if (getPutPost === 'post') {
                    await API.postBuilding(cancelToken, auth, payload)
                    navigate(`/building?building=${buildingData.building_id}`)
                } else {
                    await API.putBuilding(cancelToken, auth, building, payload)
                    setGetPutPost('get')
                    setError(null)
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    setError(err.response.data)
                    try {
                        if ('error' in err.response.data) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } finally {
                setLoading(false)
            }
        }
    }

    const updateOperators = (operator) => {
        let inList = buildingData.operators.find(e => e.operator_id === operator.operator_id)
        let tempData = JSON.parse(JSON.stringify(buildingData))
        if (!inList) {
            tempData.operators.push(operator)
        } else {
            tempData.operators = tempData.operators.filter(e => e.operator_id !== operator.operator_id);
        }
        setBuildingData(tempData)
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/buildings">
                    Buildings
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? buildingData.building : "Add Building"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='input-border pb-8 items-center mb-6'>
                    <input
                        placeholder='Building Name'
                        className='text-3xl p-2 outline-none hide-input-background laptop:w-1/2 tablet:w-full mobile:w-full'
                        type="text"
                        name='building'
                        disabled={getPutPost === 'get'}
                        defaultValue={buildingData.building ? buildingData.building : ""}
                        onChange={updateBuilding} />
                    {
                        error && error.building &&
                        <p className='text-red text-xs p-2'>{error.building}</p>

                    }
                </div>
                {
                    loading &&
                    <Loader />
                }
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="building_id">Building ID</label>
                            <input
                                placeholder='Building ID'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="building_id" defaultValue={buildingData.building_id ? buildingData.building_id : ""}
                                disabled={getPutPost !== 'post'}
                                onChange={updateBuilding} />
                            {
                                error && error.building_id &&
                                <p className='text-red text-xs'>{error.building_id[0]}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="image">Building Image</label>
                            {
                                getPutPost === 'get' &&
                                <img className='w-20' src={buildingData.image ? buildingData.image : require('../../assets/images/placeholder-image.png')} alt="" />
                            }
                            {getPutPost !== 'get' &&
                                <input
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="file"
                                    name="image"
                                    onChange={updateBuilding} />
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="property_id">Property ID</label>
                            <input
                                placeholder='Property ID'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="property_id" defaultValue={buildingData.property_id ? buildingData.property_id : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateBuilding} />
                            {
                                error && error.property_id &&
                                <p className='text-red text-xs'>{error.property_id[0]}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="client_id">Client ID</label>
                            <select
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                name="client_id"
                                value={buildingData.client_id ? buildingData.client_id : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateBuilding}
                            >
                                <option value="">Select</option>
                                {
                                    clients && clients.map((e, idx) => (
                                        <option key={idx + 'clientidkey'} value={e.client_id}>{e.client_id}</option>
                                    ))
                                }
                            </select>
                            {
                                error && error.client_id &&
                                <p className='text-red text-xs'>{error.client_id[0]}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="address">Address</label>
                            <input
                                placeholder='Address'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="address" defaultValue={buildingData.address ? buildingData.address : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateBuilding} />
                            {
                                error && error.address &&
                                <p className='text-red text-xs'>{error.address[0]}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="city">City</label>
                            <input
                                placeholder='City'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="city" defaultValue={buildingData.city ? buildingData.city : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateBuilding} />
                            {
                                error && error.city &&
                                <p className='text-red text-xs'>{error.city[0]}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="province">Province</label>
                            <select
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                name="province"
                                value={buildingData.province ? buildingData.province : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateBuilding}
                            >
                                <option value="">Select</option>
                                <option value="AB">AB</option>
                                <option value="BC">BC</option>
                                <option value="MB">MB</option>
                                <option value="NB">NB</option>
                                <option value="NL">NL</option>
                                <option value="NT">NL</option>
                                <option value="NS">NS</option>
                                <option value="NU">NU</option>
                                <option value="ON">ON</option>
                                <option value="PE">PE</option>
                                <option value="QC">QC</option>
                                <option value="SK">SK</option>
                                <option value="YT">YT</option>
                            </select>
                            {
                                error && error.province &&
                                <p className='text-red text-xs'>{error.province[0]}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="postalcode">Postal Code</label>
                            <input
                                placeholder='Postal Code'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="postalcode" defaultValue={buildingData.postalcode ? buildingData.postalcode : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateBuilding} />
                            {
                                error && error.postalcode &&
                                <p className='text-red text-xs'>{error.postalcode[0]}</p>

                            }
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="country">Country</label>
                                <input
                                    placeholder='Country'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="country" defaultValue={buildingData.country ? buildingData.country : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateBuilding} />
                                {
                                    error && error.country &&
                                    <p className='text-red text-xs'>{error.country[0]}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="timezone">Timezone</label>
                                    <input
                                        placeholder='Timezone'
                                        className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="text"
                                        name="timezone" defaultValue={buildingData.timezone ? buildingData.timezone : ""}
                                        disabled={getPutPost === 'get'}
                                        onChange={updateBuilding} />
                                    {
                                        error && error.timezone &&
                                        <p className='text-red text-xs'>{error.timezone[0]}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="latitude">Latitude</label>
                                <input
                                    placeholder='Latitude'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="number"
                                    step="0.1"
                                    name="latitude" defaultValue={buildingData.latitude ? buildingData.latitude : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateBuilding} />
                                {
                                    error && error.latitude &&
                                    <p className='text-red text-xs'>{error.latitude[0]}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="longitude">Longitude</label>
                                    <input
                                        placeholder='Longitude'
                                        className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="number"
                                        step="0.1"
                                        name="longitude" defaultValue={buildingData.longitude ? buildingData.longitude : ""}
                                        disabled={getPutPost === 'get'}
                                        onChange={updateBuilding} />
                                    {
                                        error && error.longitude &&
                                        <p className='text-red text-xs'>{error.longitude[0]}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="sq_footage">Square Footage</label>
                                <input
                                    placeholder='Square Footage'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="number"
                                    step="1"
                                    name="sq_footage" defaultValue={buildingData.sq_footage ? buildingData.sq_footage : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateBuilding} />
                                {
                                    error && error.sq_footage &&
                                    <p className='text-red text-xs'>{error.sq_footage[0]}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="building_type">Building Type</label>
                                    <input
                                        placeholder='Building Type'
                                        className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="text"
                                        name="building_type" defaultValue={buildingData.building_type ? buildingData.building_type : ""}
                                        disabled={getPutPost === 'get'}
                                        onChange={updateBuilding} />
                                    {
                                        error && error.building_type &&
                                        <p className='text-red text-xs'>{error.building_type[0]}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="year_built">Year Built</label>
                                <input
                                    placeholder='Year Built'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="number"
                                    step="1"
                                    name="year_built" defaultValue={buildingData.year_built ? buildingData.year_built : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateBuilding} />
                                {
                                    error && error.year_built &&
                                    <p className='text-red text-xs'>{error.year_built[0]}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="rep_name">Rep. Name</label>
                                <input
                                    placeholder='Rep. Name'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="rep_name" defaultValue={buildingData.rep_name ? buildingData.rep_name : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateBuilding} />
                                {
                                    error && error.rep_name &&
                                    <p className='text-red text-xs'>{error.rep_name[0]}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="rep_phone">Rep. Phone</label>
                                    <input
                                        placeholder='Rep. Phone'
                                        className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="text"
                                        name="rep_phone" defaultValue={buildingData.rep_phone ? buildingData.rep_phone : ""}
                                        disabled={getPutPost === 'get'}
                                        onChange={updateBuilding} />
                                    {
                                        error && error.rep_phone &&
                                        <p className='text-red text-xs'>{error.rep_phone[0]}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="rep_email">Rep. Email</label>
                                <input
                                    placeholder='Rep. Email'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="rep_email" defaultValue={buildingData.rep_email ? buildingData.rep_email : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateBuilding} />
                                {
                                    error && error.rep_email &&
                                    <p className='text-red text-xs'>{error.rep_email[0]}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="image">Rep. Image</label>
                                    {
                                        getPutPost === 'get' &&
                                        <img className='w-20' src={buildingData.rep_image ? buildingData.rep_image : require('../../assets/images/placeholder-image.png')} alt="" />
                                    }
                                    {getPutPost !== 'get' &&
                                        <input
                                            className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                            type="file"
                                            name="rep_image"
                                            onChange={updateBuilding} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-full tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap justify-between items-center'>
                                <p className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="buildings">Operators</p>
                                <div className='flex flex-wrap w-full text-sm p-2 outline-none overflow-hidden truncate'>
                                    {buildingData.operators ? buildingData.operators.map((e, idx) => (
                                        <div key={idx + "selectedbuildings"} className='flex flex-nowrap items-center w-content'>
                                            <div className={`rouned-full active-user`}></div>
                                            <p className='whitespace-nowrap'>
                                                {e.op_name}
                                            </p>
                                        </div>

                                    )) : ""}
                                </div>
                                {getPutPost !== 'get' &&
                                    <div className='flex flex-nowrap tablet:w-2/12 mobile:w-full justify-center items-center cursor-pointer mt-4' onClick={() => setManager(!manager)}>
                                        {GlobalSVG.search()}
                                        <p className='ml-2 text-green'>Manage Operators</p>
                                    </div>}

                                {
                                    error && error.sq_footage &&
                                    <p className='text-red text-xs'>{error.sq_footage[0]}</p>

                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Building
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && buildingData.building !== "" && !loading && <button className='submit-button' onClick={postBuilding}>
                            Save
                        </button>
                    }
                </div>
            </div>
            {
                manager &&
                <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center'>
                    <div className='card laptop:w-2/5 tablet:w-3/5 mobile:w-11/12 m-2 p-4 background-white'>
                        <div className='laptop:h-[400px] tablet:h-[400px] mobile:h-[350px] overflow-auto m-2'>
                            {operators && operators.map((e, idx) => (
                                <div className='flex flex-nowrap w-full truncate p-1 cursor-pointer' key={idx + "buildings"} onClick={() => updateOperators(e)}>
                                    <input className='mr-3' type="checkbox" checked={buildingData.operators.map(i => i.operator_id).includes(e.operator_id)} />
                                    <p>{e.op_name}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-nowrap items-center justify-end w-full">
                            <div className='mr-8 text-green3 cursor-pointer' onClick={() => setManager(false)}>
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Building;