import React from 'react';
import { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from "../../utils/API";
import Swal from "sweetalert2";
import GraphFuncs from '../../utils/GraphFuncs';
import GlobalSVG from '../../utils/GlobalSVG';
import './style.css'
import GlobalFuncs from '../../utils/GlobalFuncs';
import DateRangePicker from '../../components/DateRangePicker';
import ReportGraph1 from '../../components/ReportGraph1';
import ReportPie1 from '../../components/ReportPie1';
import ReportPie2 from '../../components/ReportPie2';
import Accordion from "../../components/Accordion";
import BreakerGraph from '../../components/BreakerGraph';
import ReportGraph2 from '../../components/ReportGraph2';
import Loader from '../../components/Loader';
import moment from 'moment';

const Reports = () => {

    const context = useContext(AppContext);
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const [mainCircuit, setMainCircuit] = useState(null)
    const [unit, setUnit] = useState('price')
    const [view, setView] = useState('circuit')

    const [openFilters, setOpenFilters] = useState(false)

    const [submit, setSubmit] = useState(false);
    const [filteredCircuits, setFilteredCircuits] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [filteredDistribution, setFilteredDistribution] = useState([]);
    const [finalCircuits, setFinalCircuits] = useState([])
    const [finalCircuitsNoComp, setFinalCircuitsNoComp] = useState([])
    const [showBuildings, setShowBuildings] = useState(false)
    const [opHours, setOpHours] = useState([])
    const [baseCircuits, setBaseCircuits] = useState(null)
    const [generationCircuits, setGenerationCircuits] = useState(null)
    const [generationFinal, setGenerationFinal] = useState(null)
    const [peakPf, setPeakPf] = useState(null)
    const [peakPfCompare, setPeakPfCompare] = useState(null)
    const [generationFinalNoSubt, setGenerationFinalNoSubt] = useState(null)
    const [categoriesOptions, setCategoriesOptions] = useState(null)
    const [buildingDistOptions, setBuildingDistOptions] = useState(null)
    const [compareCircuits, setCompareCircuits] = useState(null)
    const [compareCircuitsNoSubt, setCompareCircuitsNoSubt] = useState(null)
    const [virtualTotal, setVirtualTotal] = useState(null)
    const [virtualTotalCompare, setVirtualTotalCompare] = useState(null)
    const [realPeak, setRealPeak] = useState(null)
    const [realPeakCompare, setRealPeakCompare] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [totalPriceCompare, setTotalPriceCompare] = useState(null)
    const [totalPriceCompareNoDiff, setTotalPriceCompareNoDiff] = useState(null)
    const [totalCo, setTotalCo] = useState(null)
    const [totalCoCompare, setTotalCoCompare] = useState(null)
    const [complete, setComplete] = useState("")
    const [settings, setSettings] = useState({ startEnd: null, compareOn: false, compareStartEnd: null })

    const [tempSettings, setTempSettings] = useState({ startEnd: [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], compareOn: false, compareStartEnd: null })
    const navigate = useNavigate();


    useEffect(() => {
        if (!context.getBuildingInfo || !settings.startEnd || !submit) return

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;

        API.getOpHoursRange(cancelToken, auth, context.getBuilding, settings.startEnd[0], settings.startEnd[1],).then(res => {
            setOpHours(res.data)
        }).catch(e => {
            if (e.message === 'cancelling') return
            console.log("Failed to get Op hours")
            console.log(e);
        })

        let end_circuits = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)
        end_circuits.type = 'electricity'
        setMainCircuit(end_circuits)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuildingInfo, settings.startEnd, submit]);

    useEffect(() => {
        let newBuilding = queryParams.get('building')
        let start = queryParams.get('start')
        if (!start) start = moment().subtract(1, 'months').format('YYYY-MM-DD')
        let end = queryParams.get('end')
        if (!end) end = moment().format('YYYY-MM-DD')

        if (!context.getUserInfo) return
        if (queryParams.size !== 0) {
            let building = context.getUserInfo.buildings.find(e => e.building_id === newBuilding)
            if (building && building.building !== tempSettings.building) {
                setTempSettings(prev => ({ ...prev, building: building.building, compareAllowed: false, compareOn: false, startEnd: [start, end] }))
                if (newBuilding !== context.getBuilding) setShowBuildings(false)
            } else {
                setTempSettings(prev => ({ ...prev, startEnd: [start, end] }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, context.getUserInfo])

    useEffect(() => {
        if (!opHours || !mainCircuit) return
        const auth = context.getUser.token;
        const cancelToken = API.cancelToken();
        GraphFuncs.getPeakKvaBusinessHours(cancelToken, auth, mainCircuit, settings.startEnd[0], settings.startEnd[1], opHours).then(dict => {
            getPeakBatchAsync(dict)
        }).catch(e => {
            console.log(e)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        })

        const getPeakBatchAsync = async (peaksDict) => {
            try {
                let peakOn = Object.keys(peaksDict.on)[0]
                let peakOff = Object.keys(peaksDict.off)[0]
                if ((!peakOn || peakOn === "null") || (!peakOff || peakOff === "null")) {
                    return
                }

                // Get peak kva for all circuits during ON hours
                let onHours = await API.getPeakBatch(cancelToken, auth, context.getBuilding, peakOn)
                onHours = onHours.data

                // Get peak kva for all circuits during OFF hours
                let offHours = await API.getPeakBatch(cancelToken, auth, context.getBuilding, peakOff)
                offHours = offHours.data

                // Get kwh batch for all circuits (on, off included in response)
                let kwh = await API.getKwhBatch(cancelToken, auth, context.getBuilding, settings.startEnd[0], settings.startEnd[1])
                kwh = kwh.data

                // Get co for main circuit
                let co = await API.getPriceCoElectric(cancelToken, auth, settings.startEnd[0], settings.startEnd[1], mainCircuit.circuit_name, mainCircuit.device_id, 60)
                co = Object.values(co.data.series).reduce((sum, value) => (sum + value), 0)
                setTotalCo(co)
                // Check if acutal peak is in on or off hours
                let realPeak = {}
                let realPeakOn = true
                if (peaksDict.on[peakOn] > peaksDict.off[peakOff]) {
                    realPeak = { [peakOn]: peaksDict.on[peakOn] }
                } else {
                    realPeakOn = false
                    realPeak = { [peakOff]: peaksDict.off[peakOff] }
                }
                setRealPeak(realPeak)

                // Get main circuits price
                let priceTotal = await API.getPriceE(cancelToken, auth, settings.startEnd[0], settings.startEnd[1], mainCircuit.circuit_name, mainCircuit.device_id)
                priceTotal = priceTotal.data
                setTotalPrice(priceTotal.total)

                let pfDict = await API.getPeakBatch(cancelToken, auth, context.getBuilding, Object.keys(realPeak)[0], 'pf')
                pfDict = pfDict.data

                // Get sum of kwh consumption
                delete kwh['Cap Bank'];
                let totalKwh = Object.values(kwh).reduce((sum, value) => (sum + value.on + value.off), 0)

                setVirtualTotal(totalKwh.toFixed(2))
                let tempCircuitDict = {}

                let categories = new Set()
                let buildingDists = new Set()
                // Create main circuit dict
                for (let c in onHours) {
                    let channelInfo = context.getBuildingInfo.e_channels.filter(e => e.channel_name.trim() === c.trim())
                    // let pf = await API.getElectricity(cancelToken, auth, settings.startEnd[0], settings.startEnd[1], channelInfo[0].device_id, 60, 'pf', channelInfo[0].channel_name)
                    // pf = Object.values(pf.data.series).reduce((sum, value) => (sum + value), 0) / Object.values(pf.data.series).length

                    // Get circuit kwh consumption
                    let circuitKwh = c in kwh ? kwh[c] : { on: 0, off: 0 }

                    // Get price of circuit as proportion of main for ON hours
                    let priceOn = {
                        kwh_consumption_dollar: (circuitKwh.on / totalKwh) * priceTotal.kwh_consumption_dollar,
                        base_dollar: (circuitKwh.on / totalKwh) * priceTotal.base_dollar,
                        carbon_charge_dollar: (circuitKwh.on / totalKwh) * priceTotal.carbon_charge_dollar,
                        kva_consumption_dollar: realPeakOn ? (onHours[c] / Object.values(realPeak)[0]) * priceTotal.kva_consumption_dollar : 0
                    }

                    // Get ratio for taxes
                    let ratioOn = ((priceOn.kwh_consumption_dollar + priceOn.carbon_charge_dollar + priceOn.kva_consumption_dollar + priceOn.base_dollar) /
                        (priceTotal.kwh_consumption_dollar + priceTotal.carbon_charge_dollar + priceTotal.kva_consumption_dollar + priceTotal.base_dollar))

                    if (isNaN(ratioOn)) ratioOn = 0
                    // Add taxes and calculate total
                    priceOn.municipal_tax_dollar = ratioOn * priceTotal.municipal_tax_dollar
                    priceOn.pst_dollar = ratioOn * priceTotal.pst_dollar
                    priceOn.gst_dollar = ratioOn * priceTotal.gst_dollar
                    priceOn.total = priceOn.kwh_consumption_dollar + priceOn.carbon_charge_dollar + priceOn.kva_consumption_dollar + priceOn.municipal_tax_dollar + priceOn.pst_dollar + priceOn.gst_dollar

                    // Get price of circuit as proportion of main for OFF hours
                    let priceOff = {
                        kwh_consumption_dollar: (circuitKwh.off / totalKwh) * priceTotal.kwh_consumption_dollar,
                        base_dollar: (circuitKwh.off / totalKwh) * priceTotal.base_dollar,
                        carbon_charge_dollar: (circuitKwh.off / totalKwh) * priceTotal.carbon_charge_dollar,
                        kva_consumption_dollar: !realPeakOn ? (offHours[c] / Object.values(realPeak)[0]) * priceTotal.kva_consumption_dollar : 0
                    }

                    // Get ratio for taxes
                    let ratioOff = ((priceOff.kwh_consumption_dollar + priceOff.carbon_charge_dollar + priceOff.kva_consumption_dollar + priceOff.base_dollar) /
                        (priceTotal.kwh_consumption_dollar + priceTotal.carbon_charge_dollar + priceTotal.kva_consumption_dollar + priceTotal.base_dollar))

                    if (isNaN(ratioOff)) ratioOff = 0
                    // Add taxes and calculate total
                    priceOff.municipal_tax_dollar = ratioOff * priceTotal.municipal_tax_dollar
                    priceOff.pst_dollar = ratioOff * priceTotal.pst_dollar
                    priceOff.gst_dollar = ratioOff * priceTotal.gst_dollar
                    priceOff.total = priceOff.kwh_consumption_dollar + priceOff.carbon_charge_dollar + priceOff.kva_consumption_dollar + priceOff.municipal_tax_dollar + priceOff.pst_dollar + priceOff.gst_dollar

                    let category = "Undefined"
                    let buldingDist = "Undefined"
                    if (channelInfo.length !== 0) {
                        category = channelInfo[0].category
                        buldingDist = channelInfo[0].building_distribution
                    }
                    buildingDists.add(buldingDist)
                    categories.add(category)
                    // Assemble object
                    tempCircuitDict[c] = {
                        kva: {
                            on: onHours[c],
                            off: c in offHours ? offHours[c] : 0
                        },
                        kwh: circuitKwh,
                        price: {
                            on: priceOn,
                            off: priceOff
                        },
                        co: (circuitKwh.on / totalKwh) * co + (circuitKwh.off / totalKwh) * co,
                        pf: pfDict[c],
                        realPeakOn: realPeakOn,
                        category: category,
                        distribution: buldingDist
                    }
                }
                categories = Array.from(categories)
                categories = categories.filter(item => !['Source', 'Building Distribution', null].includes(item)).sort();
                setBaseCircuits(tempCircuitDict)
                setFilteredCircuits(Object.keys(onHours))
                setFilteredCategories(categories)
                setCategoriesOptions(categories)
                setFilteredDistribution(Array.from(buildingDists))
                setBuildingDistOptions(Array.from(buildingDists))
                setPeakPf(pfDict[mainCircuit.circuit_name])
            } catch (e) {
                console.log(e)
                if (e.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
                return
            }
        }
        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opHours, mainCircuit])

    useEffect(() => {
        if (!baseCircuits || !settings.compareOn || (settings.compareOn && !settings.compareStartEnd) || !context.getBuildingInfo) return
        const auth = context.getUser.token;
        const cancelToken = API.cancelToken();

        API.getOpHoursRange(cancelToken, auth, context.getBuilding, settings.compareStartEnd[0], settings.compareStartEnd[1],).then(res => {
            GraphFuncs.getPeakKvaBusinessHours(cancelToken, auth, mainCircuit, settings.compareStartEnd[0], settings.compareStartEnd[1], res.data).then(dict => {
                getPeakBatchAsync(dict)
            }).catch(e => {
                console.log(e)
                if (e.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            })
        }).catch(e => {
            if (e.message === 'cancelling') return
            console.log("Failed to get Op hours")
            console.log(e);
        })


        const getPeakBatchAsync = async (peaksDict) => {
            try {
                let peakOn = Object.keys(peaksDict.on)[0]
                let peakOff = Object.keys(peaksDict.off)[0]
                if ((!peakOn || peakOn === "null") || (!peakOff || peakOff === "null")) {
                    return
                }

                // Get peak kva for all circuits during ON hours
                let onHours = await API.getPeakBatch(cancelToken, auth, context.getBuilding, peakOn)
                onHours = onHours.data

                // Get peak kva for all circuits during OFF hours
                let offHours = await API.getPeakBatch(cancelToken, auth, context.getBuilding, peakOff)
                offHours = offHours.data

                // Get kwh batch for all circuits (on, off included in response)
                let kwh = await API.getKwhBatch(cancelToken, auth, context.getBuilding, settings.compareStartEnd[0], settings.compareStartEnd[1])
                kwh = kwh.data

                // Get co for main circuit
                let co = await API.getPriceCoElectric(cancelToken, auth, settings.compareStartEnd[0], settings.compareStartEnd[1], mainCircuit.circuit_name, mainCircuit.device_id, 60)
                co = Object.values(co.data.series).reduce((sum, value) => (sum + value), 0)
                setTotalCoCompare(co)
                // Check if acutal peak is in on or off hours
                let realPeak = {}
                let realPeakOn = true
                if (peaksDict.on[peakOn] > peaksDict.off[peakOff]) {
                    realPeak = { [peakOn]: peaksDict.on[peakOn] }
                } else {
                    realPeakOn = false
                    realPeak = { [peakOff]: peaksDict.off[peakOff] }
                }
                setRealPeakCompare(realPeak)

                // Get main circuits price
                let priceTotal = await API.getPriceE(cancelToken, auth, settings.compareStartEnd[0], settings.compareStartEnd[1], mainCircuit.circuit_name, mainCircuit.device_id)
                priceTotal = priceTotal.data
                setTotalPriceCompare(totalPrice - priceTotal.total)
                setTotalPriceCompareNoDiff(priceTotal.total)

                let pfDict = await API.getPeakBatch(cancelToken, auth, context.getBuilding, Object.keys(realPeak)[0], 'pf')
                pfDict = pfDict.data

                // Get sum of kwh consumption
                delete kwh['Cap Bank'];
                let totalKwh = Object.values(kwh).reduce((sum, value) => (sum + value.on + value.off), 0)
                setVirtualTotalCompare((virtualTotal - totalKwh).toFixed(2))

                let tempCircuitDict = {}
                let tempCircuitDictNoSubt = {}
                // Create main circuit dict
                for (let c in onHours) {

                    // Get circuit kwh consumption
                    let circuitKwh = c in kwh ? kwh[c] : { on: 0, off: 0 }

                    // Get price of circuit as proportion of main for ON hours
                    let priceOn = {
                        kwh_consumption_dollar: (circuitKwh.on / totalKwh) * priceTotal.kwh_consumption_dollar,
                        base_dollar: (circuitKwh.on / totalKwh) * priceTotal.base_dollar,
                        carbon_charge_dollar: (circuitKwh.on / totalKwh) * priceTotal.carbon_charge_dollar,
                        kva_consumption_dollar: realPeakOn ? (onHours[c] / Object.values(realPeak)[0]) * priceTotal.kva_consumption_dollar : 0
                    }

                    let priceOnNoSubt = {
                        kwh_consumption_dollar: (circuitKwh.on / totalKwh) * priceTotal.kwh_consumption_dollar,
                        base_dollar: (circuitKwh.on / totalKwh) * priceTotal.base_dollar,
                        carbon_charge_dollar: (circuitKwh.on / totalKwh) * priceTotal.carbon_charge_dollar,
                        kva_consumption_dollar: realPeakOn ? (onHours[c] / Object.values(realPeak)[0]) * priceTotal.kva_consumption_dollar : 0
                    }

                    // Get ratio for taxes
                    let ratioOn = ((priceOn.kwh_consumption_dollar + priceOn.carbon_charge_dollar + priceOn.kva_consumption_dollar + priceOn.base_dollar) /
                        (priceTotal.kwh_consumption_dollar + priceTotal.carbon_charge_dollar + priceTotal.kva_consumption_dollar + priceTotal.base_dollar))

                    if (isNaN(ratioOn)) ratioOn = 0
                    // Add taxes and calculate total
                    priceOn.base_dollar = (c in baseCircuits ? baseCircuits[c].price.on.base_dollar : 0) - priceOn.base_dollar
                    priceOn.kwh_consumption_dollar = (c in baseCircuits ? baseCircuits[c].price.on.kwh_consumption_dollar : 0) - priceOn.kwh_consumption_dollar
                    priceOn.carbon_charge_dollar = (c in baseCircuits ? baseCircuits[c].price.on.carbon_charge_dollar : 0) - priceOn.carbon_charge_dollar
                    priceOn.kva_consumption_dollar = (c in baseCircuits ? baseCircuits[c].price.on.kva_consumption_dollar : 0) - priceOn.kva_consumption_dollar
                    priceOn.municipal_tax_dollar = (c in baseCircuits ? baseCircuits[c].price.on.municipal_tax_dollar : 0) - (ratioOn * priceTotal.municipal_tax_dollar)
                    priceOn.pst_dollar = (c in baseCircuits ? baseCircuits[c].price.on.pst_dollar : 0) - (ratioOn * priceTotal.pst_dollar)
                    priceOn.gst_dollar = (c in baseCircuits ? baseCircuits[c].price.on.gst_dollar : 0) - (ratioOn * priceTotal.gst_dollar)
                    priceOn.total = priceOn.kwh_consumption_dollar + priceOn.carbon_charge_dollar + priceOn.kva_consumption_dollar + priceOn.municipal_tax_dollar + priceOn.pst_dollar + priceOn.gst_dollar

                    priceOnNoSubt.municipal_tax_dollar = ratioOn * priceTotal.municipal_tax_dollar
                    priceOnNoSubt.pst_dollar = ratioOn * priceTotal.pst_dollar
                    priceOnNoSubt.gst_dollar = ratioOn * priceTotal.gst_dollar
                    priceOnNoSubt.total = priceOnNoSubt.kwh_consumption_dollar + priceOnNoSubt.carbon_charge_dollar + priceOnNoSubt.kva_consumption_dollar + priceOnNoSubt.municipal_tax_dollar + priceOnNoSubt.pst_dollar + priceOnNoSubt.gst_dollar

                    // Get price of circuit as proportion of main for OFF hours
                    let priceOff = {
                        kwh_consumption_dollar: (circuitKwh.off / totalKwh) * priceTotal.kwh_consumption_dollar,
                        base_dollar: (circuitKwh.off / totalKwh) * priceTotal.base_dollar,
                        carbon_charge_dollar: (circuitKwh.off / totalKwh) * priceTotal.carbon_charge_dollar,
                        kva_consumption_dollar: !realPeakOn ? (onHours[c] / Object.values(realPeak)[0]) * priceTotal.kva_consumption_dollar : 0
                    }

                    let priceOffNoSubt = {
                        kwh_consumption_dollar: (circuitKwh.off / totalKwh) * priceTotal.kwh_consumption_dollar,
                        base_dollar: (circuitKwh.off / totalKwh) * priceTotal.base_dollar,
                        carbon_charge_dollar: (circuitKwh.off / totalKwh) * priceTotal.carbon_charge_dollar,
                        kva_consumption_dollar: !realPeakOn ? (onHours[c] / Object.values(realPeak)[0]) * priceTotal.kva_consumption_dollar : 0
                    }

                    // Get ratio for taxes
                    let ratioOff = ((priceOff.kwh_consumption_dollar + priceOff.carbon_charge_dollar + priceOff.kva_consumption_dollar + priceOff.base_dollar) /
                        (priceTotal.kwh_consumption_dollar + priceTotal.carbon_charge_dollar + priceTotal.kva_consumption_dollar + priceTotal.base_dollar))

                    if (isNaN(ratioOff)) ratioOff = 0
                    // Add taxes and calculate total
                    priceOff.base_dollar = (c in baseCircuits ? baseCircuits[c].price.off.base_dollar : 0) - priceOff.base_dollar
                    priceOff.kwh_consumption_dollar = baseCircuits[c].price.off.kwh_consumption_dollar - priceOff.kwh_consumption_dollar
                    priceOff.carbon_charge_dollar = baseCircuits[c].price.off.carbon_charge_dollar - priceOff.carbon_charge_dollar
                    priceOff.kva_consumption_dollar = baseCircuits[c].price.off.kva_consumption_dollar - priceOff.kva_consumption_dollar
                    priceOff.municipal_tax_dollar = baseCircuits[c].price.off.municipal_tax_dollar - (ratioOff * priceTotal.municipal_tax_dollar)
                    priceOff.pst_dollar = baseCircuits[c].price.off.pst_dollar - (ratioOff * priceTotal.pst_dollar)
                    priceOff.gst_dollar = baseCircuits[c].price.off.gst_dollar - (ratioOff * priceTotal.gst_dollar)
                    priceOff.total = priceOff.kwh_consumption_dollar + priceOff.carbon_charge_dollar + priceOff.kva_consumption_dollar + priceOff.municipal_tax_dollar + priceOff.pst_dollar + priceOff.gst_dollar

                    priceOffNoSubt.municipal_tax_dollar = ratioOff * priceTotal.municipal_tax_dollar
                    priceOffNoSubt.pst_dollar = ratioOff * priceTotal.pst_dollar
                    priceOffNoSubt.gst_dollar = ratioOff * priceTotal.gst_dollar
                    priceOffNoSubt.total = priceOffNoSubt.kwh_consumption_dollar + priceOffNoSubt.carbon_charge_dollar + priceOffNoSubt.kva_consumption_dollar + priceOffNoSubt.municipal_tax_dollar + priceOffNoSubt.pst_dollar + priceOffNoSubt.gst_dollar

                    let channelInfo = context.getBuildingInfo.e_channels.filter(e => e.channel_name.trim() === c)
                    let category = "Undefined"
                    let buldingDist = "Undefined"
                    if (channelInfo.length !== 0) {
                        category = channelInfo[0].category
                        buldingDist = channelInfo[0].building_distribution
                    }
                    // Assemble object
                    tempCircuitDict[c] = {
                        kva: {
                            on: baseCircuits[c].kva.on - onHours[c],
                            off: c in offHours ? baseCircuits[c].kva.off - offHours[c] : baseCircuits[c].kva.on - 0
                        },
                        kwh: {
                            on: baseCircuits[c].kwh.on - circuitKwh.on,
                            off: baseCircuits[c].kwh.off - circuitKwh.off
                        },
                        price: {
                            on: priceOn,
                            off: priceOff
                        },
                        co: baseCircuits[c].co - ((circuitKwh.on / totalKwh) * co + (circuitKwh.off / totalKwh) * co),
                        pf: baseCircuits[c].pf - pfDict[c],
                        realPeakOn: realPeakOn,
                        category: category,
                        distribution: buldingDist
                    }

                    tempCircuitDictNoSubt[c] = {
                        kva: {
                            on: onHours[c],
                            off: c in offHours ? offHours[c] : 0
                        },
                        kwh: {
                            on: circuitKwh.on,
                            off: circuitKwh.off
                        },
                        price: {
                            on: priceOnNoSubt,
                            off: priceOffNoSubt
                        },
                        co: (circuitKwh.on / totalKwh) * co + (circuitKwh.off / totalKwh) * co,
                        pf: pfDict[c],
                        realPeakOn: realPeakOn,
                        category: category,
                        distribution: buldingDist
                    }
                }
                setCompareCircuitsNoSubt(tempCircuitDictNoSubt)
                setCompareCircuits(tempCircuitDict)
                setPeakPfCompare(pfDict[mainCircuit.circuit_name])
            } catch (e) {
                console.log(e)
                if (e.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
                return
            }
        }
        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opHours, mainCircuit, settings.compareStartEnd])

    useEffect(() => {
        if (!baseCircuits) return
        if (settings.compareOn && !compareCircuits) return
        let tempFiltered
        let tempFilteredNoComp
        if (settings.compareOn) {
            tempFiltered = JSON.parse(JSON.stringify(compareCircuits))
            for (let key in tempFiltered) {
                if (filteredCircuits.length > 0 && !filteredCircuits.includes(key)) {
                    delete tempFiltered[key]
                    continue
                }
                if (filteredCategories.length > 0 && !filteredCategories.includes(tempFiltered[key].category)) {
                    delete tempFiltered[key]
                    continue
                }
                if (filteredCategories.length > 0 && !filteredDistribution.includes(tempFiltered[key].distribution)) {
                    delete tempFiltered[key]
                    continue
                }
            }
        }
        tempFilteredNoComp = JSON.parse(JSON.stringify(baseCircuits))
        for (let key in tempFilteredNoComp) {
            if (filteredCircuits.length > 0 && !filteredCircuits.includes(key)) {
                delete tempFilteredNoComp[key]
                continue
            }
            if (filteredCategories.length > 0 && !filteredCategories.includes(tempFilteredNoComp[key].category)) {
                delete tempFilteredNoComp[key]
                continue
            }
            if (filteredCategories.length > 0 && !filteredDistribution.includes(tempFilteredNoComp[key].distribution)) {
                delete tempFilteredNoComp[key]
                continue
            }
        }

        setFinalCircuitsNoComp(tempFilteredNoComp)
        if (settings.compareOn) {
            setFinalCircuits(tempFiltered)
        } else {
            setFinalCircuits(tempFilteredNoComp)
        }

    }, [baseCircuits, compareCircuits, filteredCircuits, filteredCategories, filteredDistribution, settings.compareOn])

    useEffect(() => {
        if (view !== 'generation') return
        let sources = context.getBuildingInfo.e_sources
        sources.push(mainCircuit)
        const cancelToken = API.cancelToken();

        const getSources = () => {
            return new Promise(async (resolve, reject) => {
                var promises = []
                sources.forEach((circuit) => {
                    let tempCircuit = JSON.parse(JSON.stringify(circuit));
                    const auth = context.getUser.token;
                    if (!circuit || (circuit.channels[0].category !== 'Building Distribution' && circuit.channels[0].sub_category === 'Utility')) return
                    promises.push(API.getElectricity(cancelToken, auth, settings.startEnd[0], settings.startEnd[1], tempCircuit.device_id, 60, 'kw', tempCircuit.circuit_name).then(res => {
                        res.data.circuit_name = tempCircuit.circuit_name
                        return res.data
                    }).catch(e => {
                        if (e.message === 'cancelling') return
                        console.log(e);
                    }))

                });
                let finalData = await Promise.all(promises)
                resolve(finalData)
            })

        }
        getSources().then(sources => {
            let tempSources = {}
            let businessHoursNoMinutes = opHours.map(e => e.split(":")[0])
            for (let source of sources) {
                if (!source) continue
                tempSources[source.circuit_name] = { kwh: { on: 0, off: 0 } }
                for (let timestamp in source.series) {
                    if (businessHoursNoMinutes.includes(timestamp.split(":")[0])) {
                        tempSources[source.circuit_name].kwh.on += source.series[timestamp]
                    } else {
                        tempSources[source.circuit_name].kwh.off += source.series[timestamp]
                    }
                }
            }
            setGenerationCircuits(tempSources)
            setGenerationFinal(tempSources)
        })
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, context.getBuildingInfo])


    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (!generationCircuits) return
        if (settings.compareOn) {
            if (view !== 'generation' || !settings.compareStartEnd) return
            let sources = context.getBuildingInfo.e_sources
            sources.push(mainCircuit)
            const getSources = () => {
                return new Promise(async (resolve, reject) => {
                    var promises = []
                    sources.forEach((circuit) => {
                        let tempCircuit = JSON.parse(JSON.stringify(circuit));
                        const auth = context.getUser.token;
                        if (!circuit || (circuit.channels[0].category !== 'Building Distribution' && circuit.channels[0].sub_category === 'Utility')) return
                        promises.push(API.getElectricity(cancelToken, auth, settings.compareStartEnd[0], settings.compareStartEnd[1], tempCircuit.device_id, 60, 'kw', tempCircuit.circuit_name).then(res => {
                            res.data.circuit_name = tempCircuit.circuit_name
                            return res.data
                        }).catch(e => {
                            if (e.message === 'cancelling') return
                            console.log(e);
                        }))

                    });
                    let finalData = await Promise.all(promises)
                    resolve(finalData)
                })

            }
            getSources().then(sources => {
                let tempSources = {}
                let tempSourcesNoComp = {}
                const auth = context.getUser.token;
                API.getOpHoursRange(cancelToken, auth, context.getBuilding, settings.compareStartEnd[0], settings.compareStartEnd[1],).then(res => {
                    let businessHoursNoMinutes = res.data.map(e => e.split(":")[0])
                    GraphFuncs.getPeakKvaBusinessHours(cancelToken, auth, mainCircuit, settings.compareStartEnd[0], settings.compareStartEnd[1], res.data).then(dict => {
                        for (let source of sources) {
                            if (!source) continue
                            tempSources[source.circuit_name] = { kwh: { on: 0, off: 0 } }
                            tempSourcesNoComp[source.circuit_name] = { kwh: { on: 0, off: 0 } }
                            for (let timestamp in source.series) {
                                if (businessHoursNoMinutes.includes(timestamp.split(":")[0])) {
                                    tempSources[source.circuit_name].kwh.on += source.series[timestamp]
                                    tempSourcesNoComp[source.circuit_name].kwh.on += source.series[timestamp]
                                } else {
                                    tempSources[source.circuit_name].kwh.off += source.series[timestamp]
                                    tempSourcesNoComp[source.circuit_name].kwh.off += source.series[timestamp]
                                }
                            }
                        }
                        for (let source in tempSources) {
                            tempSources[source].kwh.on = generationCircuits[source].kwh.on - tempSources[source].kwh.on
                            tempSourcesNoComp[source].kwh.on = tempSources[source].kwh.on
                            tempSources[source].kwh.off = generationCircuits[source].kwh.off - tempSources[source].kwh.off
                            tempSourcesNoComp[source].kwh.off = tempSources[source].kwh.off
                        }
                        setGenerationFinal(tempSources)
                        setGenerationFinalNoSubt(tempSourcesNoComp)
                    }).catch(e => {
                        console.log(e)
                        if (e.message === 'cancelling') return
                        Swal.fire({
                            title: 'Error.',
                            text: 'Please try again later.',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    })
                }).catch(e => {
                    if (e.message === 'cancelling') return
                    console.log("Failed to get Op hours")
                    console.log(e);
                })
            })
        } else {
            setGenerationFinal(generationCircuits)
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, context.getBuildingInfo, settings.compareStartEnd, settings.compareOn, generationCircuits])


    const handleDateChange = (data) => {
        if (!data.value) return
        let startDate = data.value[0]
        let endDate = data.value[1]
        navigate(`/reports?building=${queryParams.get('building')}&start=${GlobalFuncs.formatDate(startDate, 'YYYY-MM-DD')}&end=${GlobalFuncs.formatDate(endDate, 'YYYY-MM-DD')}`)
    }

    const handleDateCompare = (data) => {
        if (!data.value) return
        let diff = GraphFuncs.getDifferenceInDays(settings.startEnd[0], settings.startEnd[1]) - 1
        let startDate = data.value
        let endDate = moment(startDate).add(diff, 'days');
        setTempSettings(prev => ({ ...prev, compareStartEnd: [moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")] }))
    }

    const handleCompareOff = () => {
        if (!settings.compareOn && !tempSettings.compareOn) return
        setTempSettings(prev => ({ ...prev, compareOn: false }))
        setSettings(prev => ({ ...prev, compareOn: false }))
    }

    const handleChangeUnit = (e) => {
        setUnit(e.currentTarget.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setComplete("")
        let newBuilding = queryParams.get('building')
        if (queryParams.size !== 0) {
            if (newBuilding !== context.getBuilding) {
                setShowBuildings(false)
                context.setBuilding(newBuilding)
                setTempSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3'].every(item => complete.includes(item)) ? true : false, compareOn: false, compareStartEnd: null }))
                // setTempSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3', '4'].every(item => complete.includes(item)) ? true : false, compareOn: false, compareStartEnd: null }))
                setSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3'].every(item => complete.includes(item)) ? true : false, compareOn: false, compareStartEnd: null, startEnd: tempSettings.startEnd }))
                // setSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3', '4'].every(item => complete.includes(item)) ? true : false, compareOn: false, compareStartEnd: null, startEnd: tempSettings.startEnd }))
            } else {
                setTempSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3'].every(item => complete.includes(item)) ? true : false, compareOn: tempSettings.compareOn }))
                // setTempSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3', '4'].every(item => complete.includes(item)) ? true : false, compareOn: tempSettings.compareOn }))
                setSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3'].every(item => complete.includes(item)) ? true : false, compareOn: tempSettings.compareOn, compareStartEnd: tempSettings.compareStartEnd ? tempSettings.compareStartEnd : null, startEnd: tempSettings.startEnd }))
                // setSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3', '4'].every(item => complete.includes(item)) ? true : false, compareOn: tempSettings.compareOn, compareStartEnd: tempSettings.compareStartEnd ? tempSettings.compareStartEnd : null, startEnd: tempSettings.startEnd }))
            }
            setSubmit(true)
        }
    }
    useEffect(() => {
        if (['1', '2', '3'].every(item => complete.includes(item))) {
            // if (['1', '2', '3', '4'].every(item => complete.includes(item))) {
            setSubmit(false)
            setTempSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3'].every(item => complete.includes(item)) ? true : false }))
            // setTempSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3', '4'].every(item => complete.includes(item)) ? true : false }))
            setSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3'].every(item => complete.includes(item)) ? true : false }))
            // setSettings(prev => ({ ...prev, compareAllowed: ['1', '2', '3', '4'].every(item => complete.includes(item)) ? true : false }))
        }
    }, [complete])

    return (
        <div>
            <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-between items-center rounded-md bg-[#FFF] shadow-md" >
                <div className='flex flex-nowrap gap-6 text-base font-bold'>
                    <Link to={`/reports?building=all`} className='active-reports-tab'>Buildings</Link>
                    <Link to={`/report?building=${queryParams.get('building')}`} className=''>Portfolio</Link>
                </div>
                <div className='flex flex-nowrap justify-end items-center w-9/12'>
                    {!context.getLaptop &&
                        <div className="flex flex-norap justify-end items-center">
                            <div className='flex justify-end items-center m-2'>
                                <DateRangePicker onChange={handleDateChange}
                                    start={tempSettings.startEnd[0]}
                                    end={tempSettings.startEnd[1]}
                                    compare={tempSettings.compareOn}
                                    onChangeCompare={handleDateCompare}
                                    compareStart={tempSettings.compareStartEnd} />
                            </div>
                            {!tempSettings.compareOn && tempSettings.compareAllowed && baseCircuits && <div className='flex flex-nowrap justify-center items-center m-2 cursor-pointer' onClick={() => setTempSettings(prev => ({ ...prev, compareOn: true }))}>
                                {GlobalSVG.pentagon()}
                                <p className='ml-2 font-medium text-[#587464]'>Compare Periods</p>
                            </div>}
                            {tempSettings.compareOn && tempSettings.compareAllowed && <div className='flex flex-nowrap justify-center items-center m-2 cursor-pointer' onClick={handleCompareOff}>
                                {GlobalSVG.closeBig()}
                                <p className='ml-2 font-medium text-[#587464]'>Stop Comparison</p>
                            </div>}
                        </div>
                    }
                    {!context.getLaptop &&
                        <div className="relative flex flex-nowrap justify-center items-center w-3/12">
                            <div onClick={() => setShowBuildings(!showBuildings)} className='p-1 border border-2 rounded-[8px] transparent  border-[#E5E7EB] h-[43px] cursor-pointer flex flex-nowrap justify-between items-center w-10/12'>
                                <p className='font-sans font-normal text-sans text-[#C1C1C1]'>{tempSettings.building ? tempSettings.building : "Select building"}</p>
                                {showBuildings && GlobalSVG.sortArrowDesc('#C1C1C1')}
                                {!showBuildings && GlobalSVG.sortArrowAsc('#C1C1C1')}
                            </div>
                            <div className={`${showBuildings ? 'block' : 'hidden'} z-10 h-[300px] absolute laptop:top-[160%] left-0 tablet:top-[100%] mobile:top-[100%] w-full flex items-start justify-center `}>
                                <div className='card buildings-card w-full laptop:h-full tablet:h-full mobile:h-full p-2 right-0'>
                                    <Accordion onSelect={() => null} compact={true} linkTo='reports' />
                                </div>
                            </div>
                        </div>
                    }
                    {!context.getLaptop && tempSettings.startEnd && tempSettings.building && tempSettings.building !== 'all' && !submit &&
                        <button onClick={handleSubmit} className='submit-button'>Generate Report</button>
                    }
                </div>
                {
                    context.getLaptop &&
                    <div className="input-group-text flex items-center whitespace-nowrap cursor-pointer" onClick={() => setOpenFilters(!openFilters)}>
                        {GlobalSVG.menuExport()}
                        <p className="text-sm text-normal leading-4">Settings</p>
                    </div>
                }
            </div >

            {
                context.getLaptop && openFilters &&
                <div className={`${openFilters ? 'tablet:w-[300px] mobile:w-full p-3' : "w-0"} card alert-sidebar transition-width duration-300 ease-in-out h-full fixed right-0 tablet:top-[10] mobile:top-[10] z-40 background-white`}>
                    <div className="mt-8 relative flex flex-nowrap justify-center items-center w-full gap-3">
                        <div onClick={() => setShowBuildings(!showBuildings)} className='p-1 border border-2 rounded-[8px] transparent  border-[#E5E7EB] h-[43px] cursor-pointer flex flex-nowrap justify-between items-center w-10/12'>
                            <p className='font-sans font-normal text-sans text-[#C1C1C1]'>{tempSettings.building ? tempSettings.building : "Select building"}</p>
                            {GlobalSVG.sortArrowDesc('#C1C1C1')}
                        </div>
                        {showBuildings &&
                            <div className='z-10 h-[300px] absolute laptop:top-[130%] left-0 tablet:top-[100%] mobile:top-[100%] w-parent flex items-start justify-center '>
                                <div className='card buildings-card w-full laptop:h-full tablet:h-full mobile:h-full p-2 right-0'>
                                    <Accordion onSelect={(e) => console.log(e)} compact={true} linkTo='reports' />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='w-full mt-8'>
                        <DateRangePicker onChange={handleDateChange}
                            start={tempSettings.startEnd[0]}
                            end={tempSettings.startEnd[1]}
                            compare={tempSettings.compareOn}
                            onChangeCompare={handleDateCompare}
                            compareStart={tempSettings.compareStartEnd}
                            wrap={true} />
                    </div>
                    {!tempSettings.compareOn && baseCircuits &&
                        <div className='flex flex-nowrap justify-center items-center m-2 mb-8 cursor-pointer' onClick={() => setTempSettings(prev => ({ ...prev, compareOn: true }))}>
                            {GlobalSVG.pentagon()}
                            <p className='ml-2 font-medium text-[#587464]'>Compare Periods</p>
                        </div>}
                    {tempSettings.compareOn && <div className='flex flex-nowrap justify-center items-center m-2 mt-4 mb-8 cursor-pointer' onClick={handleCompareOff}>
                        {GlobalSVG.closeBig()}
                        <p className='ml-2 font-medium text-[#587464]'>Stop Comparison</p>
                    </div>}
                    {tempSettings.startEnd[0] && tempSettings.building && tempSettings.building !== 'all' && !submit &&
                        <button onClick={handleSubmit} className='submit-button mx-auto mt-8'>Generate Report</button>
                    }
                </div>
            }

            <div className={`tablet:p-12 mobile:p-3 ${complete && ['1', '2', '3'].every(item => complete.includes(item)) ? 'block' : 'hidden'}`}>
                {/* <div className={`tablet:p-12 mobile:p-3 ${complete && ['1', '2', '3', '4'].every(item => complete.includes(item)) ? 'block' : 'hidden'}`}> */}
                {baseCircuits && finalCircuits &&
                    context.getBuildingInfo &&
                    <h2 className='laptop:text-3xl tablet:text-xl font-bold text-[#332D41]'>{context.getBuildingInfo.building}</h2>
                }
                {baseCircuits &&
                    <div className='flex flex-wrap laptop:flex-row mobile:flex-col justify-left gap-x-6'>
                        <div className='card laptop:w-[49%] tablet:w-full mobile:w-full min-w-[200px] tablet:p-8 mobile:p-2 items-center rounded-lg p-6 mt-8'>
                            <ReportPie1
                                circuits={baseCircuits}
                                generationCircuits={generationFinal}
                                view={view} filter={setFilteredCategories}
                                unit={unit}
                                totalPrice={totalPrice}
                                realPeak={realPeak} />
                        </div>
                        {compareCircuits && <div className='card laptop:w-[49%] tablet:w-full mobile:w-full min-w-[200px] tablet:p-8 mobile:p-2 items-center rounded-lg p-6 mt-8'>
                            <ReportPie1
                                circuits={compareCircuitsNoSubt}
                                generationCircuits={generationFinalNoSubt}
                                view={view}
                                filter={setFilteredCategories}
                                unit={unit}
                                compare={true}
                                totalPrice={totalPriceCompareNoDiff}
                                realPeak={realPeakCompare} />
                        </div>}
                        <div className='card laptop:w-[49%] tablet:w-full mobile:w-full min-w-[200px] tablet:p-8 mobile:p-2 items-center rounded-lg p-6 mt-8'>
                            <ReportPie2 circuits={finalCircuitsNoComp} totalPrice={totalPrice} />
                        </div>
                        {compareCircuits && <div className='card laptop:w-[49%] tablet:w-full mobile:w-full min-w-[200px] tablet:p-8 mobile:p-2 items-center rounded-lg p-6 mt-8'>
                            <ReportPie2 circuits={compareCircuitsNoSubt} compare={true} totalPrice={totalPriceCompareNoDiff} />
                        </div>}

                    </div>
                }
                {baseCircuits && finalCircuits &&
                    <ReportGraph1
                        setComplete={setComplete}
                        circuits={finalCircuits}
                        circuitOptions={Object.keys(baseCircuits)}
                        categoryOptions={categoriesOptions}
                        distributionOptions={buildingDistOptions}
                        unit={unit}
                        startEnd={settings.startEnd}
                        setUnit={handleChangeUnit}
                        selectCircuit={setFilteredCircuits}
                        selectCategory={setFilteredCategories}
                        selectDistribution={setFilteredDistribution}
                        selectedDistributions={filteredDistribution}
                        selectedCircuits={filteredCircuits}
                        selectedCategories={filteredCategories}
                        view={view}
                        realPeak={realPeak}
                        realPeakCompare={realPeakCompare}
                        totalPrice={totalPrice}
                        totalPriceCompare={totalPriceCompare}
                        setView={setView}
                        virtualTotal={virtualTotal}
                        virtualTotalCompare={virtualTotalCompare}
                        generationCircuits={generationFinal}
                        peakPf={peakPf}
                        peakPfCompare={peakPfCompare}
                        compareStartEnd={settings.compareStartEnd}
                        compare={settings.compareOn} />
                }
                {baseCircuits && finalCircuits &&
                    <ReportGraph2
                        setComplete={setComplete}
                        startEnd={settings.startEnd}
                        compareStartEnd={settings.compareStartEnd}
                        view={view}
                        virtualTotal={virtualTotal}
                        virtualTotalCompare={virtualTotalCompare}
                        realPeak={realPeak}
                        totalCoCompare={totalCoCompare}
                        totalCo={totalCo}
                        realPeakCompare={realPeakCompare}
                        setView={setView}
                        compare={settings.compareOn} />
                }
                {baseCircuits && finalCircuits &&
                    <div className='flex laptop:flex-col mobile:flex-col justify-left gap-x-6'>
                        <div className='card laptop:w-full tablet:w-full min-w-[300px] tablet:p-8 mobile:p-2 items-center rounded-lg p-6 mt-8'>
                            <BreakerGraph
                                setComplete={setComplete}
                                startEnd={settings.startEnd}
                                compare={settings.compareOn}
                                compareStartEnd={settings.compareStartEnd}
                                realPeak={realPeak}
                                realPeakCompare={realPeakCompare} />
                        </div>
                        {/* <div className='card laptop:w-full tablet:w-full min-w-[300px] tablet:p-8 mt-8 mobile:p-2 items-center rounded-lg p-6'>
                            <RuntimeGraph
                                setComplete={setComplete}
                                startEnd={settings.startEnd}
                                compare={settings.compareOn}
                                compareStartEnd={settings.compareStartEnd}
                            />
                        </div> */}
                    </div>
                }
            </div>
            {!submit && !['1', '2', '3'].every(item => complete.includes(item)) &&
                // {!submit && !['1', '2', '3', '4'].every(item => complete.includes(item)) &&
                <div className='h-[70vh]'>
                    <p className='p-8 text-center'>Please select a building and a date range to begin.</p>
                </div>
            }
            {context.getBuilding && context.getBuilding !== 'all' && submit && !['1', '2', '3', '4'].every(item => complete.includes(item)) &&
                <div className='h-[70vh]'>
                    <p className='pt-8 text-center text-[#C1C1C1]'>Generating your report. This might take a few minutes...</p>
                    <Loader />
                </div>
            }
        </div>
    );
};

export default Reports;