import { useContext, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import LoaderWheel from "../LoaderWheel";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Link } from "react-router-dom";

const ConservationActivities = (props) => {

    const context = useContext(AppContext);
    const statusColor = { 'Implemented': '#107048', 'In Progress': '#F9941E', 'Investigating': '#B8BBB9', 'On Hold': 'black', 'Not Implemented': '#F75D5F' }
    const [deleting, setDeleting] = useState([])

    const handleSort = (field) => {
        props.setSorting(e => e.hasOwnProperty(field) ? { [field]: !e[field] } : { [field]: true })
    }

    const handleDelete = (id) => {
        const auth = context.getUser.token;
        setDeleting(e => [...e, id])
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateActivity(auth, { status: "deleted" }, id).then(async res => {
                    props.setActivities(e => e.filter(item => item.id !== id));
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(e => e.filter(item => item !== id))
            }
        });
    }

    return (
        <div className="min-h-[200px]">
            <div className="flex flex-nowrap">
                {
                    props.filters.hasOwnProperty('startDate') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <div className='text-[12px] font-semibold'>Start: </div>
                        <DatePickerComponent
                            className='p-2'
                            onChange={(event) => props.setFilters(e => ({ ...e, startDate: event.value }))} />
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ startDate: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('endDate') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <div className='text-[12px] font-semibold'>End: </div>
                        <DatePickerComponent
                            className='p-2'
                            onChange={(event) => props.setFilters(e => ({ ...e, endDate: event.value }))} />
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ endDate: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('status') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='text-[12px] font-semibold h-[33px]' name="status" onChange={(event) => props.setFilters(e => ({ ...e, status: event.target.value }))}>
                            <option value={null}>Status</option>
                            <option value="Implemented">Implemented</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Investigating">Investigating</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Not Implemented">Not Implemented</option>
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ status: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
            </div>
            <div className='overflow-auto'>
                <div className='flex flex-nowrap justify-between p-4 my-2 w-full min-w-[1156px]'>
                    {
                        props.portfolio && <div className='flex flex-nowrap justify-start w-1/12 text-[12px] text-[#B8BBB9] font-semibold'>
                            Rank
                        </div>
                    }
                    {
                        props.portfolio && <div className='flex flex-nowrap justify-start w-2/12 text-[12px] text-[#B8BBB9] font-semibold'>
                            Building
                        </div>
                    }
                    {!props.portfolio && <div className='flex flex-nowrap justify-start w-2/12 text-[12px] text-[#B8BBB9] font-semibold'>
                        Action
                    </div>}
                    <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('startDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("startDate") ? 'text-[green]' : ''}`}>Start</span>
                        {props.sorting.hasOwnProperty("startDate") && props.sorting.startDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("startDate") && props.sorting.startDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    {!props.portfolio && <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('endDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("endDate") ? 'text-[green]' : ''}`}>End</span>
                        {props.sorting.hasOwnProperty("endDate") && props.sorting.endDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("endDate") && props.sorting.endDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>}
                    <div className='flex flex-nowrap justify-start items-center w-[130px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('kwhToDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("kwhToDate") ? 'text-[green]' : ''}`}>Electricity (kWh)</span>
                        {props.sorting.hasOwnProperty("kwhToDate") && props.sorting.kwhToDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("kwhToDate") && props.sorting.kwhToDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[130px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('kvaToDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("kvaToDate") ? 'text-[green]' : ''}`}>Electricity (kVA)</span>
                        {props.sorting.hasOwnProperty("kvaToDate") && props.sorting.kvaToDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("kvaToDate") && props.sorting.kvaToDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('waterToDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("waterToDate") ? 'text-[green]' : ''}`}>Water</span>
                        {props.sorting.hasOwnProperty("waterToDate") && props.sorting.waterToDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("waterToDate") && props.sorting.waterToDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('gasToDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("gasToDate") ? 'text-[green]' : ''}`}>Gas</span>
                        {props.sorting.hasOwnProperty("gasToDate") && props.sorting.gasToDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("gasToDate") && props.sorting.gasToDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('savings')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("savings") ? 'text-[green]' : ''}`}>Savings</span>
                        {props.sorting.hasOwnProperty("savings") && props.sorting.savings === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("savings") && props.sorting.savings === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    {!props.portfolio && <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold'>
                        <span className='mr-2'>Status</span>
                    </div>}
                    {!props.portfolio && <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold'>
                        <span className='mr-2'>Emissions</span>
                    </div>}
                    <div className='flex flex-nowrap justify-start w-[50px]'></div>
                </div>
                {
                    props.filteredActivities.map((e, ix) => (
                        <div key={'activitykey' + ix} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full min-w-[1156px] bg-[white] rounded-md '>
                            {!props.portfolio && <div className={`w-2/12 text-[12px] font-medium`}>{e.name ? e.name : " - "}</div>}
                            {props.portfolio && <div className={`w-1/12 text-[12px] font-medium`}>{e.rank}</div>}
                            {props.portfolio && <div className={`w-2/12 text-[12px] font-medium`}>{e.building ? e.building : " - "}</div>}
                            <div className={`w-1/12 text-[12px] font-medium ${e.startDate ? "" : "pl-[25px]"}`}>{e.startDate ? e.startDate : " - "}</div>
                            {!props.portfolio && <div className={`w-1/12 text-[12px] font-medium ${e.endDate ? "" : "pl-[25px]"}`}>{e.endDate ? e.endDate : " - "}</div>}
                            <div className='w-[130px] text-[12px] font-medium'>{e.kwhToDate && e.kwhToDate.toLocaleString()} kWh</div>
                            <div className='w-[130px] text-[12px] font-medium'>{e.kvaToDate && e.kvaToDate.toLocaleString()} kVA</div>
                            <div className='w-1/12 text-[12px] font-medium'>{e.waterToDate && e.waterToDate.toLocaleString()} L</div>
                            <div className='w-1/12 text-[12px] font-medium'>{e.gasToDate && e.gasToDate.toLocaleString()} ft³</div>
                            <div className='w-1/12 text-[12px] font-medium'>${e.savings && Math.round(e.savings).toLocaleString()}</div>
                            {!props.portfolio && <div className={`w-1/12 text-[12px] font-medium text-[${statusColor[e.status]}]`}>{e.status}</div>}
                            {!props.portfolio && <div className='w-1/12 text-[12px] font-medium'>{e.coToDate && e.coToDate.toLocaleString()} t</div>}
                            {!props.portfolio && <div className='cursor-pointer w-[25px]' onClick={() => props.setShowDetails(e.id)}>{GlobalSVG.edit2()}</div>}
                            {props.portfolio && <Link to={`/conservation?building=${e.building_id}`} target="_blank">
                                <div className="w-[50px] text-[12px] text-[#107048] px-2 py-1 rounded border border-[#117048]">OPEN</div>
                            </Link>}
                            {!props.portfolio && <div className='cursor-pointer w-[25px]'>
                                {context.getUserInfo.update_buildings_and_operators &&
                                    (deleting.includes(e.id) ?
                                        <LoaderWheel /> :
                                        <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete(e.id)}>{GlobalSVG.delete()}</div>)
                                }
                            </div>}

                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default ConservationActivities;