import React from 'react';
import { useState, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import SlideToggle from '../../components/SlideToggle';
import ImageSelector from '../../components/ImageSelector';

const AssetInfo = (props) => {

    const [magnify, setMagnify] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();

    const updateChannel = (e) => {
        const { name, value } = e.target;
        if (e.target.type === 'checkbox') {
            const { name, checked } = e.target;
            props.setCircuit(prevValue => {
                return {
                    ...prevValue,
                    [name]: checked
                }
            })
        } else if (name === 'delete_images') {
            let newValue = [value]
            if (props.circuit.delete_images) {
                newValue = [...props.circuit.delete_images]
                newValue.push(value)
            }
            props.setCircuit(prevValue => {
                return {
                    ...prevValue,
                    [name]: newValue
                }
            })
            props.setDevicePhotos(prev => prev.filter(item => item !== value))
        } else if (name === 'images') {
            const selectedImages = e.target.files[0];
            if (selectedImages) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    if ('images' in props.circuit) {
                      props.setCircuit(prevValue => {
                            return {
                                ...prevValue,
                                images: [...prevValue.images, base64ImageData]
                            }
                        })
                    } else {
                      props.setCircuit(prevValue => {
                            return {
                                ...prevValue,
                                images: [base64ImageData]
                            }
                        })
                    }
                };

                reader.readAsDataURL(selectedImages);
            }
        } else {
          props.setCircuit(prevValue => {
                return {
                    ...prevValue,
                    [name]: value
                }
            })
        }
    }

    const magnifyImage = (url) => {
        setMagnify(url)
    }


    const postChannel = async (e) => {
        props.setLoading(true)
        props.setGetPutPost('get')
        let payload
        payload = JSON.parse(JSON.stringify(props.circuit))

        for (let key in payload) {
            if (payload[key] === null) {
                delete payload[key];
            } else if (typeof payload[key] === "string") {
                payload[key] = String(payload[key]).trim()
            }
        }

        let intVals = ["operating_load_threshold", "average_operating_consumption"]
        for (let intVal of intVals) {
            if (intVal in payload) payload[intVal] = parseFloat(payload[intVal])
        }

        let toDel = ["device_id", "poles", "breaker_nr", "breaker_amps",
            "channel_name", "breaker_label", "utility_type", "input_unit", "virtual", "building", "building_id"]
        for (let item of toDel) {
            delete payload[item];
        }

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;

        try {
            await API.updateCircuit(cancelToken, auth, props.device, props.channelName, payload)
            navigate(`/circuit?device=${props.device}&circuit=${encodeURIComponent(props.channelName)}&tab=1`)
            props.setGetPutPost('get')
            props.setError(null)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('props.error' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.props.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    } else {
                        props.setError(err.response.data)
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Oops!',
                    text: "An props.error occurred, please contact us.",
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        } finally {
            props.setLoading(false)
        }
    }

    return (
        <div>
            <div className='device tablet:p-8 mobile:p-3'>
                {magnify &&
                    <div className="highest-z fixed top-0 left-0 w-screen h-screen flex flex-nowrap justify-center items-center" onClick={() => setMagnify(false)}>
                        <img onClick={() => setMagnify(false)} className='nav-magnified-image' src={magnify} alt="" />
                    </div>
                }
                <div className='card tablet:p-8 mobile:p-3 min-h-[80vh] mb-6'>
                    {
                        props.loading &&
                        <Loader />
                    }
                    <div className="flex flex-wrap items-start justify-between w-full">
                        {props.devicePhotos && props.circuit &&
                            <div className='laptop:w-1/4 tablet:w-full mobile:w-full'>
                                <ImageSelector images={props.devicePhotos} magnifyImage={magnifyImage} />
                            </div>}
                        {props.circuit &&
                            <div className='laptop:w-3/4 tablet:w-full mobile:w-full laptop:pl-8 tablet:pt-3 mobile:pt-3'>
                                <div className=''>
                                    <div className='input-border pb-8 items-center mb-6'>
                                        <div className='flex flex-wrap w-full items-center justify-between'>
                                            <input
                                                placeholder='Channel Name'
                                                className='text-xl outline-none hide-input-background laptop:w-1/2 tablet:w-1/2 mobile:w-1/2'
                                                type="text"
                                                name='channel_name'
                                                disabled={true}
                                                onChange={updateChannel}
                                                value={props.circuit.channel_name ? props.circuit.channel_name : ""} />
                                            <div className='laptop:w-1/4 tablet:w-1/4 mobile:w-1/2 flex flex-wrap items-center m'>
                                                <SlideToggle titleClass="input-label-color" disabled={true} setOptions={props.setCircuit} options={props.circuit} id="virtual" name="Virtual" />
                                            </div>
                                        </div>
                                    </div>
                                    {props.circuit && props.circuit.type === 'electric' &&
                                        <div className='flex flex-wrap'>
                                            <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                                <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                                    <label className='laptop:w-8/12 tablet:w-8/12 mobile:w-full text-base input-label-color' htmlFor="poles">Number of Phases</label>
                                                    <input
                                                        placeholder='Number of Phases'
                                                        className='laptop:w-3/12 tablet:w-3/12 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                        type="text"
                                                        name="poles"
                                                        value={props.circuit.poles ? props.circuit.poles : ""}
                                                        disabled={true}
                                                        onChange={updateChannel} />
                                                </div>
                                                <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                                    <label className='laptop:w-8/12 tablet:w-8/12 mobile:w-full text-base input-label-color' htmlFor="breaker_amps">Breaker Size</label>
                                                    <input
                                                        placeholder='Breaker Size'
                                                        className='laptop:w-3/12 tablet:w-3/12 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                        type="number"
                                                        name="breaker_amps"
                                                        value={props.circuit.breaker_amps ? props.circuit.breaker_amps : ""}
                                                        disabled={true}
                                                        onChange={updateChannel} />
                                                    {
                                                        props.error && props.error.breaker_amps &&
                                                        <p className='text-red text-xs'>{props.error.breaker_amps}</p>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {props.circuit && props.circuit.type === 'electric' &&
                                        <div className='flex flex-wrap'>
                                            <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                                <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                                    <label className='laptop:w-3/5 tablet:w-full mobile:w-full text-base input-label-color' htmlFor="breaker_label">Breaker or Disconnected Label</label>
                                                    <input
                                                        placeholder='Breaker Label'
                                                        className='laptop:w-2/5 tablet:w-full mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                        type="text"
                                                        name="breaker_label"
                                                        value={props.circuit.breaker_label ? props.circuit.breaker_label : ""}
                                                        disabled={true}
                                                        onChange={updateChannel} />
                                                    {
                                                        props.error && props.error.breaker_label &&
                                                        <p className='text-red text-xs'>{props.error.breaker_label}</p>

                                                    }
                                                </div>
                                                <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                                    <label className='laptop:w-3/5 tablet:w-full mobile:w-full text-base input-label-color' htmlFor="breaker_nr">Breaker # or Disconnected</label>
                                                    <input
                                                        placeholder='Breaker Number'
                                                        className='laptop:w-2/5 tablet:w-full mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                        type="number"
                                                        max="99"
                                                        name="breaker_nr"
                                                        value={props.circuit.breaker_nr ? props.circuit.breaker_nr : ""}
                                                        disabled={true}
                                                        onChange={updateChannel} />
                                                    {
                                                        props.error && props.error.breaker_nr &&
                                                        <p className='text-red text-xs'>{props.error.breaker_nr}</p>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {props.circuit.type === 'digital' &&
                                        <div className='flex flex-wrap'>
                                            <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>

                                                <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                                    <label className='laptop:w-4/12 mobile:w-full tablet:w-2/5 text-base input-label-color' >Utility Type</label>
                                                    <select
                                                        className='mobile:w-full tablet:w-7/12 mobile:p-2 mr-2 text-base input-color outline-none hide-input-background'
                                                        name="utility_type"
                                                        value={props.circuit.utility_type ? props.circuit.utility_type : ""}
                                                        disabled={true}
                                                        onChange={updateChannel}>
                                                        <option value="">No Selection</option>
                                                        <option value="Natural Gas">Natural Gas</option>
                                                        <option value="Water">Water</option>
                                                    </select>
                                                    {
                                                        props.error && props.error.utility_type &&
                                                        <p className='text-red text-xs'>{props.error.utility_type}</p>

                                                    }
                                                </div>
                                                <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                                    <label className='laptop:w-4/12 mobile:w-full tablet:w-2/5 text-base input-label-color' >Input Unit</label>
                                                    <select
                                                        className='mobile:w-full tablet:w-7/12 mobile:p-2 mr-2 text-base input-color outline-none hide-input-background'
                                                        name="input_unit"
                                                        value={props.circuit.input_unit ? props.circuit.input_unit : ""}
                                                        disabled={true}
                                                        onChange={updateChannel}>
                                                        <option value="">No Selection</option>
                                                        <option value="l.">L</option>
                                                        <option value="ft^3">ft³</option>
                                                    </select>
                                                    {
                                                        props.error && props.error.input_unit &&
                                                        <p className='text-red text-xs'>{props.error.input_unit}</p>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='flex flex-wrap'>
                                        <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full input-border flex flex-wrap items-center pt-3 pb-3 mb-6'>
                                            <div className='w-full flex flex-wrap items-center'>
                                                <label className='laptop:w-2/12 mobile:w-full tablet:w-2/5 text-base input-label-color' >Category</label>
                                                {/* <input
                                                placeholder='Category'
                                                className='laptop:w-9/12 p-1 tablet:w-3/5 mobile:p-2 mobile:w-full text-base input-color outline-none hide-input-background'
                                                type="text"
                                                name='category'
                                                onChange={updateChannel}
                                                disabled={props.getPutPost === 'get'}
                                                value={props.circuit.category ? props.circuit.category : ""} /> */}
                                                <select
                                                    className='p-1 laptop:w-9/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                                    name="category"
                                                    disabled={props.getPutPost === 'get'}
                                                    value={props.circuit.category ? props.circuit.category.trim() : ""}
                                                    onChange={updateChannel}>
                                                    <option value="null">No Selection</option>
                                                    <option value="Cooling">Cooling</option>
                                                    <option value="Ventilation">Ventilation</option>
                                                    <option value="House Distribution">House Distribution</option>
                                                    <option value="Lighting">Lighting</option>
                                                    <option value="Source">Source</option>
                                                    <option value="Slots & Gaming">Slots & Gaming</option>
                                                    <option value="Heating">Heating</option>
                                                    <option value="Risk Mitigation">Risk Mitigation</option>
                                                    <option value="Distribution Point">Distribution Point</option>
                                                    <option value="Riser Distribution">Riser Distribution</option>
                                                    <option value="Revenue Stream">Revenue Stream</option>
                                                    <option value="Refrigeration">Refrigeration</option>
                                                    <option value="Tenant Power">Tenant Power</option>
                                                    <option value="NGV Station">NGV Station</option>
                                                    <option value="Water Supply & Conditioning">Water Supply & Conditioning</option>
                                                    <option value="HVAC">HVAC</option>
                                                    <option value="Mixed Use">Mixed Use</option>
                                                    <option value="Plug Loads">Plug Loads</option>
                                                    <option value="Capacitor Bank">Capacitor Bank</option>
                                                    <option value=""></option>
                                                    <option value="Building Distribution">Building Distribution</option>
                                                    <option value="Industrial Equipment">Industrial Equipment</option>
                                                    <option value="Building Level">Building Level</option>
                                                </select>
                                                {
                                                    props.error && props.error.category &&
                                                    <p className='text-red text-xs'>{props.error.category}</p>

                                                }
                                            </div>
                                        </div>
                                        <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full input-border flex flex-wrap items-center pt-3 pb-3 mb-6'>
                                            <label className='laptop:w-4/12 mobile:w-full tablet:w-2/5 text-base input-label-color' >Device ID</label>
                                            <input
                                                placeholder='Device ID'
                                                className='mobile:w-full tablet:w-7/12 mobile:p-2 mr-2 text-base input-color outline-none hide-input-background'
                                                type="text"
                                                name="device_id"
                                                value={props.circuit.device_id ? props.circuit.device_id : ""}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {props.circuit &&
                        <div className='tablet:pt-8 mobile:pt-3'>
                            {props.circuit.type === 'electric' &&
                                <div className='flex flex-wrap'>
                                    <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-4/12 tablet:w-4/12 mobile:w-full text-base input-label-color' >Equipment Type</label>
                                            <input
                                                placeholder='Equipment Type'
                                                className='p-1 laptop:w-7/12 tablet:1/2 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                type="text"
                                                name='sub_category'
                                                onChange={updateChannel}
                                                disabled={props.getPutPost === 'get'}
                                                value={props.circuit.sub_category ? props.circuit.sub_category : ""} />
                                            {
                                                props.error && props.error.sub_category &&
                                                <p className='text-red text-xs'>{props.error.sub_category}</p>

                                            }
                                        </div>
                                    </div>
                                    <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-4/12 tablet:w-4/12 mobile:w-full  text-base input-label-color' >Equipment Group</label>
                                            <input
                                                placeholder='Equipment Group'
                                                className='p-1 laptop:w-7/12 tablet:1/2 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                type="text"
                                                name='equipment_group'
                                                onChange={updateChannel}
                                                disabled={props.getPutPost === 'get'}
                                                value={props.circuit.equipment_group ? props.circuit.equipment_group : ""} />
                                            {
                                                props.error && props.error.equipment_group &&
                                                <p className='text-red text-xs'>{props.error.equipment_group}</p>

                                            }
                                        </div>
                                    </div>
                                    <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-4/12 tablet:w-4/12 mobile:w-full text-base input-label-color' >Equipment Model</label>
                                            <input
                                                placeholder='Equipment Model'
                                                className='p-1 laptop:w-7/12 tablet:1/2 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                type="text"
                                                name='equipment_model'
                                                onChange={updateChannel}
                                                disabled={props.getPutPost === 'get'}
                                                value={props.circuit.equipment_model ? props.circuit.equipment_model : ""} />
                                            {
                                                props.error && props.error.equipment_model &&
                                                <p className='text-red text-xs'>{props.error.equipment_model}</p>

                                            }
                                        </div>
                                    </div>
                                </div>}
                            <div className='flex flex-wrap'>
                                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                    <div className='w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-2/12 tablet:w-4/12 mobile:w-full text-base input-label-color'>End Use Location</label>
                                        <input
                                            placeholder='End Use Location'
                                            className='p-1 laptop:w-7/12 tablet:1/2 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                            type="text"
                                            name='location'
                                            onChange={updateChannel}
                                            disabled={props.getPutPost === 'get'}
                                            value={props.circuit.location ? props.circuit.location : ""} />
                                        {
                                            props.error && props.error.location &&
                                            <p className='text-red text-xs'>{props.error.location}</p>

                                        }
                                    </div>
                                </div>
                            </div>
                            {props.circuit && props.circuit.type === 'electric' &&
                                <div className='flex flex-wrap'>
                                    <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>

                                        <div className='laptop:w-2/5 tablet:w-full mobile:w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-5/12 tablet:w-1/2 mobile:w-full text-base input-label-color' htmlFor="operating_load_threshold">Operating Load Threshold</label>
                                            <input
                                                placeholder='Operating Load Threshold'
                                                className='p-1 laptop:w-7/12 tablet:1/2 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                                type="number"
                                                name="operating_load_threshold"
                                                value={props.circuit.operating_load_threshold ? props.circuit.operating_load_threshold : ""}
                                                disabled={props.getPutPost === 'get'}
                                                onChange={updateChannel} />
                                            {
                                                props.error && props.error.operating_load_threshold &&
                                                <p className='text-red text-xs'>{props.error.operating_load_threshold}</p>
                                            }
                                        </div>
                                        <div className='laptop:w-2/5 tablet:w-full mobile:w-full flex flex-wrap items-center'>
                                            <label className='w-2/5 text-base input-label-color' htmlFor="average_operating_consumption">Average Operating Consumption</label>
                                            <input
                                                placeholder='Average Operating Consumption'
                                                className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                                type="number"
                                                name="average_operating_consumption"
                                                value={props.circuit.average_operating_consumption ? props.circuit.average_operating_consumption : ""}
                                                disabled={props.getPutPost === 'get'}
                                                onChange={updateChannel} />
                                            {
                                                props.error && props.error.average_operating_consumption &&
                                                <p className='text-red text-xs'>{props.error.average_operating_consumption}</p>
                                            }
                                        </div>
                                        <div className='laptop:w-1/5 tablet:w-full mobile:w-full flex flex-wrap items-center ml-[-20px]'>
                                            <SlideToggle titleClass="input-label-color" disabled={props.getPutPost === 'get'} setOptions={props.setCircuit} options={props.circuit} id="natural_gas_user" name="Natural Gas User" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='flex flex-wrap'>
                                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                    <div className='w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-2/12 tablet:w-2/12 mobile:w-full text-base input-label-color' htmlFor="notes">Notes</label>
                                        <input
                                            placeholder='Notes'
                                            className='p-1 laptop:w-7/12 tablet:w-9/12 mobile:w-full mobile:p-2 text-base input-color outline-none hide-input-background'
                                            type="text"
                                            name="notes"
                                            value={props.circuit.notes ? props.circuit.notes : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.notes &&
                                            <p className='text-red text-xs'>{props.error.notes}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                props.getPutPost !== 'get' && <div className='flex flex-wrap'>
                                    <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                        <label className='w-1/12 text-base input-label-color'>Images</label>
                                        <div className='w-10/12 flex flex-wrap items-center'>

                                            {props.getPutPost !== 'get' &&
                                                <input
                                                    className='mobile:w-full tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                    type="file"
                                                    name="images"
                                                    onChange={updateChannel} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                props.getPutPost !== 'get' && <div className='flex flex-wrap'>
                                    <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                        {props.devicePhotos.map((e, idx) => (
                                            <div className='relative'>
                                                <img className='w-16 h-16 m-2' key={e + 'image'} src={e} alt="" />
                                                <button
                                                    className='absolute top-0 right-0 rounded-full bg-[grey] text-[white] cursor-pointer text-sm font-light p-2 w-5 h-5 flex items-center justify-center'
                                                    type="button"
                                                    name="delete_images"
                                                    value={e}
                                                    onClick={updateChannel}
                                                >&#x2716;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        ['get'].includes(props.getPutPost) && props.circuit &&
                        <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                            <p className='cursor-pointer capitalize' onClick={() => props.setGetPutPost('put')}>
                                Edit Circuit
                            </p>
                        </div>
                    }
                    <div className="flex flex-nowrap items-center justify-end w-full">
                        {
                            ['put', 'post'].includes(props.getPutPost) && !props.loading &&
                            <div className='mr-8 text-green3 cursor-pointer' onClick={() => props.setGetPutPost('get')}>
                                Cancel
                            </div>
                        }
                        {
                            props.getPutPost !== 'get' && !props.loading &&
                            <button className='submit-button' onClick={postChannel}>
                                Save
                            </button>
                        }
                    </div>
                </div>
            </div >
        </div>
    )
}


export default AssetInfo;