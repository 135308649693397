import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';

const Client = () => {

    const location = useLocation();
    const [client, setClient] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [clientData, setClientData] = useState({ client_id: "", client_name: "", logo: "", address: "", address2: "", city: "", province: "", postalcode: "", country: "" })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_clients_list", "create_clients", "update_clients"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/users')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/client/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setClient(queryParams.get('client'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        if (getPutPost === 'get' && client !== null) {
            setLoading(true)
            const cancelToken = API.cancelToken();
            const auth = context.getUser.token;
            API.getClient(cancelToken, auth, client).then(res => {
                setClientData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if ('error' in err.response.data) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }).finally(f => {
                setLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, client])

    const updateClient = (e) => {
        const { name, value } = e.target;
        if (name === 'logo') {
            const selectedImage = e.target.files[0];
            if (selectedImage) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    setClientData(prevValue => {
                        return {
                            ...prevValue,
                            logo: base64ImageData
                        }
                    })
                };

                reader.readAsDataURL(selectedImage);
            }
        } else {
            setClientData(prevValue => {
                return {
                    ...prevValue,
                    [name]: value.trim()
                }
            })
        }
    }

    const postClient = async (e) => {
        if (clientData.client_name === "") {
            setError({ client_name: "Missing Value" })
        } else {
            let payload = JSON.parse(JSON.stringify(clientData))
            delete payload['created_at'];
            delete payload['updated_at'];
            if (getPutPost === 'put') {
                delete payload['client_id'];
            }
            setLoading(true)
            const cancelToken = API.cancelToken();
            const auth = context.getUser.token;
            for (let key in payload) {
                if (payload[key] === null || String(payload[key]).trim() === "") {
                    delete payload[key];
                }
            }
            if (payload['logo'] && payload['logo'].includes('https')) {
                delete payload['logo'];
            }
            try {
                if (getPutPost === 'post') {
                    await API.postClient(cancelToken, auth, payload)
                    navigate(`/client?client=${clientData.client_id}`)
                } else {
                    await API.putClient(cancelToken, auth, client, payload)
                    setGetPutPost('get')
                    setError(null)
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if ('error' in err.response.data) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/clients">
                    Clients
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? clientData.client_name : "Add Client"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='input-border pb-8 items-center mb-6'>
                    <input
                        placeholder='Client Name'
                        className='text-3xl p-2 outline-none hide-input-background laptop:w-1/2 tablet:w-full mobile:w-full'
                        type="text"
                        name='client_name'
                        disabled={getPutPost === 'get'}
                        defaultValue={clientData.client_name ? clientData.client_name : ""}
                        onChange={updateClient} />
                    {
                        error && error.client_name &&
                        <p className='text-red text-xs p-2'>{error.client_name}</p>

                    }
                </div>
                {
                    loading &&
                    <Loader />
                }
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="client_id">Client ID</label>
                            <input
                                placeholder='Client ID'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="client_id" defaultValue={clientData.client_id ? clientData.client_id : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateClient} />
                            {
                                error && error.client_id &&
                                <p className='text-red text-xs'>{error.client_id}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="logo">Client Logo</label>
                            {
                                getPutPost === 'get' &&
                                <img className='w-20' src={clientData.logo ? clientData.logo : require('../../assets/images/placeholder-image.png')} alt="" />
                            }
                            {getPutPost !== 'get' &&
                                <input
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="file"
                                    name="logo"
                                    onChange={updateClient} />
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="address">Address</label>
                            <input
                                placeholder='Address'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="address" defaultValue={clientData.address ? clientData.address : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateClient} />
                            {
                                error && error.address &&
                                <p className='text-red text-xs'>{error.address}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="address2">Address 2</label>
                            <input
                                placeholder='Address 2'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="address2" defaultValue={clientData.address2 ? clientData.address2 : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateClient} />
                            {
                                error && error.address2 &&
                                <p className='text-red text-xs'>{error.address2}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="city">City</label>
                            <input
                                placeholder='City'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="city" defaultValue={clientData.city ? clientData.city : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateClient} />
                            {
                                error && error.city &&
                                <p className='text-red text-xs'>{error.city}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="province">Province</label>
                            <select
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                name="province"
                                value={clientData.province ? clientData.province : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateClient}
                            >
                                <option value="">Select</option>
                                <option value="AB">AB</option>
                                <option value="BC">BC</option>
                                <option value="MB">MB</option>
                                <option value="NB">NB</option>
                                <option value="NL">NL</option>
                                <option value="NT">NL</option>
                                <option value="NS">NS</option>
                                <option value="NU">NU</option>
                                <option value="ON">ON</option>
                                <option value="PE">PE</option>
                                <option value="QC">QC</option>
                                <option value="SK">SK</option>
                                <option value="YT">YT</option>
                            </select>
                            {
                                error && error.province &&
                                <p className='text-red text-xs'>{error.province}</p>

                            }
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="postalcode">Postal Code</label>
                                <input
                                    placeholder='Postal Code'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="postalcode" defaultValue={clientData.postalcode ? clientData.postalcode : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateClient} />
                                {
                                    error && error.postalcode &&
                                    <p className='text-red text-xs'>{error.postalcode}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="postalcode">Country</label>
                                    <input
                                        placeholder='Country'
                                        className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="text"
                                        name="country" defaultValue={clientData.country ? clientData.country : ""}
                                        disabled={getPutPost === 'get'}
                                        onChange={updateClient} />
                                    {
                                        error && error.country &&
                                        <p className='text-red text-xs'>{error.country}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Client
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && clientData.client !== "" && !loading && <button className='submit-button' onClick={postClient}>
                            Save
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Client;