import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import CostValueE from '../../components/CostValueE';

const EModel = () => {

    const location = useLocation();
    const [model, setModel] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [modelData, setModelData] = useState({ model_name: "", first_block_kwh: "", first_block_kva: "", values: [] })
    const [error, setError] = useState(null)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addValue, setAddValue] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_utilities_list", "create_utilities", "update_utilities"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/roles')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/model/create/electricity') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setModel(queryParams.get('model'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (getPutPost === 'get' && model !== null) {
            setLoading(true)
            const auth = context.getUser.token;
            API.getModelE(cancelToken, auth, model).then(res => {
                setModelData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && 'error' in err.response.data) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, model, forceUpdate])

    const updateModel = (e) => {
        const { name, value } = e.target;
        setModelData(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    const postModel = async (e) => {
        if (modelData.model_name === "") {
            setError({ model_name: "Missing Value" })
        } else {
            setLoading(true)
            const cancelToken = API.cancelToken();
            const auth = context.getUser.token;
            let request = null
            try {
                if (getPutPost === 'post') {
                    request = await API.postModelE(cancelToken, auth, modelData)
                    navigate(`/model/electricity?model=${request.data}`)
                } else {
                    request = await API.putModelE(cancelToken, auth, modelData)
                    setGetPutPost('get')
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    if ('general' in err.response.data || 'message' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.general,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    } else {
                        setError(err.response.data)
                    }
                }
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/costsfactors/electricity">
                    Electric Cost Models
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? modelData.model_name : "Add Cost Model"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='input-border pb-8 items-center'>
                    <input
                        placeholder='Model Name'
                        className='text-3xl p-2 outline-none hide-input-background'
                        type="text"
                        name='model_name'
                        disabled={getPutPost === 'get'}
                        defaultValue={modelData.model_name ? modelData.model_name : ""}
                        onChange={updateModel} />
                    {
                        error && error.model_name &&
                        <p className='text-red text-xs p-2'>{error.model_name}</p>

                    }
                </div>
                {
                    loading &&
                    <Loader />
                }
                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3'>
                    <div className='w-full flex flex-wrap items-center'>
                        <label className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color' htmlFor="first_block_kwh">First Range kWh</label>
                        <input
                            placeholder='0'
                            className='mobile:w-3/5 tablet:w-1/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                            type="number"
                            step="1"
                            name="first_block_kwh" defaultValue={modelData.first_block_kwh ? modelData.first_block_kwh : ""}
                            disabled={getPutPost === 'get'}
                            onChange={updateModel} />
                        <span className='text-base input-label-color'>kWh</span>
                        {
                            error && error.first_block_kwh &&
                            <p className='text-red text-xs'>{error.first_block_kwh}</p>

                        }
                    </div>
                </div>
                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                    <div className='w-full flex flex-wrap items-center'>
                        <label className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color' htmlFor="first_block_kva">First Range kVA</label>
                        <input
                            placeholder='0'
                            className='mobile:w-3/5 tablet:w-1/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                            type="number"
                            step="1"
                            disabled={getPutPost === 'get'}
                            name="first_block_kva" defaultValue={modelData.first_block_kva ? modelData.first_block_kva : ""}
                            onChange={updateModel} />
                        <span className='text-base input-label-color'>kVA</span>
                        {
                            error && error.first_block_kva &&
                            <p className='text-red text-xs'>{error.first_block_kva}</p>

                        }
                    </div>
                </div>
                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Model
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && modelData.model_name !== "" && !loading && <button className='submit-button' onClick={postModel}>
                            Save
                        </button>
                    }
                </div>
                {
                    ['get', 'put'].includes(getPutPost) &&
                    <div className='mt-4'>
                        <p className='text-2xl'>Model Values</p>
                        {
                            modelData.values.map((e, index) => (
                                <CostValueE key={index + "costevalue"} setUpdate={setForceUpdate} update={forceUpdate} value={e} />
                            ))
                        }
                        {
                            addValue &&
                            <CostValueE edit={true} setUpdate={setForceUpdate} open={setAddValue} update={forceUpdate} value={null} modelId={model} />
                        }

                        {addValue ?
                            <div className='w-full border-light-green block mt-8 text-sm text-green4 font-medium cursor-pointer' onClick={() => setAddValue(false)}>
                                Cancel
                            </div>
                            :
                            <div className='w-full border-light-green block mt-8 text-sm text-green4 font-medium cursor-pointer' onClick={() => setAddValue(true)}>
                                <span className='text-lg mr-2'>+</span>
                                Add new value
                            </div>}
                    </div>
                }
            </div>
        </div>
    );
};

export default EModel;