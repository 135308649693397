import API from '../../utils/API';
import DateRangePicker from '../DateRangePicker';
import SlideToggle from '../SlideToggle';
import UnitToggle from '../UnitToggle';
import './style.css'
import GlobalFuncs from '../../utils/GlobalFuncs';
import GraphFuncs from '../../utils/GraphFuncs';
import { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import GlobalSVG from '../../utils/GlobalSVG';
import DistribuitionTable from '../DistributionTable';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import moment from 'moment-timezone';

export default function MainGraph(props) {

    require('highcharts/modules/exporting')(Highcharts);
    highchartsExporting(Highcharts);
    highchartsExportData(Highcharts);

    const [unitTemp, setUnitTemp] = useState('c')
    const [visible, setVisible] = useState({
        kw: true, kva: false, kvar: false, a: false, water: false, gas: false, co: false, outdoortemp: false,
        pf: false, vll: false, vln: false, phases: false, unblv: false, unbli: false, thdva: false,
        thdv: false, othdv: false, ethdv: false, thffv: false, cfv: false, kfi: false, ethdi: false,
        othdi: false, thdi: false, harmonics: false
    })
    const [startEnd, setStartEnd] = useState([moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [compareStartEnd, setCompareStartEnd] = useState(null)
    const chartRef = useRef()
    const [options, setOptions] = useState({
        chart: {
            type: "areaspline",
            zoomType: 'x',
        },
        credits: {
            enabled: false,
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen', 'separator', 'downloadPNG',
                        'downloadPDF', 'downloadCSV'
                    ]
                },
            },
            sourceWidth: 1500,
            sourceHeight: 400,
            scale: 1,
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'right',
                verticalAlign: 'bottom',
                y: 0
            }
        },
        navigator: {
            enabled: false
        },
        tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderWidth: 1,
            borderColor: '#ccc',
            formatter: function (data) {
                let tooltipContent = '<div style="border-bottom: 1px solid #ccc;margin-bottom: 5px; padding-bottom: 5px;font-size:12px">Time Stamp: <b>' + Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) + '</b></div>';

                // Loop through each series in the hovered category
                this.points.forEach(function (point) {
                    let tempName = point.series.name.split(" in ")[0]
                    let tempUnit = point.series.name.split(" in ")[1] || ""
                    tooltipContent += '<div style="color:' + point.color + ';font-size: 12px; padding-bottom: 5px">' + tempName + ': <b>' + point.y.toLocaleString() + " " + tempUnit + '</b></div>';
                });

                return tooltipContent;
            },
            positioner: function (boxWidth, boxHeight, point) {
                return { x: 50, y: 0 }; // Adjust the coordinates as needed
            },

        },
        legend: {
            enabled: true,
            align: 'left',
            layout: 'horizontal',
            verticalAlign: 'bottom',
            itemMarginBottom: 0,
            itemMarginTop: 0,
        },
        rangeSelector: {
            enabled: false
        },
        backgroundColor: "transparent",
        series: [],
        plotOptions: {
            series: {
                stacking: "overlap",
                dataGrouping: {
                    enabled: false
                },
                lineWidth: 1.5,
                color: "#cccccc",
            }
        },
        accessibility: {
            enabled: false
        },
        yAxis: [


        ],
        xAxis: [{
            type: 'datetime',
            // tickInterval: 24 * 3600 * 1000, // one day
            minTickInterval: 60 * 60 * 1000,
            minRange: 60 * 1000,
            tickPixelInterval: 100,
            ordinal: false,
            crosshair: true,
            visible: true,
            min: null,
            max: null,
            // labels: {
            //     formatter: function () {
            //         return Highcharts.dateFormat('%d %b %y', this.value)
            //     }
            // },

            internalType: "main",
            dateTimeLabelFormats: {
                second: '%d %b %Y <br/> %H:%M:%S %P',
            },
            zoomEnabled: true
        }],
    })
    const [compareOn, setCompareOn] = useState(false)
    const dUnits = { 'l.': "L", "ft^3": "ft³" }
    const [graphSeries, setGraphSeries] = useState([])
    const [graphData, setGraphData] = useState([])
    const [harmonicsUnit, setHarmonicsUnit] = useState(null)
    const [hightContrast, setHighContrast] = useState(true)
    const prevStartEnd = useRef()
    const dChange = useRef()
    const [loading, setLoading] = useState(true)
    const [dDetail, setDDetail] = useState(15)
    const [open, setOpen] = useState(true)
    const context = useContext(AppContext);
    const fixPeriod = useRef()
    const block = useRef(false)
    let hcColors = ['#58826A', '#DD6B20', '#A22', '#17AEEE', '#FFD233', '#BFDA45', '#F75D5F', '#A6ABAD']
    let colors = ["#58826A", "#619E7B", "#75B08E", "#92C4A7", "#B2D8C3", "#377745", "#D2CECE", "#638971"]

    const getDetail = () => {
        let diff = GraphFuncs.getDifferenceInDays(startEnd[0], startEnd[1])
        let detail = 1
        if (diff > 1 && diff < 31) detail = 15
        else if (diff >= 30 && diff <= 89) detail = 60
        else if (diff >= 90) detail = 'day'
        return detail
    }

    let unitDictionnary = { pf: " (PF)", kw: [60, "day"].includes(getDetail()) ? "in kWh" : "in kW", vll: "in V (L-L)", vln: "in V (L-N)", kva: [60, "day"].includes(getDetail()) ? "in kVAh" : "in kVA", a: "in A", kvar: [60, "day"].includes(getDetail()) ? "in kVArh" : "in kVAr", L: "in L", "ft³": "in ft³", co: "in t CO₂e" }

    // Handle change of circuit selection, and change of visibility
    useEffect(() => {
        setLoading(true)
        let tempDetail = getDetail()
        if (tempDetail > dDetail || (tempDetail === 'day' && dDetail !== 'day')) {
            setDDetail(tempDetail)
            return
        }
        block.current = true
        if (!props.circuits) return
        if (startEnd !== prevStartEnd.current) {
            prevStartEnd.current = startEnd
            dChange.current = true
            if (compareOn) {
                setCompareOn(false)
                setCompareStartEnd(null)
                return
            }
        }
        let tempGraph = checkCircuits(props.circuits, dChange.current, false)
        dChange.current = false

        setGraphSeries(tempGraph)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startEnd, compareOn, props.circuits, dDetail, visible, hightContrast, unitTemp])

    useEffect(() => {
        if (!props.circuits || !compareStartEnd) return
        setLoading(true)
        let tempGraph = checkCircuits(props.circuits, false, true)
        setGraphSeries(tempGraph)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareStartEnd])


    const checkCircuits = (circuits, dateChange = false, compareChange = false) => {
        let tempGraph = []
        let units = ['kw', 'kva', 'kvar', 'pf', 'a', 'vll', 'vln', 'unblv', 'unbli', 'thdva', 'thdv', 'othdv', 'ethdv', 'thffv', 'cfv', 'kfi', 'ethdi', 'othdi', 'thdi', 'co']
        let hUnits = ['unblv', 'unbli', 'thdva', 'thdv', 'othdv', 'ethdv', 'thffv', 'cfv', 'kfi', 'ethdi', 'othdi', 'thdi']
        for (let circuit of circuits) {
            if (!circuit) continue
            if (circuit.type === 'electricity') {
                for (let unit of units) {
                    if ((visible[unit] && unit !== 'co') || (visible[unit] && unit === 'co' && visible.kw)) {
                        if (!circuit) continue
                        let tempC = graphData.find((obj) => obj && obj.circuit_name === circuit.circuit_name && !obj.compare && obj.unit === unit);
                        // If it's not a date change, look for existing series and add them to new list
                        if (tempC && !dateChange) {
                            tempGraph.push(JSON.parse(JSON.stringify(tempC)))
                        } else {
                            // If not, clone circuit and add missing fields
                            let tempCircuit = JSON.parse(JSON.stringify(circuit))
                            tempCircuit.unit = unit
                            tempCircuit.startDate = startEnd[0]
                            tempCircuit.endDate = startEnd[1]
                            tempCircuit.compare = false
                            tempCircuit.detail = getDetail()
                            tempCircuit.type = hUnits.includes(unit) ? 'harmonics' : 'electricity'
                            for (let channel of tempCircuit.channels) {
                                channel.data = []
                            }
                            tempGraph.push(tempCircuit)
                        }
                        // If compare is on and not a date change, add comparisons
                        if (compareStartEnd !== null && !dateChange) {
                            // Look for existing circuits series and add them to new list
                            if (!circuit) continue
                            let tempC = graphData.find((obj) => obj && obj.circuit_name === circuit.circuit_name && obj.compare && obj.unit === unit);
                            if (tempC && !compareChange) {
                                tempGraph.push({ ...tempC })
                            } else {
                                // If not, clone circuit and add missing fields
                                let tempCircuit = JSON.parse(JSON.stringify(circuit))
                                tempCircuit.unit = unit
                                tempCircuit.startDate = compareStartEnd[0]
                                tempCircuit.endDate = compareStartEnd[1]
                                tempCircuit.compare = true
                                tempCircuit.detail = getDetail()
                                tempCircuit.type = hUnits.includes(unit) ? 'harmonics' : 'electricity'
                                for (let channel of tempCircuit.channels) {
                                    channel.data = []
                                }
                                tempGraph.push(tempCircuit)
                            }
                        }
                    }
                }
            } else {
                if (props.dist && !visible[circuit.type]) {
                    setVisible(e => ({ ...e, [circuit.type]: true }))
                    return
                }
                if (visible[circuit.type]) {
                    let tempC = graphData.find((obj) => obj && obj.channel_name === circuit.channel_name && obj.detail === dDetail && obj.unit === circuit.unit && !obj.compare);
                    // If it's not a date change, look for existing series and add them to new list
                    if (tempC && !dateChange) {
                        tempGraph.push({ ...tempC })
                    } else {
                        // If not, clone circuit and add missing fields
                        let tempCircuit = JSON.parse(JSON.stringify(circuit))
                        tempCircuit.unit = dUnits[circuit.channel.input_unit]
                        tempCircuit.startDate = startEnd[0]
                        tempCircuit.endDate = startEnd[1]
                        tempCircuit.compare = false
                        tempCircuit.detail = dDetail
                        tempGraph.push(tempCircuit)
                    }
                    // If compare is on and not a date change, add comparisons
                    if (compareStartEnd !== null && !dateChange) {
                        // Look for existing circuits series and add them to new list
                        let tempC = graphData.find((obj) => obj && obj.channel_name === circuit.channel_name && obj.detail === dDetail && obj.unit === circuit.unit && obj.compare);
                        if (tempC && !compareChange) {
                            tempGraph.push({ ...tempC })
                        } else {
                            // If not, clone circuit and add missing fields
                            let tempCircuit = JSON.parse(JSON.stringify(circuit))
                            tempCircuit.unit = dUnits[circuit.channel.input_unit]
                            tempCircuit.startDate = compareStartEnd[0]
                            tempCircuit.endDate = compareStartEnd[1]
                            tempCircuit.compare = true
                            tempCircuit.detail = dDetail
                            tempGraph.push(tempCircuit)
                        }
                    }
                }
                if (circuit.type === 'gas' && visible[circuit.type] && visible['co']) {
                    let tempC = graphData.find((obj) => obj && obj.channel_name === circuit.channel_name && obj.unit === circuit.unit && obj.detail === dDetail && !obj.compare);
                    // If it's not a date change, look for existing series and add them to new list
                    if (tempC && !dateChange) {
                        tempGraph.push({ ...tempC })
                    } else {
                        // If not, clone circuit and add missing fields
                        let tempCircuit = JSON.parse(JSON.stringify(circuit))
                        tempCircuit.unit = 'co'
                        tempCircuit.startDate = startEnd[0]
                        tempCircuit.endDate = startEnd[1]
                        tempCircuit.compare = false
                        tempCircuit.detail = dDetail
                        tempGraph.push(tempCircuit)
                    }
                    // If compare is on and not a date change, add comparisons
                    if (compareStartEnd !== null && !dateChange) {
                        // Look for existing circuits series and add them to new list
                        let tempC = graphData.find((obj) => obj && obj.channel_name === circuit.channel_name && obj.unit === circuit.unit && obj.detail === dDetail && obj.compare);
                        if (tempC && !compareChange) {
                            tempGraph.push({ ...tempC })
                        } else {
                            // If not, clone circuit and add missing fields
                            let tempCircuit = JSON.parse(JSON.stringify(circuit))
                            tempCircuit.unit = 'co'
                            tempCircuit.startDate = compareStartEnd[0]
                            tempCircuit.endDate = compareStartEnd[1]
                            tempCircuit.compare = true
                            tempCircuit.detail = dDetail
                            tempGraph.push(tempCircuit)
                        }
                    }
                }
            }
        }
        if (visible.outdoortemp) {
            let tempCircuit = {}
            tempCircuit.unit = unitTemp
            tempCircuit.startDate = startEnd[0]
            tempCircuit.endDate = startEnd[1]
            tempCircuit.compare = false
            tempCircuit.type = 'temperature'
            tempGraph.push(tempCircuit)
            // If compare is on and not a date change, add comparisons
            if (compareStartEnd !== null && !dateChange) {
                let tempCircuit = {}
                tempCircuit.unit = unitTemp
                tempCircuit.startDate = compareStartEnd[0]
                tempCircuit.endDate = compareStartEnd[1]
                tempCircuit.compare = true
                tempCircuit.type = 'temperature'
                tempGraph.push(tempCircuit)
            }
        }
        return tempGraph
    }

    useEffect(() => {
        if (!context.getBuildingInfo || !graphSeries) return
        if (graphSeries.length === 0) return
        const cancelToken = API.cancelToken();
        get_mixed(cancelToken, graphSeries).then(data => {
            setGraphData(data)
        })
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphSeries, context.getBuildingInfo])

    const get_mixed = async (cancelToken, circuits) => {
        return new Promise(async (resolve, reject) => {
            var promises = []
            circuits.forEach((circuit) => {
                if (circuit.series) {
                    promises.push(new Promise(resolve => resolve(circuit)))
                    return
                }
                let tempCircuit = JSON.parse(JSON.stringify(circuit));
                const auth = context.getUser.token;
                if (!circuit) return
                if (circuit.unit === 'co') {
                    if (circuit.type === 'electricity') {
                        promises.push(API.getPriceCoElectric(cancelToken, auth, tempCircuit.startDate, tempCircuit.endDate, tempCircuit.circuit_name, tempCircuit.device_id, getDetail() < 60 ? 60 : getDetail()).then(res => {
                            circuit.series = res.data.series
                            circuit.channels = res.data.channels
                            return circuit
                        }).catch(e => {
                            if (e.message === 'cancelling') return
                            console.log(e);
                        }))
                    } else if (circuit.type === 'gas') {
                        promises.push(API.getPriceCoGas(cancelToken, auth, tempCircuit.startDate, tempCircuit.endDate, tempCircuit.channel_name, tempCircuit.device_id, dDetail).then(res => {
                            circuit.series = res.data.series
                            return circuit
                        }).catch(e => {
                            if (e.message === 'cancelling') return
                            console.log(e);
                        }))
                    }
                } else if (circuit.type === 'electricity') {
                    promises.push(API.getElectricity(cancelToken, auth, tempCircuit.startDate, tempCircuit.endDate, tempCircuit.device_id, getDetail(), tempCircuit.unit, tempCircuit.circuit_name).then(res => {
                        res.data.startDate = tempCircuit.startDate
                        res.data.endDate = tempCircuit.endDate
                        res.data.type = tempCircuit.type
                        res.data.unit = tempCircuit.unit
                        res.data.compare = tempCircuit.compare
                        res.data.circuit_name = tempCircuit.circuit_name
                        return res.data
                    }).catch(e => {
                        if (e.message === 'cancelling') return
                        console.log(e);
                    }))
                } else if (circuit.type === 'harmonics') {
                    promises.push(API.getHarmonics(cancelToken, auth, tempCircuit.startDate, tempCircuit.endDate, tempCircuit.device_id, getDetail(), tempCircuit.unit, tempCircuit.circuit_name).then(res => {
                        res.data.startDate = tempCircuit.startDate
                        res.data.endDate = tempCircuit.endDate
                        res.data.type = tempCircuit.type
                        res.data.unit = tempCircuit.unit
                        res.data.compare = tempCircuit.compare
                        res.data.circuit_name = tempCircuit.circuit_name
                        return res.data
                    }).catch(e => {
                        if (e.message === 'cancelling') return
                        console.log(e);
                    }))
                } else if (circuit.type === 'temperature') {
                    let frequency = getDetail() === 'day' ? 'daily' : 'hourly'
                    let tempEnd = tempCircuit.endDate
                    let tempDate = tempCircuit.endDate.split("-")
                    let tempEndDate = new Date(tempDate[0], tempDate[1], tempDate[2], 0, 0, 0, 0)
                    let today = new Date()
                    if (tempEndDate > today) tempEnd = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
                    promises.push(API.getTemperature(cancelToken, auth, context.getBuildingInfo.building_id, frequency, tempCircuit.startDate, tempEnd, tempCircuit.unit).then(res => {
                        res.data.startDate = tempCircuit.startDate
                        res.data.endDate = tempCircuit.endDate
                        res.data.type = tempCircuit.type
                        res.data.unit = res.data.temperature_unit
                        res.data.compare = tempCircuit.compare
                        res.data.circuit_name = "Temperature"
                        res.data.series = {}
                        for (let key in res.data.readings) {
                            res.data.series[key] = res.data.readings[key].temp
                        }
                        return res.data
                    }).catch(e => {
                        if (e.message === 'cancelling') return
                        console.log(e);
                    }))
                } else {
                    promises.push(API.getDigital(cancelToken, auth, tempCircuit.startDate, tempCircuit.endDate, tempCircuit.device_id, dDetail, tempCircuit.channel_name, tempCircuit.channel.utility_type).then(res => {
                        res.data.startDate = tempCircuit.startDate
                        res.data.endDate = tempCircuit.endDate
                        res.data.type = tempCircuit.type
                        res.data.unit = tempCircuit.unit
                        res.data.compare = tempCircuit.compare
                        res.data.circuit_name = tempCircuit.circuit_name
                        return res.data
                    }).catch(e => {
                        if (e.message === 'cancelling') return
                        console.log(e);
                    }))

                }
            });
            let finalData = await Promise.all(promises)
            resolve(finalData)
        })
    }

    useEffect(() => {
        let unitAxixDict = {
            kva: 'electricity', kw: 'electricity', kwh: 'electricity', kvar: 'electricity',
            a: 'electricity', vll: 'digital', vln: 'digital', 'ft³': 'digital', 'L': 'digital',
            pf: 'digital', unblv: 'digital', unbli: 'digital', thdva: 'digital', thdi: 'digital',
            thdv: 'digital', othdv: 'digital', othdi: 'digital', ethdv: 'digital', ethdi: 'digital',
            thffv: 'digital', cfv: 'digital'
        }
        if (graphData.length === 0) return
        let colorIndex = 0
        let tempOptions = JSON.parse(JSON.stringify(options))
        tempOptions.series = []
        tempOptions.yAxis = []
        let countYAxis = 0
        let maxYAxis = {}
        let hasTemp = false
        let tempDetail = getDetail()
        for (let series of graphData) {
            if (!series) continue
            let startDate = moment.tz(series.startDate.replaceAll(" ", "T"), "Etc/GMT")
            startDate = startDate.valueOf();

            if (visible.phases && (series.type === 'electricity' || series.type === 'harmonics')) {
                for (let channel of series.channels) {
                    let circuitDetail = series.type === 'electricity' || series.type === 'harmonics' ? tempDetail : dDetail
                    circuitDetail === 'day' ? circuitDetail = 60 * 60 * 24 : circuitDetail = parseInt(circuitDetail)
                    let tempStartend = series.compare && compareStartEnd ? [...compareStartEnd] : [...startEnd]
                    let timestamps = generateTimestamps(tempStartend[0], tempStartend[1], circuitDetail)
                    let newSeries = []
                    let skip = false
                    for (let timestamp of timestamps) {
                        if (skip) {
                            newSeries.push(null)
                            skip = false
                            continue
                        }
                        if (timestamp in channel.series) {
                            newSeries.push(parseFloat(channel.series[timestamp].toFixed(2)))
                            // TODO
                            // newSeries.push(parseFloat(channel.series[timestamp].toFixed(2)))
                            series.series[timestamp] >= 0 ? newSeries.push(parseFloat(channel.series[timestamp].toFixed(2))) : newSeries.push(null)
                        } else if (series.unit === 'pf' && !moment.tz(timestamp, context.getBuildingInfo.timezone).isAfter(moment())) {
                            newSeries.push(1)
                            skip = true
                        } else {
                            newSeries.push(null)
                            skip = true
                        }
                    }
                    if (series.unit === 'pf') newSeries = newSeries.map(e => e > 1 ? 1 : e)
                    let tempESeries = {
                        data: newSeries,
                        visible: true,
                        pointStart: startDate,
                        pointInterval: series.type === 'electricity' && series.unit === 'co' ? (tempDetail === "day" ? 24 * 60 * 60 * 1000 : 60 * 60 * 1000) : (tempDetail === "day" ? 24 * 60 * 60 * 1000 : tempDetail * 60 * 1000),
                        color: hightContrast ? hcColors[colorIndex] : colors[colorIndex],
                        name: `${series.circuit_name} - ${channel.phase} ${series.type === 'harmonics' ? "Harmonics" : unitDictionnary[series.unit]}${series.compare ? " Comparison" : ""}`,
                        type: 'line',
                        yAxis: countYAxis,
                        xAxis: series.compare ? 1 : 0,
                        internalId: 'electricity',
                        toggle_checked: true,
                        comparison: series.compare && series.compare,
                        zIndex: 1
                    }
                    tempOptions.series.push(tempESeries)
                    tempOptions.yAxis.push(
                        {
                            lineColor: "#709982",
                            gridLineWidth: 0,
                            lineWidth: 0.1,
                            tickWidth: 0.1,
                            opposite: false,
                            visible: false,
                            unit: series.unit,
                            min: null,
                            max: null,
                            type: "linear",
                            title: {
                                align: 'high',
                                offset: 0,
                                rotation: 0,
                                y: 10,
                                text: unitAxixDict[series.unit] === 'digital' ? "Digital / Power Quality" : "Consumption"
                            },
                            labels: {
                                align: "left",
                                style: {
                                    color: "#85929E "
                                },
                                formatter: function () {
                                    return this.value;
                                }
                            },
                        },
                    )
                    colorIndex++
                    countYAxis++
                    let tempMax = Math.max(...newSeries);
                    maxYAxis[unitAxixDict[series.unit]] = maxYAxis[unitAxixDict[series.unit]] || 0
                    if (tempMax > maxYAxis[unitAxixDict[series.unit]]) maxYAxis[unitAxixDict[series.unit]] = tempMax
                }
            } else if (series.type === 'temperature') {
                hasTemp = series.unit
                let tempStartend = series.compare && compareStartEnd ? [...compareStartEnd] : [...startEnd]
                tempDetail === 'day' ? tempDetail = 60 * 24 : tempDetail = parseInt(tempDetail)
                let timestamps = generateTimestamps(tempStartend[0], tempStartend[1], tempDetail)
                let newSeries = []
                let last = null
                for (let timestamp of timestamps) {
                    if (tempDetail === 1440) timestamp = timestamp.split(" ")[0]
                    if (timestamp in series.series && series.series[timestamp] !== null) {
                        // TODO
                        // newSeries.push(parseFloat(series.series[timestamp].toFixed(2)))
                        series.series[timestamp] >= 0 ? newSeries.push(parseFloat(series.series[timestamp].toFixed(2))) : newSeries.push(null)
                        last = parseFloat(series.series[timestamp].toFixed(2))
                    } else {
                        newSeries.push(last)
                    }
                }
                let tempESeries = {
                    data: newSeries,
                    visible: true,
                    pointStart: startDate,
                    pointInterval: tempDetail === "day" ? 24 * 60 * 60 * 1000 : tempDetail * 60 * 1000,
                    color: series.compare && series.compare ? '#0000FF85' : '#a3a7a385',
                    name: `Temperature ${series.unit}${series.compare ? " - Comparison" : ""}`,
                    type: 'line',
                    yAxis: countYAxis,
                    xAxis: series.compare ? 1 : 0,
                    internalId: series.type,
                    toggle_checked: true,
                    comparison: series.compare && series.compare,
                    zIndex: 0
                }
                tempOptions.series.push(tempESeries)
            } else {
                let circuitDetail = series.type === 'electricity' || series.type === 'harmonics' ? series.unit === 'co' && tempDetail < 60 ? 60 : tempDetail : dDetail
                let tempStartend = series.compare && compareStartEnd ? [...compareStartEnd] : [...startEnd]
                circuitDetail === 'day' ? circuitDetail = 60 * 24 : circuitDetail = parseInt(circuitDetail)
                let timestamps = generateTimestamps(tempStartend[0], tempStartend[1], circuitDetail)
                let newSeries = []
                let skip = false
                for (let timestamp of timestamps) {
                    if (skip) {
                        newSeries.push(null)
                        skip = false
                        continue
                    }
                    if (timestamp in series.series) {
                        // TODO
                        // newSeries.push(parseFloat(series.series[timestamp].toFixed(2)))
                        series.series[timestamp] >= 0 ? newSeries.push(parseFloat(series.series[timestamp].toFixed(2))) : newSeries.push(null)
                    } else if (series.unit === 'pf' && !moment.tz(timestamp, context.getBuildingInfo.timezone).isAfter(moment())) {
                        newSeries.push(1)
                    } else {
                        newSeries.push(null)
                        skip = true
                    }
                }
                if (series.unit === 'pf') newSeries = newSeries.map(e => e > 1 ? 1 : e)
                let tempESeries = {
                    data: newSeries,
                    visible: true,
                    pointStart: startDate,
                    pointInterval: series.type === 'electricity' || series.type === 'harmonics' ?
                        (series.unit === 'co' ?
                            (tempDetail === "day" ? 24 * 60 * 60 * 1000 : 60 * 60 * 1000) :
                            (tempDetail === "day" ? 24 * 60 * 60 * 1000 : tempDetail * 60 * 1000))
                        :
                        (dDetail === "day" ? 24 * 60 * 60 * 1000 : dDetail * 60 * 1000),
                    color: hightContrast ? hcColors[colorIndex] : colors[colorIndex],
                    name: `${series.type === 'electricity' || series.type === 'harmonics' ? series.circuit_name : series.channel_name} ${series.type === 'harmonics' ? "Harmonics" : unitDictionnary[series.unit]}${series.compare ? " Comparison" : ""}`,
                    type: 'line',
                    yAxis: countYAxis,
                    xAxis: series.compare ? 1 : 0,
                    internalId: series.type,
                    toggle_checked: true,
                    comparison: series.compare && series.compare
                }
                tempOptions.series.push(tempESeries)
                tempOptions.yAxis.push(
                    {
                        lineColor: "#709982",
                        gridLineWidth: 0,
                        lineWidth: 0.1,
                        tickWidth: 0.1,
                        opposite: false,
                        visible: false,
                        unit: series.unit,
                        min: null,
                        max: null,
                        type: "linear",
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            y: 10,
                            text: unitAxixDict[series.unit] === 'digital' ? "Digital / Power Quality" : "Consumption"
                        },
                        labels: {
                            align: "right",
                            style: {
                                color: "#85929E "
                            },
                            formatter: function () {
                                return this.value;
                            }
                        },
                    },
                )
                countYAxis++
                colorIndex++
                let tempMax = Math.max(...newSeries);
                if (series.unit in unitAxixDict) {
                    maxYAxis[unitAxixDict[series.unit]] = maxYAxis[unitAxixDict[series.unit]] || 0
                    if (tempMax > maxYAxis[unitAxixDict[series.unit]]) maxYAxis[unitAxixDict[series.unit]] = tempMax
                } else {
                    maxYAxis[series.unit] = maxYAxis[series.unit] || 0
                    if (tempMax > maxYAxis[series.unit]) maxYAxis[series.unit] = tempMax
                }

            }
        }

        let finalE = null
        let finalD = null
        let onlyPf = true
        for (let axis of tempOptions.yAxis) {
            if (axis.unit === 'co') axis.max = Math.ceil(maxYAxis[axis.unit])
            else if (axis.unit in unitAxixDict) {
                if (unitAxixDict[axis.unit] === 'digital') {
                    if (axis.unit === 'pf') axis.max = 1
                    if (axis.unit !== 'pf') onlyPf = false
                    axis.opposite = true
                    axis.labels.align = "left"
                    if (!finalD) {
                        finalD = JSON.parse(JSON.stringify(axis))
                        finalD.max = (Math.ceil(maxYAxis[unitAxixDict[axis.unit]] / 1) * 1)
                    }
                } else if (unitAxixDict[axis.unit] === 'electricity') {
                    if (!finalE) {
                        finalE = JSON.parse(JSON.stringify(axis))
                        finalE.max = (Math.ceil(maxYAxis[unitAxixDict[axis.unit]] / 1) * 1)
                    }
                }
                if (axis.unit === 'pf') axis.max = 1
                else axis.max = (Math.ceil(maxYAxis[unitAxixDict[axis.unit]] / 1) * 1)
                axis.tickPositions = generateArrayFromNumber(axis.max)
            } else axis.max = (Math.ceil(maxYAxis[axis.unit] / 1) * 1)
        }
        if (onlyPf && finalD) {
            finalD.max = 1
        } else if (!onlyPf && finalD) {
            let axis = tempOptions.yAxis.filter(e => e.unit === 'pf')[0]
            if (axis) {
                axis.max = (Math.ceil(maxYAxis[unitAxixDict[axis.unit]] / 1) * 1)
                axis.tickPositions = generateArrayFromNumber(axis.max)
            }
        }
        if (finalE) {
            finalE.visible = true
            finalE.min = -1000000
            finalE.tickPositions = generateArrayFromNumber(finalE.max)
            finalE.labels = {}
            finalE.labels.formatter = function () {
                return this.value;
            }
            tempOptions.yAxis.push(finalE)
        }
        if (finalD) {
            finalD.visible = true
            finalD.min = -1000000
            finalD.tickPositions = generateArrayFromNumber(finalD.max)
            finalD.labels = {}
            finalD.labels.formatter = function () {
                return this.value;
            }
            tempOptions.yAxis.push(finalD)
        }

        if (hasTemp) {
            tempOptions.yAxis.push(
                {
                    lineColor: "#709982",
                    gridLineWidth: 0,
                    lineWidth: 0.1,
                    tickWidth: 0.1,
                    opposite: true,
                    visible: true,
                    unit: hasTemp,
                    min: -40,
                    max: unitTemp === 'c' ? 40 : 112,
                    type: "linear",
                    title: {
                        align: 'high',
                        text: "Temparature",
                        offset: 0,
                        rotation: 0,
                        y: 10,
                    },
                    labels: {
                        align: "left",
                        style: {
                            color: "#85929E "
                        },
                        formatter: function () {
                            return this.value;
                        }
                    },
                },
            )
            let item = tempOptions.series.filter(e => e.internalId === 'temperature' && !e.comparison)[0]
            if (item) item.yAxis = tempOptions.yAxis.length - 1

            if (compareOn) {
                tempOptions.yAxis.push(
                    {
                        lineColor: "#709982",
                        gridLineWidth: 0,
                        lineWidth: 0.1,
                        tickWidth: 0.1,
                        opposite: true,
                        visible: false,
                        unit: hasTemp,
                        min: -40,
                        max: unitTemp === 'c' ? 40 : 112,
                        type: "linear",
                        title: {
                            align: 'high',
                            text: "Temparature",
                            offset: 0,
                            rotation: 0,
                            y: 10,
                        },
                        labels: {
                            align: "left",
                            style: {
                                color: "#85929E "
                            },
                            formatter: function () {
                                return this.value;
                            }
                        },
                    },
                )
                let item = tempOptions.series.filter(e => e.internalId === 'temperature' && e.comparison === true)[0]
                if (item) item.yAxis = tempOptions.yAxis.length - 1
            }
        }

        if (compareOn) {
            let compareAxis = tempOptions.xAxis.find((obj) => obj.internalType === "compare");
            if (!compareAxis) {
                tempOptions.xAxis.push({
                    type: 'datetime',
                    minTickInterval: 60 * 60 * 1000,
                    minRange: 24 * 3600 * 1000,
                    ordinal: false,
                    crosshair: true,
                    dateTimeLabelFormats: {
                        second: '%d %b %Y <br/> %H:%M:%S %P',
                    },
                    visible: true,
                    internalType: "compare",
                    zoomEnabled: true
                })
            }
        } else {
            tempOptions.xAxis = tempOptions.xAxis.filter(e => e.internalType !== "compare")
        }
        setOptions(tempOptions)
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData])

    const generateArrayFromNumber = (number) => {
        number *= 1.8
        const step = (number / 4);
        const result = [];
        let final = new Set()
        if (number) {
            for (let i = 0; i <= number; i += step) {
                result.push(i);
            }
            result.map(e => e === 0 ? 0 : replaceWithFirstDigit(e))
            result.forEach(e => {
                if (e === 0) final.add(e)
                else final.add(replaceWithFirstDigit(e))
            })
            final = [...final]
            final.push((final[final.length - 1] + (final[final.length - 1] - final[final.length - 2])))
            return findLargestDigitsNumber(final);
        }
        else {
            return [0, 10]
        }
    }

    const findLargestDigitsNumber = (numbers) => {
        const maxDigits = Math.max(...numbers.map(num => String(num).length));
        let filteredNumbers = numbers.filter(num => String(num).length === maxDigits);
        if (filteredNumbers.length > 1) {
            if (!filteredNumbers.includes(0)) filteredNumbers.unshift(0)
            return filteredNumbers
        }
        return numbers
    }

    const replaceWithFirstDigit = (number) => {
        const firstDigit = Math.floor(Math.abs(number) / Math.pow(10, Math.floor(Math.log10(Math.abs(number)))))
        return parseInt(firstDigit * Math.pow(10, Math.floor(Math.log10(Math.abs(number)))))
    }

    useEffect(() => {
        if (!compareOn) {
            setCompareStartEnd(null)
        }
    }, [compareOn])


    const handleDateChange = (data) => {
        if (!data.value) return
        let startDate = data.value[0]
        let endDate = data.value[1]
        setStartEnd([GlobalFuncs.formatDate(startDate, 'YYYY-MM-DD'), GlobalFuncs.formatDate(endDate, 'YYYY-MM-DD')])
        fixPeriod.current.value = ""
    }

    const handleDateCompare = (data) => {
        if (!data.value) return
        let diff = GraphFuncs.getDifferenceInDays(startEnd[0], startEnd[1]) - 1
        let startDate = data.value
        let endDate = moment(startDate).add(diff, 'days');
        setCompareStartEnd([moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")])
    }

    const handleDateChangeSelection = (e) => {
        if (chartRef.current) {
            chartRef.current.chart.zoomOut();
        }

        const today = new Date();
        const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        const lastDayOfMonth = new Date(firstDayOfNextMonth - 1);
        const lastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastYear = new Date();
        lastYear.setDate(lastYear.getDate() - 365);
        if (e.currentTarget.value === 'day') {
            setStartEnd([GlobalFuncs.formatDate(today, 'YYYY-MM-DD'), GlobalFuncs.formatDate(today, 'YYYY-MM-DD')])
        } else if (e.currentTarget.value === 'month') {
            setStartEnd([GlobalFuncs.formatDate(lastMonth, 'YYYY-MM-DD'), GlobalFuncs.formatDate(lastDayOfMonth, 'YYYY-MM-DD')])
        } else if (e.currentTarget.value === 'year') {
            setStartEnd([GlobalFuncs.formatDate(lastYear, 'YYYY-MM-DD'), GlobalFuncs.formatDate(today, 'YYYY-MM-DD')])
        }
    }

    const handleSlideSelection = (e) => {
        let range = fixPeriod.current.value
        if (range === '') return
        let currentStart = startEnd[0].split("-")
        let currentEnd = startEnd[1].split("-")
        let start = new Date(currentStart[0], currentStart[1] - 1, currentStart[2], 0, 0, 0, 0)
        let end = new Date(currentEnd[0], currentEnd[1] - 1, currentEnd[2], 0, 0, 0, 0)
        if (range === 'day') {
            start.setDate(start.getDate() + (e));
            end.setDate(end.getDate() + (e));
            setStartEnd([GlobalFuncs.formatDate(start, 'YYYY-MM-DD'), GlobalFuncs.formatDate(end, 'YYYY-MM-DD')])
        } else if (range === 'month') {
            start.setMonth(start.getMonth() + e);
            end.setMonth(end.getMonth() + e);
            setStartEnd([GlobalFuncs.formatDate(start, 'YYYY-MM-DD'), GlobalFuncs.formatDate(end, 'YYYY-MM-DD')])
        } else if (range === 'year') {
            start.setDate(start.getDate() + (365 * e));
            end.setDate(end.getDate() + (365 * e));
            setStartEnd([GlobalFuncs.formatDate(start, 'YYYY-MM-DD'), GlobalFuncs.formatDate(end, 'YYYY-MM-DD')])
        }
    }

    const handleVisible = (event) => {
        let name = event.currentTarget.name
        let checked = event.currentTarget.checked
        if (name === 'harmonics') {
            setHarmonicsUnit("")
            setVisible(e => ({ ...e, [name]: checked, unblv: false, unbli: false, thdva: false, thdv: false, othdv: false, ethdv: false, kfi: false, ethdi: false, othdi: false, thdi: false, thffv: false, cfv: false }))
        } else {
            setVisible(e => ({ ...e, [name]: checked }))
        }
    }

    const handleHarmonic = (event) => {
        let val = event.currentTarget.value
        setHarmonicsUnit(val)
        setVisible(e => ({ ...e, unblv: false, unbli: false, thdva: false, thdv: false, othdv: false, ethdv: false, kfi: false, ethdi: false, othdi: false, thdi: false, thffv: false, cfv: false, [val]: true }))
    }

    const handleCircuitPhase = (e) => {
        setVisible(v => ({
            ...v, phases: e,
            thdv: visible.thdv ? e : false,
            othdv: visible.othdv ? e : false,
            ethdv: visible.ethdv ? e : false,
            thffv: visible.thffv ? e : false,
            cfv: visible.cfv ? e : false,
            unblv: visible.unblv ? !e : false,
            unbli: visible.unbli ? !e : false,
            thdva: visible.thdva ? !e : false,
        }))
    }

    const generateTimestamps = (startDate, endDate, step) => {
        if (step === 'day') step = 60 * 60 * 24

        const timestamps = [];

        let today = new Date();
        today = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

        let dateParts = startDate.split('-');
        let year = parseInt(dateParts[0]);
        let month = parseInt(dateParts[1]) - 1;
        let day = parseInt(dateParts[2]);
        const currentDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

        dateParts = endDate.split('-');
        year = parseInt(dateParts[0]);
        month = parseInt(dateParts[1]) - 1;
        day = parseInt(dateParts[2]);
        const endDateObj = new Date(Date.UTC(year, month, day, 23, 59, 59, 999));

        today.setUTCHours(0, 0, 0, 0);
        if (endDateObj.getFullYear() === today.getFullYear() &&
            endDateObj.getMonth() === today.getMonth() &&
            endDateObj.getDate() === today.getDate()) {
            endDateObj.setDate(endDateObj.getDate());
        } else if (startDate === endDate) {
            let tempEnd = endDate.split("-")
            endDate = `${tempEnd[0]}-${tempEnd[1]}-${parseInt(tempEnd[2]) + 1}`
            endDateObj.setDate(endDateObj.getDate());
        }
        while (currentDate <= endDateObj) {
            const formattedTimestamp = currentDate.toISOString().slice(0, 19).replace("T", " ");
            timestamps.push(formattedTimestamp);
            currentDate.setMinutes(currentDate.getMinutes() + step);
        }
        return timestamps;
    }



    return (
        <div>
            <div className={`${"card tablet:p-8"} rounded-lg relative`}>
                {props.title &&
                    <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl   font-bold text-[#332D41] md:decoration-dashed">{props.title}</h2>
                }
                <div className="flex flex-nowrap tablet:justify-end laptop:justify-start mobile:justify-end rounded-lg min-h-[600px]">
                    <div className={`${open ? 'laptop:w-2/12 tablet:w-1/2 mobile:w-full' : 'w-0'} side-card mr-2 mt-2 flex-col z-10 laptop:static tablet:absolute mobile:absolute tablet:left-0 tablet:top-0 mobile:left-0 mobile:top-0 laptop:min-h-[600px] laptop:h-auto tablet:h-full mobile:h-full backround-white tablet:[card] transition-width duration-300 ease-in-out`}>
                        {open && <div className='flex flex-col justify-between h-full mr-3'>
                            <div>
                                <div className=" grid grid-col justify-self-center">
                                    <h2 className='text-lg font-medium mt-2'>Electricity</h2>
                                    <h3 className='ml-4 mb-2 font-normal mt-2 text-base'>Consumption</h3>
                                    <div className='ml-4 left-green-border pl-2 flex flex-col items-start justify-center'>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="kw" id="kw" checked={visible.kw} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="kw">Real Power (kW)</label>
                                        </div>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="kva" id="kva" checked={visible.kva} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="kva">Apparent Power (kVA)</label>
                                        </div>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="kvar" id="kvar" checked={visible.kvar} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="kvar">Reactive Power (kVAr)</label>
                                        </div>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="a" id="a" checked={visible.a} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="a">Current (A)</label>
                                        </div>
                                    </div>
                                    <h3 className='ml-4 mb-2 font-normal mt-4 text-base'>Power Quality</h3>
                                    <div className='ml-4 left-green-border pl-2 flex flex-col items-start justify-center mb-2'>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="harmonics" id="h" checked={visible.harmonics} onChange={handleVisible} disabled={!context.isGwUser} />
                                            <label className={`p-1 pl-2 text-xs ${!context.isGwUser ? 'text-grey' : ''}`} htmlFor="h">Harmonics</label>
                                        </div>
                                        {
                                            visible.harmonics &&
                                            <select className='w-1/2 p-2 ml-2 text-xs outline-none' name="harmonics" value={harmonicsUnit} onChange={handleHarmonic}>
                                                <option value="">Select</option>
                                                {visible.phases && <option value="thdv">THD (V)</option>}
                                                {visible.phases && <option value="othdv">OTHD (V)</option>}
                                                {visible.phases && <option value="othdi">OTHD (I)</option>}
                                                {visible.phases && <option value="ethdv">ETHD (V)</option>}
                                                {visible.phases && <option value="ethdi">ETHD (I)</option>}
                                                {visible.phases && <option value="thffv">THFF (V)</option>}
                                                {visible.phases && <option value="cfv">CF (V)</option>}
                                                {!visible.phases && <option value="unblv">Unbl (V)</option>}
                                                {!visible.phases && <option value="unbli">Unbl (I)</option>}
                                                {!visible.phases && <option value="thdva">THD (V)</option>}
                                                {/* <option value="kfi">KF (I)</option> */}
                                                <option value="thdi">THD (I)</option>
                                            </select>
                                        }
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="pf" id="pf" checked={visible.pf} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="pf">Power Factor</label>
                                        </div>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="vll" id="vll" checked={visible.vll} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="vll">Voltage (V-L-L)</label>
                                        </div>
                                        <div className='flex flex-nowrap items-center'>
                                            <input type="checkbox" name="vln" id="vln" checked={visible.vln} onChange={handleVisible} />
                                            <label className='p-1 pl-2 text-xs' htmlFor="vln">Voltage (V-L-N)</label>
                                        </div>
                                    </div>
                                    <UnitToggle setOptions={handleCircuitPhase} options={visible.phases} option1={false} option2={true} button1="Circuit" button2="Phase" />
                                    <SlideToggle setOptions={setVisible} options={visible} id={"water"} index={1} name={"Water"} />
                                    {
                                        visible.water &&
                                        <select className='w-1/2 p-2 ml-2' name="dDetail" value={dDetail} onChange={(e) => setDDetail(e.target.value)}>
                                            {getDetail() <= 1 && <option value="1">1 min</option>}
                                            {getDetail() <= 3 && <option value="3">3 mins</option>}
                                            {getDetail() <= 5 && <option value="5">5 mins</option>}
                                            {getDetail() <= 15 && <option value="15">15 mins</option>}
                                            {getDetail() <= 20 && <option value="20">20 mins</option>}
                                            {getDetail() <= 30 && <option value="30">30 mins</option>}
                                            {getDetail() <= 60 && <option value="60">60 mins</option>}
                                            <option value="day">24h</option>
                                        </select>
                                    }
                                    <SlideToggle setOptions={setVisible} options={visible} id={"gas"} index={2} name={"Gas"} />
                                    {
                                        visible.gas &&
                                        <select className='w-1/2 p-2 ml-2' name="dDetail" value={dDetail} onChange={(e) => setDDetail(e.target.value)}>
                                            {getDetail() <= 1 && <option value="1">1 min</option>}
                                            {getDetail() <= 3 && <option value="3">3 mins</option>}
                                            {getDetail() <= 5 && <option value="5">5 mins</option>}
                                            {getDetail() <= 15 && <option value="15">15 mins</option>}
                                            {getDetail() <= 20 && <option value="20">20 mins</option>}
                                            {getDetail() <= 30 && <option value="30">30 mins</option>}
                                            {getDetail() <= 60 && <option value="60">60 mins</option>}
                                            <option value="day">24h</option>
                                        </select>
                                    }
                                    {[60, 'day'].includes(getDetail()) && <SlideToggle setOptions={setVisible} options={visible} id={"co"} index={3} name={"Emissions"} />}
                                    <SlideToggle setOptions={setVisible} options={visible} id={"outdoortemp"} index={4} name={"Outdoor Temp"} />
                                    <UnitToggle setOptions={setUnitTemp} options={unitTemp} option1="c" option2="f" button1="&deg;C" button2="&deg;F" />
                                </div>
                            </div>
                            <div className='mt-4 flex flex-nowrap justify-start items-center cursor-pointer' onClick={() => setOpen(false)}>
                                <div className='border border-light-green rounded-full w-8 h-8 flex flex-nowrap items-center justify-center'>
                                    {GlobalSVG.leftArrow()}
                                </div>
                                <p className='ml-3 cursor-pointer'>
                                    Hide options
                                </p>
                            </div>
                        </div>}
                        {
                            !open &&
                            <div className={`${open ? 'w-2/12' : 'items-center'} flex flex-col justify-end h-full`}>
                                <div onClick={() => setOpen(true)} className='cursor-pointer border border-light-green rounded-full tablet:w-8 tablet:h-8 mobile:w-6 mobile:h-6  flex flex-nowrap items-center justify-center'>
                                    {GlobalSVG.rightArrow()}
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`col-span-2 ${open ? 'w-10/12' : 'laptop:w-full mobile:w-11/12'}`}>
                        <div className="flex flex-wrap justify-end mb-4 items-center">
                            <div className='flex justify-end items-center m-2'>
                                <p className="font-sans font-normal leading-5 text-sans text-[#332D41] md:decoration-dashed">View By:</p>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center m-2'>
                                {fixPeriod.current && fixPeriod.current.value !== '' &&
                                    <div className='cursor-pointer' onClick={() => handleSlideSelection(-1)}>
                                        {GlobalSVG.leftArrow()}
                                    </div>}
                                <select ref={fixPeriod} onChange={handleDateChangeSelection} className="outline-none mx-2 p-2 font-sans w-[134px] text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm font-bold">
                                    <option value=''>Select</option>
                                    <option value='day'>Day</option>
                                    <option value='month'>Month</option>
                                    <option value='year'>Year</option>
                                </select>
                                {fixPeriod.current && fixPeriod.current.value !== '' &&
                                    <div className='cursor-pointer' onClick={() => handleSlideSelection(1)}>
                                        {GlobalSVG.rightArrow()}
                                    </div>}
                            </div>
                            <p className="font-sans font-normal leading-5 text-sans text-[#332D41] md:decoration-dashed m-2"> <b> or </b></p>
                            <div className='flex justify-end items-center m-2'>
                                <DateRangePicker onChange={handleDateChange}
                                    start={startEnd[0]}
                                    end={startEnd[1]}
                                    compare={compareOn}
                                    onChangeCompare={handleDateCompare}
                                    compareStart={compareStartEnd} />
                            </div>
                            {!compareOn && <div className='flex flex-nowrap justify-center items-center m-2 cursor-pointer' onClick={() => setCompareOn(true)}>
                                {GlobalSVG.pentagon()}
                                <p className='ml-2 font-medium text-[#587464]'>Compare Periods</p>
                            </div>}
                            {compareOn && <div className='flex flex-nowrap justify-center items-center m-2 cursor-pointer' onClick={() => setCompareOn(false)}>
                                {GlobalSVG.closeBig()}
                                <p className='ml-2 font-medium text-[#587464]'>Stop Comparison</p>
                            </div>}

                        </div>
                        <HighchartsReact
                            ref={chartRef}
                            highcharts={Highcharts}
                            constructorType={"stockChart"}
                            options={options}
                        />
                        <div className='w-full flex flex-nowrap justify-end items-center cursor-pointer' onClick={() => setHighContrast(!hightContrast)}>
                            {
                                GlobalSVG.colorPalette()
                            }
                            <p className='my-4 mr-4 ml-2 text-[#F75D5F]'>High Contrast {hightContrast ? "Off" : ""}</p>
                        </div>
                        {loading && <Loader />}
                    </div>
                </div>
            </div >
            {props.dist && graphData &&
                <DistribuitionTable circuits={graphData} startEnd={startEnd} compare={compareStartEnd} />
            }
        </div>

    );
};